@mixin hero-grid($navbar-heights, $preview-heights) {
    display: grid;
    grid-template-columns: 1fr;

    @include media-breakpoint-up(xl) {
        grid-template-columns: 1fr;
    }

    $last-navbar-height: 0;
    $last-preview-height: 0;

    @each $breakpoint, $size in $grid-breakpoints {
        $current-navbar-height: $last-navbar-height;
        $current-preview-height: $last-preview-height;

        @if (map-has-key($navbar-heights, $breakpoint)) {
            $current-navbar-height: map-get($navbar-heights, $breakpoint);
        }
        @if (map-has-key($preview-heights, $breakpoint)) {
            $current-preview-height: map-get($preview-heights, $breakpoint);
        }
        @if ($current-navbar-height != $last-navbar-height OR $current-preview-height != $last-preview-height) {
            @include media-breakpoint-up($breakpoint) {
                grid-template-rows: $current-navbar-height 1fr $current-preview-height;
            }
        }

        $last-navbar-height: $current-navbar-height;
        $last-preview-height: $current-preview-height;
    }
}
