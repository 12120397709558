.CE_FEATURE_TEASER {
    position: relative;

    @include media-breakpoint-up(xl) {
        .container-fluid,
        .row {
            height: 100%;
        }
    }

    @include media-breakpoint-down(lg) {
        .CE_FEATURE_TEASER_VIDEO_WRAP {
            // Duplicate feature of BS4 class .embed-responsive and .embed-responsive-16by9
            // @extend isn't available within a media-breakpoint()
            position: relative;
            display: block;
            width: 100%;
            overflow: hidden;
            padding-top: 56.25%;
        }
    }

    .CE_FEATURE_TEASER_QUOTE {
        background-position: left center;
        background-repeat: no-repeat;
        background-size: 873px 834px;
        padding: $grid-gutter-width;
        background-color: __get($theme-colors, 'primary');

        @include media-breakpoint-up(md) {
            padding: $grid-gutter-width $grid-gutter-width * 2;
        }

        @include media-breakpoint-up(xl) {
            background-image: url('../Images/Diamond-Logografik-Ansicht.png');
        }
        @include media-breakpoint-up(xl) {
            padding: $grid-gutter-width * 2 $grid-gutter-width $grid-gutter-width * 2 $grid-gutter-width * 7;
        }

        p,
        blockquote {
            text-align: center;
            color: __get($colors_legacy, 'weiss' 'base');
            max-width: 720px;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            margin-bottom: 0;

            cite {
                margin-top: $grid-gutter-width * 0.5;
                display: block;

                span {
                    @extend .font-weight-bold;
                }
            }
        }

        img {
            margin: 0 auto $grid-gutter-width * 0.5 auto;
            opacity: 0.4;
        }
    }

    .CE_FEATURE_TEASER_BG {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        img {
            display: none;
        }

        @include media-breakpoint-down(lg) {
            background-image: none;
            img {
                display: block;
            }
        }
    }

    .CE_FEATURE_TEASER_BOX {
        position: relative;
        z-index: 7;
        background-color: __get($colors_legacy, 'weiss' 'base');
        padding: 24px $grid-gutter-width;
        @extend .shadow_box;
        @include media-breakpoint-up(md) {
            padding: 24px $grid-gutter-width;
        }
        @include px-to-rem(font-size, __get($font-size, 'xs' 'feature'));
        @include px-to-rem(line-height, __get($line-height, 'xs' 'feature'));

        @include media-breakpoint-up(md) {
            @include px-to-rem(font-size, __get($font-size, 'md' 'feature'));
            @include px-to-rem(line-height, __get($line-height, 'md' 'feature'));
        }

        .no-facts-placeholder {
            margin: 0 auto;
            max-width: 100px;

            @include media-breakpoint-up(md) {
                max-width: 150px;
            }

            @include media-breakpoint-up(xl) {
                max-width: 250px;
                margin: 100px auto;
            }
        }

        width: 100%;

        @include media-breakpoint-up(xl) {
            @include centerer;
            position: absolute;
            left: 50%;
            top: 50%;
            z-index: 1;
            padding: 54px $grid-gutter-width;
            max-width: 360px;
            min-height: 545px;
        }

        .CE_FEATURE_TEASER_FEATURE {
            margin-bottom: $grid-gutter-width * 0.25;

            @include px-to-rem(font-size, __get($font-size, 'xs' 'feature'));
            @include px-to-rem(line-height, __get($line-height, 'xs' 'feature'));

            @include media-breakpoint-up(md) {
                @include px-to-rem(font-size, __get($font-size, 'md' 'feature'));
                @include px-to-rem(line-height, __get($line-height, 'md' 'feature'));
            }

            .unit {
                @include px-to-rem(font-size, __get($font-size, 'xs' 'button_small'));
                @include px-to-rem(line-height, __get($line-height, 'xs' 'button_small'));

                @include media-breakpoint-up(md) {
                    @include px-to-rem(font-size, __get($font-size, 'md' 'button_small'));
                    @include px-to-rem(line-height, __get($line-height, 'md' 'button_small'));
                }
            }

            .unit_icon {
                vertical-align: inherit;
                height: 36px;
                width: auto;
                margin-right: 26px;
            }
        }

        p {
            @include px-to-rem(font-size, __get($font-size, 'xs' 'text'));
            @include px-to-rem(line-height, __get($line-height, 'xs' 'text'));

            @include media-breakpoint-up(md) {
                @include px-to-rem(font-size, __get($font-size, 'md' 'text'));
                @include px-to-rem(line-height, __get($line-height, 'md' 'text'));
            }

            margin-top: $grid-gutter-width * 0.5;

            a {
                &:after {
                    border-style: solid;
                    border-width: 2px 2px 0 0;
                    content: '';
                    display: inline-block;
                    height: 10px;
                    width: 10px;
                    transform: rotate(45deg);
                    vertical-align: middle;
                    margin: 0 0 0 $grid-gutter-width * 0.5;
                }
            }
        }
    }

    &:hover .yt-play,
    .yt-play:focus {
        opacity: 1;
    }

    .yt-play {
        position: absolute !important;
        bottom: 0;
        left: 0;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
        background: transparent;
        border: none;
        padding: $grid-gutter-width * 0.25;

        .yt-pause {
            display: block;
        }
        .yt-start {
            display: none;
        }

        &.paused {
            .yt-pause {
                display: none;
            }
            .yt-start {
                display: block;
            }
        }

        svg {
            fill: white;
        }
    }

    @media (prefers-reduced-motion: reduce) {
        iframe,
        .yt-plays {
            display: none;
        }
    }

    &:not(:has(iframe.playerBox)) .yt-play {
        display: none;
    }
}

.CE_FEATURE_TEASER_DISCLAIMER {
    margin-top: -20px;
    @include media-breakpoint-up(md) {
        margin-top: -48px;
    }

    p {
        @include px-to-rem(font-size, __get($font-size, 'xs' 'footerText'));
        @include px-to-rem(line-height, __get($line-height, 'xs' 'footerText'));

        @include media-breakpoint-up(md) {
            @include px-to-rem(font-size, __get($font-size, 'md' 'footerText'));
            @include px-to-rem(line-height, __get($line-height, 'md' 'footerText'));
        }
    }
}
