.CE_PRODUCTS {
    &__accordion {
        position: relative;

        @include media-breakpoint-up(md) {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: min-content 1fr min-content;
        }

        @include print() {
            display: flex;
            flex-direction: column;
        }
    }

    &__figure {
        position: relative;

        &::after {
            content: '';
            background: linear-gradient(
                180deg,
                __get($theme-colors, 'dai-shadow') 0%,
                rgba(__get($theme-colors, 'dai-shadow'), 0) 95.44%
            );
            mix-blend-mode: darken;
            transform: rotate(-180deg);
            height: 60%;
            width: 100%;
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }

    &__title {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: $grid-gutter-width * 0.5;
        color: __get($theme-colors, 'dai-light');
        font-size: 30px;

        @include media-breakpoint-up(md) {
            font-size: 35px;
            padding: $grid-gutter-width;
        }

        @include media-breakpoint-up(xxl) {
            padding: $grid-gutter-width * 1.5;
        }
    }

    &__header {
        @include media-breakpoint-up(md) {
            grid-row: 1;
        }

        @include print() {
            page-break-before: auto;
            page-break-inside: avoid;
        }
    }

    .is-open &__title {
        justify-content: flex-end;
        padding-bottom: (($grid-gutter-width * 0.5) + 5);

        @include media-breakpoint-up(md) {
            padding-bottom: ($grid-gutter-width + 5);
        }

        @include media-breakpoint-up(xxl) {
            padding-bottom: (($grid-gutter-width * 1.5) + 5);
        }
    }

    &__content {
        background-color: __get($colors_legacy, 'hellblau' 'base');
        height: 0;
        padding: $grid-gutter-width * 1.5 $grid-gutter-width * 0.5 $grid-gutter-width * 0.5 $grid-gutter-width * 0.5;

        @include media-breakpoint-up(md) {
            grid-row: 2;
            grid-column: 1/4;
            padding: $grid-gutter-width * 1.5 $grid-gutter-width 104px $grid-gutter-width;
        }

        @include media-breakpoint-up(xxl) {
            grid-row: 2;
            grid-column: 1/4;
            padding: $grid-gutter-width * 2 $grid-gutter-width * 1.5 164px $grid-gutter-width * 1.5;
        }

        @include print() {
            display: block !important;
            position: relative !important;
            height: auto !important;
            padding-left: 0;
            padding-right: 0;
            page-break-before: avoid;
        }
    }

    .aircraft-preview {
        @include media-breakpoint-up(xxl) {
            &:last-child {
                margin-bottom: $grid-gutter-width * 0.5;
            }
        }

        @include print() {
            padding-left: 0;
            padding-right: 0;
        }

        &__list {
            @include media-breakpoint-up(ultrawide) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: repeat(3, min-content);
                grid-auto-flow: column;
                grid-gap: 0 $grid-gutter-width;
                margin-bottom: $grid-gutter-width * 0.5;

                &--two {
                    grid-template-rows: repeat(2, min-content);
                }
            }
        }

        .main &__content {
            color: __get($theme-colors, 'dai-dark');
        }
    }

    .is-open &__figure {
        position: relative;

        &:before {
            background-color: __get($theme-colors, primary);
            content: '';
            left: 0;
            bottom: 0;
            display: block;
            position: absolute;
            height: 5px;
            width: 100%;
            z-index: 3;
        }
    }

    .is-open + &__content {
        position: relative;
        height: auto;

        @include motion-safe() {
            transition: height 0.25s;
        }
    }

    &__button {
        &.btn {
            position: static;
            border-color: __get($theme-colors, 'dai-light');
            color: __get($theme-colors, 'dai-light');
            font-size: 15px;
            max-width: 126px;
            line-height: 1;
            height: auto;
            padding: $grid-gutter-width * 0.5;

            &:hover,
            &:focus {
                color: __get($theme-colors, 'dai-dark');
                background-color: __get($theme-colors, 'dai-light');
            }

            @include media-breakpoint-up(md) {
                font-size: 20px;
                max-width: 180px;
            }

            @include print() {
                display: none !important;
            }
        }

        svg {
            height: 6px;
            width: 14px;
            margin-left: $grid-gutter-width * 0.25;

            @include media-breakpoint-up(md) {
                height: 10px;
                width: 22px;
                margin-left: $grid-gutter-width * 0.5;
            }
        }

        &[aria-expanded='true'] {
            &.btn {
                @include media-breakpoint-up(md) {
                    border-color: __get($theme-colors, 'primary');
                    color: __get($theme-colors, 'primary');
                    background-color: transparent;
                    position: absolute;
                    bottom: $grid-gutter-width * 1.75;
                    left: $grid-gutter-width * 0.5;
                    left: $grid-gutter-width;
                }

                @include media-breakpoint-up(xxl) {
                    bottom: $grid-gutter-width * 3;
                    left: $grid-gutter-width * 0.5;
                    left: $grid-gutter-width * 1.5;
                }
            }

            svg {
                transform: rotate(180deg);
            }
        }
    }

    .is-open &__button {
        margin-top: $grid-gutter-width * 0.5;

        @include media-breakpoint-up(md) {
            margin-top: 0;
        }
    }
}
