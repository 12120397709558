.specialNavButton {
    margin-right: 4px;
    color: __get($colors_legacy, 'hellgrau' 'base');
    @include px-to-rem(font-size, __get($font-size, 'xs' 'specialNavButton'));
    @include px-to-rem(line-height, __get($line-height, 'xs' 'specialNavButton'));

    @include media-breakpoint-up(md) {
        @include px-to-rem(font-size, __get($font-size, 'md' 'specialNavButton'));
        @include px-to-rem(line-height, __get($line-height, 'md' 'specialNavButton'));
    }

    cursor: pointer;

    span {
        text-transform: uppercase;
        font-weight: 700;
        @include px-to-rem(font-size, __get($font-size, 'xs' 'specialNavButton'));
        @include px-to-rem(line-height, __get($line-height, 'xs' 'specialNavButton'));

        @include media-breakpoint-up(md) {
            @include px-to-rem(font-size, __get($font-size, 'md' 'specialNavButton'));
            @include px-to-rem(line-height, __get($line-height, 'md' 'specialNavButton'));
        }
    }

    img {
        margin-right: $grid-gutter-width * 0.5;
    }
}
