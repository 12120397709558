.STICKY_PAGE_NAVIGATION {
    padding: 24px 32px;
    position: fixed;
    left: 0;
    top: 50%;
    z-index: 8;
    background-color: transparent;
    display: none;
    cursor: pointer;
    //transition: background-color .4s ease-in-out;

    span,
    header {
        //transition: background-color .4s ease-in-out;
        opacity: 0;
    }

    &:hover,
    &:focus-within,
    &.init {
        @include motion-safe() {
            transition: background-color 0.4s ease-in-out;
        }

        background-color: rgba(__get($colors_legacy, 'weiss' 'base'), 0.9);
        @extend .shadow;

        nav {
            ul {
                max-width: 100%;
            }
        }

        span,
        header {
            opacity: 1;

            @include motion-safe() {
                transition: opacity 400ms linear;
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        top: 225px;
        display: block;
    }
    @include media-breakpoint-up(lg) {
        /*margin-bottom:0;*/
    }

    nav {
        ul {
            margin-bottom: 0;
            max-width: 25px;
            overflow: hidden;
            //transition: max-width 0s ease-in-out;

            li {
                margin-bottom: 8px;

                &:last-child {
                    margin-bottom: 0;
                }

                a:link,
                a:visited {
                    white-space: nowrap;
                    color: __get($colors_legacy, 'schwarz' 'base');
                    background-image: url('../Images/Svg/sticky_normal.svg');
                    background-repeat: no-repeat;
                    padding-left: 48px;
                    background-size: 22px 22px;
                }

                a:hover,
                a:active,
                a:focus {
                    background-image: url('../Images/Svg/sticky_hover.svg');
                    color: __get($theme-colors, 'primary-highlight');
                }

                a.active {
                    color: __get($theme-colors, 'primary');
                    background-image: url('../Images/Svg/sticky_active.svg');
                }
            }
        }
    }
}
