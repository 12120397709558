.aircraft-range-map {
    position: relative;

    .CE_RANGEMAP_HEADER {
        position: absolute;
        z-index: 7;
        left: 0;
        right: $grid-gutter-width;
        top: $grid-gutter-width;
    }

    .CE_RANGEMAP_INFO {
        background-color: __get($colors_legacy, 'weiss' 'base');
        @extend .shadow;
        padding: $grid-gutter-width * 0.5 $grid-gutter-width;

        @include media-breakpoint-up(md) {
            bottom: $grid-gutter-width;
            left: $grid-gutter-width;
            right: $grid-gutter-width;
            visibility: hidden;
            position: absolute;
            z-index: 8;
            p {
                margin-left: $grid-gutter-width * 0.5;
            }
        }

        .CE_RANGEMAP_INFO_BUTTON {
            visibility: visible;
            cursor: pointer;
            display: block;
            min-width: 54px;
            height: 54px;
            border-radius: 54px;
            border: 5px solid __get($colors_legacy, 'hellgrau' 'base');

            &:before {
                display: block;
                content: 'i';
                font-weight: bold;
                color: __get($colors_legacy, 'hellgrau' 'base');
                font-size: 2rem;
                width: 45px;
                height: 45px;
                line-height: 42px;
                text-align: center;
            }

            &:hover,
            &:active,
            &:focus,
            &.active {
                border-color: __get($theme-colors, 'primary-highlight');

                &:before {
                    color: __get($theme-colors, 'primary-highlight');
                }
            }

            &.active {
                cursor: initial;
            }
        }

        a.close {
            margin-top: -10px;
            display: none;
            @include media-breakpoint-up(md) {
                display: block;
            }

            svg {
                &:hover,
                &:active,
                &:focus {
                    path {
                        stroke: __get($colors_legacy, 'danger' 'base');
                    }
                }
            }
        }
    }

    .selected {
    }

    .canvas {
        height: 730px;
        margin-top: 30px;
        border: 1px solid #929899;
        display: grid;
        place-content: center;
        grid-template-columns: minmax(280px, 50%);

        .label {
            padding: 2px 6px;
            background-color: rgba(255, 255, 255, 0.75);
            border-radius: 0 3px 3px 0;
            overflow: visible !important;
            color: #384043;
            font-family: $font-family-base;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 1px;
                height: 40px;
                background-color: rgba(110, 144, 175, 0.75);
            }
        }
    }
}

.gm-style-iw {
    font-family: $font-family-base;
}
