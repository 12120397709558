.picturelink {
    display: block;
    overflow: hidden;

    img {
        @include motion-safe() {
            transition: all 0.8s cubic-bezier(0.06, 0.43, 0.22, 1);
        }
    }

    &:hover {
        picture {
            img {
                transform: scale(1.05);
            }
        }
    }
}
