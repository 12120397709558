.nohero {
    padding-top: $grid-gutter-width * 1.25;

    @include media-breakpoint-up(md) {
        padding-top: $grid-gutter-width * 1.5;
    }

    @include media-breakpoint-up(lg) {
        padding-top: $grid-gutter-width * 1.5;

        &__aside {
            padding-top: $grid-gutter-width * 3;
        }
    }
}
