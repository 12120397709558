.container-fluid {
    max-width: 2560px;

    &.limited-width {
        max-width: __get($container-max-widths, 'xxl');
    }
}

.container-wrapper {
    .fullwidth & {
        @include make-container;
        max-width: __get($container-max-widths, 'xxl');

        @include media-breakpoint-up(xxl) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
