// little animate helper for images on hover (or possibly more animation stuff)

.animate {
    &-image {
        img {
            @include motion-safe() {
                transition: all 0.8s cubic-bezier(0.06, 0.43, 0.22, 1);
            }
        }

        &:hover {
            img {
                transform: scale(1.05);
            }
        }
    }

    &-shadow {
        box-shadow: 0 0 8px 0 rgba(#000, 0.25);

        &:hover {
            box-shadow: 0 0 16px 0 rgba(#000, 0.5);
        }
    }
}
