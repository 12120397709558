.CE {
    .CE_GALLERY_HEADER {
        margin-top: -$grid-gutter-width;
        margin-bottom: 0;

        // removing negative margin
        // when a shadowbox is the parent
        // element
        // #9159
        .CE_SHADOWBOX + & {
            margin-top: 0;
        }
    }
}

.CE_GALLERY_HEADER {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        /*
    margin-bottom:0;
    margin-top:0;
    */
        span {
            font-weight: 400;

            &:after {
                content: '';
                display: block;
                width: 60px;
                height: 2px;
                margin-top: 2px;
                background-color: __get($colors_legacy, 'schwarz' 'base');
                margin-bottom: $grid-gutter-width * 0.5;
            }
        }
    }
}
