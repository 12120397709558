a,
button,
input[type='submit'],
input[type='button'],
input[type='reset'],
span {
    &.btn {
        color: __get($colors_legacy, 'weiss' 'base');
        text-align: center;
        padding: 0 $grid-gutter-width * 0.5;
        line-height: 48px;
        margin-bottom: 18px;
        height: 48px;
        border-radius: 3px;
        box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.15);
        border: none;
        position: relative;
        @extend .text-bold;

        &.outline {
            background-color: __get($colors_legacy, 'weiss' 'base') !important;
        }

        &.small {
            font-size: __get($font-size, 'xs' 'button_small');
            height: 40px;
            line-height: 40px;
            @include media-breakpoint-up(md) {
                font-size: __get($font-size, 'md' 'button_small');
            }
        }

        &.extrasmall {
            font-size: __get($font-size, 'xs' 'button_small');
            height: 24px;
            line-height: 24px;
            @include media-breakpoint-up(md) {
                font-size: __get($font-size, 'md' 'button_small');
            }
        }

        &:not(:disabled):not(.disabled):not(.no-hover) {
            &:hover {
                box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.3);
                transform: translateY(-1px);
            }

            &:active,
            &:focus,
            &:active:focus {
                outline: none;
                box-shadow: none;
            }
        }

        &.btn-range {
            border: 1px solid __get($colors_legacy, 'hellgrau' 'base');
            color: __get($colors_legacy, 'schwarz' 'base');
            height: auto;
            white-space: normal;

            @include media-breakpoint-up(md) {
                height: 26px;
            }

            margin-bottom: 0;

            &.selected {
                box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.3);
                transform: translateY(-1px);
            }
        }

        &.btn-range-location {
            border: 1px solid __get($colors_legacy, 'hellgrau' 'base');
            color: __get($colors_legacy, 'weiss' 'base');
            background-color: __get($theme-colors, 'success');
            height: 26px;
            margin-bottom: 0;

            img {
                margin-right: 5px;
                margin-top: -5px;
            }
        }

        &.btn-primary {
            background-color: __get($theme-colors, 'primary');

            &:not(:disabled):not(.disabled) {
                &:hover,
                &:active,
                &:focus,
                &.active {
                    background-color: __get($theme-colors, 'primary-highlight');
                }
            }

            &.outline {
                border: 1px solid __get($theme-colors, 'primary');
                color: __get($theme-colors, 'primary');

                &:hover,
                &:focus,
                &:focus:active,
                &.active {
                    color: __get($theme-colors, 'primary-highlight');
                    border: 1px solid __get($theme-colors, 'primary-highlight');
                }
            }
        }

        &.btn-success {
            background-color: __get($colors_legacy, 'success' 'base');

            &:not(:disabled):not(.disabled) {
                &:hover,
                &:active,
                &:focus,
                &.active {
                    background-color: __get($colors_legacy, 'success' 'base');
                }
            }

            &.outline {
                border: 1px solid __get($colors_legacy, 'success' 'base');
                color: __get($colors_legacy, 'success' 'base');

                &:hover,
                &:focus,
                &:focus:active {
                    color: __get($colors_legacy, 'success' 'base');
                }
            }
        }

        &.btn-danger {
            background-color: __get($colors_legacy, 'danger' 'base');

            &:not(:disabled):not(.disabled) {
                &:hover,
                &:active,
                &:focus,
                &.active {
                    background-color: __get($colors_legacy, 'danger' 'base');
                }
            }

            &.outline {
                border: 1px solid __get($colors_legacy, 'danger' 'base');
                color: __get($colors_legacy, 'danger' 'base');

                &:hover,
                &:focus,
                &:focus:active {
                    color: __get($colors_legacy, 'danger' 'base');
                }
            }
        }

        &.btn-warning {
            background-color: __get($colors_legacy, 'warning' 'base');

            &:not(:disabled):not(.disabled) {
                &:hover,
                &:active,
                &:focus,
                &.active {
                    background-color: __get($colors_legacy, 'warning' 'base');
                    color: __get($colors_legacy, 'weiss' 'base');
                }
            }

            &:not(:disabled):not(.disabled) {
                &.outline {
                    border: 1px solid __get($colors_legacy, 'warning' 'base');
                    color: __get($colors_legacy, 'warning' 'base');

                    &:hover,
                    &:active,
                    &:focus,
                    &.active {
                        background-color: __get($colors_legacy, 'warning' 'base');
                    }
                }
            }
        }

        &.btn-info {
            background-color: __get($colors_legacy, 'info' 'base');

            &:not(:disabled):not(.disabled) {
                &:hover,
                &:active,
                &:focus,
                &.active {
                    background-color: __get($colors_legacy, 'info' 'base');
                }
            }

            &.outline {
                border: 1px solid __get($colors_legacy, 'info' 'base');
                color: __get($colors_legacy, 'info' 'base');

                &:hover,
                &:focus,
                &:focus:active {
                    color: __get($colors_legacy, 'info' 'base');
                }
            }
        }

        &.btn-light {
            background-color: __get($colors_legacy, 'hellgrau' 'base');

            &:not(:disabled):not(.disabled) {
                &:hover,
                &:active,
                &:focus,
                &.active {
                    background-color: __get($colors_legacy, 'hellgrau' 'base');
                }
            }

            &.outline {
                border: 1px solid __get($colors_legacy, 'hellgrau' 'base');
                color: __get($colors_legacy, 'hellgrau' 'base');

                &:hover,
                &:focus,
                &:focus:active {
                    color: __get($colors_legacy, 'hellgrau' 'base');
                }
            }
        }

        &.btn-link {
            color: __get($theme-colors, 'primary');
            background-color: transparent;
            box-shadow: none;

            &:not(:disabled):not(.disabled) {
                &:hover,
                &:active,
                &:focus {
                    color: __get($theme-colors, 'primary-highlight');
                    text-decoration: underline;
                    text-decoration-thickness: 1px;
                    text-underline-offset: 2px;
                }

                &:active,
                &:focus {
                    text-decoration: underline;
                    text-decoration-thickness: 1px;
                    text-underline-offset: 2px;
                }
            }

            &.no-hover {
                font-weight: 300;
            }
        }

        i {
            position: absolute;
            left: $grid-gutter-width * 0.5;
            top: 50%;
            margin-top: -10px;
        }

        &:has(> i) {
            padding-inline: $grid-gutter-width * 0.5 !important;
        }
    }
}

@each $button in map-keys($theme-colors) {
    .btn.btn-outline-#{$button} {
        border: 1px solid __get($theme-colors, $button);
        color: __get($theme-colors, $button);

        &:hover,
        &:focus,
        &:active,
        &.active {
            color: __get($colors_legacy, 'weiss.base');
        }

        @if $button == 'primary' {
            &.active,
            &:active {
                background: __get($theme-colors, 'primary');
                border-color: __get($theme-colors, 'primary');

                &:hover,
                &:focus {
                    background: __get($theme-colors, 'primary-highlight');
                    border-color: __get($theme-colors, 'primary-highlight');
                }
            }

            &:hover,
            &:focus {
                background: __get($theme-colors, 'primary-highlight');
                border-color: __get($theme-colors, 'primary-highlight');
            }
        }
    }
}

.btn-accordion {
    border: 0;
    background: none;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
    @include px-to-rem(font-size, 22px);
    color: $primary;

    &__text {
        flex: 1 0 calc(100% - #{$grid-gutter-width * 0.75});
    }

    svg {
        @include motion-safe() {
            transition: all 0.2s ease-in-out;
        }
        width: 24px;
        height: auto;
        color: $primary;
        flex: 1 0 $grid-gutter-width * 0.75;
    }

    &[aria-expanded='true'] svg {
        transform: rotate(180deg);
    }
}
