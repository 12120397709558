/*
 * LEGACY CODE
 * yes, needs to be refactored
 */
#location-map {
    .location-map-form {
        .form-check-input {
            margin-top: 5px;
            margin-left: -20px;
        }
    }

    .labelhelper {
        margin-top: -6px;
        white-space: nowrap;
        @include media-breakpoint-up(sm) {
            margin-top: -8px;
        }
        @include media-breakpoint-up(md) {
            margin-top: -10px;
        }
    }

    label {
        margin-right: 5px;
        margin-left: 5px;
        @include media-breakpoint-up(sm) {
            margin-top: -8px;
        }
        @include media-breakpoint-up(md) {
            margin-top: -10px;
        }
    }

    #location-map-support-types {
        .form-control {
            font-size: 14px;
            line-height: 1.5;
        }

        select {
            &.form-control {
                height: 33px;
                padding: 5px 10px;
                border-radius: 2px;
                margin-right: $grid-gutter-width * 0.25;
                appearance: none;
                background-image: url("data:image/svg+xml,<svg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg' aria-hidden='true' focusable='false'><path d='M1 1.27344L7 6.72798L13 1.27344' stroke='black' stroke-width='2'/></svg>");
                background-repeat: no-repeat;
                background-position: calc(100% - 12px) center;
            }
        }
    }

    .checkbox {
        label {
            font-size: 18px;
            padding-left: 5px !important;
        }
    }
}

#location-map .location-map-list {
    position: relative;
    height: 700px;
    margin-left: -10px;
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 14px;
    line-height: 1.3;
    display: flex;
    flex-direction: column;
    gap: $grid-gutter-width * 0.5;

    button {
        border: 1px solid transparent;
        background: transparent;
        padding: 0;
        margin: 0;
        width: 100%;
        text-align: start;

        &:focus-visible {
            border-color: __get($theme-colors, 'copytext');
            outline: none;
        }
    }

    p,
    li,
    br {
        font-size: 14px;
        line-height: 1.3;

        strong {
            font-size: 14px;
            line-height: 1.3;
        }
    }

    .supported-countries {
        p {
            margin-bottom: 0;
        }
    }
}

#location-map .location-map-list {
    height: auto;
    max-height: 700px;

    @include media-breakpoint-up(lg) {
        max-width: none;
    }
}

#location-map .location-map-list .info-window {
    position: relative;
    margin-top: 0;
    padding-top: 10px;
    padding-left: 10px;
    cursor: pointer;
    border-top: 1px solid #f2f3f4;
}

#location-map .location-map-list .info-window:first-child {
    border-top: none;
}

#location-map .location-map-list .info-window:hover {
    background-color: #f2f3f4;
}

#location-map .location-map-list .info-window.active {
    background-color: #e6ebf0;
}

#location-map .location-map-list .info-window.service-center.exclusive {
    background-color: #f9f2e2;
}

#location-map .location-map-list .info-window.service-center.exclusive:hover,
#location-map .location-map-list .info-window.service-center.active.exclusive {
    background-color: #f5eacf;
}

#location-map .location-map-list .info-window.flight-schools.exclusive {
    background-color: #ecf5e6;
}

#location-map .location-map-list .info-window.flight-schools.exclusive:hover,
#location-map .location-map-list .info-window.flight-schools.active.exclusive {
    background-color: #dfeed6;
}

#location-map .location-map-list .info-window.distributor.exclusive {
    background-color: #e6ebf0;
}

#location-map .location-map-list .info-window.distributor.exclusive:hover,
#location-map .location-map-list .info-window.distributor.active.exclusive {
    background-color: #dae1e8;
}

#location-map .location-map-list .info-window .media-body .media-left {
    padding-left: 0;
}

#location-map .location-map-list .info-window {
    .media-left {
        display: none;
    }
}

#location-map .location-map-list .info-window .icon-category {
    position: absolute;
    top: 0;
    left: 4px;
}

#location-map .location-map-list .info-window .short {
    margin-bottom: 10px;
}

#location-map .location-map-list .info-window .category {
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: $grid-gutter-width * 0.125;
}

#location-map .location-map-list .info-window .company {
    font-weight: bold;
}

#location-map .location-map-list .info-window .address {
    margin-bottom: 10px;
}

#location-map .location-map-list .info-window .contact {
    display: none;
}

#location-map .list-inline {
    margin-bottom: 5px;
}

#location-map .location-map-category {
    padding-left: 32px;
    padding-top: 10px;
}

#location-map .location-map-category label {
    padding-right: 8px;
    padding-left: 0;
}

#location-map .location-map-category span:before {
    content: '';
    display: inline-block;
    width: 28px;
    height: 28px;
    margin: -6px 8px 0 0;
    vertical-align: middle;
    background: transparent url('../Legacy/DistributorSelector/images/icon-sprite.png') no-repeat 0 0;
}

#location-map .location-map-category-service-center span:before,
#location-map .location-map-category-ae-service-partner span:before {
    background-position: -28px 0;
}

#location-map .location-map-category-flight-schools span:before {
    background-position: -56px 0;
}

#location-map .location-map-category.location-map-category-service-center {
}

#location-map .location-map-category.location-map-category-service-center.collapsed {
    background-color: #f9f2e2;
    border-radius: 5px 5px 0 0;
    border: 1px solid #f9f2e2;
    border-bottom: 1px solid #f9f2e2;
}

#location-map #location-map-support-types.collapse {
    padding: 18px 20px 5px 20px;
}

#location-map #location-map-support-types.collapse.in {
    margin-top: 0px;
    background-color: #f9f2e2;
    border-radius: 5px;
    border: 1px solid #f9f2e2;
    margin-bottom: $grid-gutter-width * 0.5;
    margin-left: $grid-gutter-width * 0.5;
    margin-right: $grid-gutter-width;

    @include media-breakpoint-up(md) {
        margin-top: -15px;
    }
}

#location-map #location-map-support-types.collapse.show {
    display: none !important;
}

#location-map #location-map-support-types {
    select {
        margin-bottom: 8px;
    }
}

#location-map #location-map-canvas {
    height: 700px;
    border: 1px solid #929899;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

#location-map .loading {
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    top: 0;
    color: #afb3b5;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.5);
    opacity: 0;
    display: none;

    &.show {
        opacity: 1;
        display: block;
    }
}

#location-map .loading .fa {
    margin-top: 20%;
}

#location-map .info-window {
    padding-top: 10px;
    border-top: 1px solid #f2f3f4;

    p,
    li,
    br,
    a {
        font-size: 14px;
        line-height: 1.3;

        strong,
        a {
            font-size: 14px;
            line-height: 1.3;
        }
    }

    .supported-countries {
        p {
            margin-bottom: 0;
        }
    }
}

#location-map #location-map-canvas .info-window:first-child {
    border-top: none;
}

#location-map #location-map-canvas .info-window .image {
    display: none;
}

#location-map #location-map-canvas .info-window .short {
    margin-bottom: 10px;
}

#location-map #location-map-canvas .info-window .address,
#location-map #location-map-canvas .info-window .name,
#location-map #location-map-canvas .info-window .details,
#location-map #location-map-canvas .info-window .description {
    margin-bottom: 10px;
}

#location-map #location-map-canvas .info-window .category {
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: $grid-gutter-width * 0.125;
}

#location-map #location-map-canvas .info-window .company {
    font-weight: bold;
}

#location-map #location-map-canvas .info-window .icon {
    float: left;
}

.icon-category:before {
    content: '';
    display: block;
    width: 28px;
    height: 28px;
    margin: 5px 8px -7px 0;
    background: transparent url('../Legacy/DistributorSelector/images/icon-sprite.1472466195.png') no-repeat 0 0;
}

.icon-ae-service-partner:before,
.icon-service-center:before {
    background-position: -28px 0;
}

.icon-flight-schools:before {
    background-position: -55px 0;
}

.media-left {
    padding-right: 10px;
}
