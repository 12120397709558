:root {
    --scroll-padding-top: auto;
}

html {
    scroll-padding-top: var(--scroll-padding-top);
}

.CE_JOBLIST {
    margin-bottom: $grid-gutter-width * 3;

    .jobs-filter {
        background: __get($colors_legacy, 'hellblau.base');

        &__inner {
            column-gap: $grid-gutter-width * 1.5;
            row-gap: $grid-gutter-width;
            legend {
                @include px-to-rem(font-size, __get($font-size, 'xs' 'legend'));

                @include media-breakpoint-up(md) {
                    @include px-to-rem(font-size, __get($font-size, 'md' 'legend'));
                }
                margin-bottom: $grid-gutter-width * 0.75;
                font-weight: 400;
            }

            .label-wrapper {
                gap: $grid-gutter-width * 0.5;
                label {
                    @include px-to-rem(font-size, __get($font-size, 'xs' 'label'));

                    @include media-breakpoint-up(md) {
                        @include px-to-rem(font-size, __get($font-size, 'md' 'label'));
                    }

                    gap: $grid-gutter-width * 0.5;
                }
            }

            input {
                --radio-width: 36px;

                appearance: none;
                width: var(--radio-width);
                height: var(--radio-width);

                &:after {
                    content: '';
                    display: block;
                    width: var(--radio-width);
                    height: var(--radio-width);
                    background: url($checkbox-icon-unchecked);
                }

                &:checked {
                    &:after {
                        background: url($checkbox-icon-checked);
                    }
                }
            }

            fieldset {
                flex: 1 0 auto;
            }

            > label {
                gap: $grid-gutter-width * 0.25;
                font-weight: 400;
                @include px-to-rem(font-size, __get($font-size, 'xs' 'legend'));

                @include media-breakpoint-up(md) {
                    @include px-to-rem(font-size, __get($font-size, 'md' 'legend'));
                }

                select {
                    appearance: none;
                    background-image: url($arrow-down);
                    background-repeat: no-repeat;
                    background-position: right 16px center;
                    width: 100%;
                }
            }
        }
    }

    &.JOBLIST_LATEST .CE_HEADER {
        text-align: center;
        span:after {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.CE_JOBSHOW {
    .job {
        &__wrapper {
            row-gap: $grid-gutter-width * 1.5;

            @include media-breakpoint-up(xl) {
                row-gap: $grid-gutter-width * 2;
            }
        }

        &__header {
            &-image {
                display: grid;
                grid-template-columns: 1fr 1fr;

                .bg-image {
                    grid-row: 1;
                    grid-column: 1 / span 2;
                }

                .employer-logo {
                    grid-row: 1;
                    grid-column: 2;
                    place-self: center;
                }
            }

            .job__description {
                margin-top: $grid-gutter-width * 1.375;

                p {
                    font-size: __get($font-size, 'xs' 'lead');
                    line-height: __get($line-height, 'xs' 'jobDetail');

                    @include media-breakpoint-up(md) {
                        font-size: __get($font-size, 'md' 'lead');
                    }
                }
            }
        }

        &__responsibilites ul,
        &__requiredSkills ul,
        &__additionalQualifications ul {
            gap: $grid-gutter-width * 0.25;
            li {
                font-size: __get($font-size, 'xs' 'table');
                line-height: __get($line-height, 'xs' 'jobDetail');

                @include media-breakpoint-up(md) {
                    font-size: __get($font-size, 'md' 'table');
                }
            }
        }

        &__additional p:not(.h3) {
            font-size: __get($font-size, 'xs' 'table');
            line-height: __get($line-height, 'xs' 'jobDetail');

            @include media-breakpoint-up(md) {
                font-size: __get($font-size, 'md' 'table');
            }
        }
    }
}

.tmpl-Career .CE_FORM {
    padding-bottom: $grid-gutter-width * 1.8125;
    padding-top: $grid-gutter-width * 2;
    margin: 0;
    background-color: __get($theme-colors, 'light-blue');

    .CE_HEADER {
        margin-bottom: $grid-gutter-width * 3;
        h2 {
            margin-bottom: $grid-gutter-width * 1.75;
        }

        p,
        strong {
            font-size: __get($font-size, 'xs' 'lead');
            line-height: __get($line-height, 'xs' 'jobDetail');

            @include media-breakpoint-up(md) {
                font-size: __get($font-size, 'md' 'lead');
            }
        }
    }

    .job-application {
        &__form {
            fieldset {
                margin-bottom: $grid-gutter-width;
                display: flex;
                flex-direction: column;
                row-gap: $grid-gutter-width * 0.75;
                position: relative;

                @include media-breakpoint-up(md) {
                    row-gap: $grid-gutter-width;
                }

                &:not(.job-application__form-privacy) {
                    padding: $grid-gutter-width * 1.125 $grid-gutter-width * 0.75;
                    padding-top: $grid-gutter-width * 3;
                    background-color: __get($theme-colors, 'dai-light');

                    @include media-breakpoint-up(md) {
                        padding: $grid-gutter-width * 1.75 $grid-gutter-width * 1.375;
                        padding-top: $grid-gutter-width * 3.5;
                    }
                }

                > .row {
                    row-gap: $grid-gutter-width;
                }

                &.job-application__form-privacy {
                    padding-top: $grid-gutter-width * 0.75;
                    padding-bottom: $grid-gutter-width * 1.75;
                    .control-label {
                        margin-bottom: $grid-gutter-width;
                    }
                }

                legend {
                    font-weight: 400;
                    position: absolute;
                    top: $grid-gutter-width * 1.125;
                    left: $grid-gutter-width * 0.75;

                    @include media-breakpoint-up(md) {
                        top: $grid-gutter-width * 1.25;
                        left: $grid-gutter-width * 1.375;
                    }
                }

                .form-group {
                    margin: 0;
                }

                input[type='file'] {
                    margin-top: $grid-gutter-width * 0.25;
                    width: fit-content;

                    &::file-selector-button {
                        @extend .btn, .btn-primary, .mr-qg;
                    }
                }

                &.job-application__form-files {
                    .input:not(:has(.file-error-message)):not(:has(.error.help-block)) {
                        display: flex;
                        align-items: center;
                    }

                    .input:has(.file-error-message),
                    .input:has(.error.help-block) {
                        display: flex;
                        flex-direction: column;
                    }

                    .delBtn {
                        border: none;
                        background: none;
                        padding: 0;
                        margin-left: $grid-gutter-width * 0.5;
                        width: $grid-gutter-width * 0.75;
                        height: $grid-gutter-width * 0.75;

                        .icon {
                            width: $grid-gutter-width * 0.75;
                            height: $grid-gutter-width * 0.75;
                            background-image: url($del-icon);
                        }
                    }
                }
            }
        }
    }
}
