@use 'sass:math';

.shadow {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);

    @include motion-safe() {
        transition: all 0.2s ease-in-out;
    }

    &-sm {
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);

        @include motion-safe() {
            transition: all 0.2s ease-in-out;
        }

        &:hover {
            box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.5);
        }
    }

    &-lg {
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.5);
    }
}

.overflow-hidden {
    overflow: hidden;
}

@each $color in map-keys($colors_legacy) {
    .color-#{$color} {
        color: __get($colors_legacy, $color 'base') !important;
    }

    .bgColor-#{$color} {
        background-color: __get($colors_legacy, $color 'base');
    }

    .badge-invert-#{$color} {
        color: __get($colors_legacy, $color 'base') !important;
    }
}

.text-bold {
    font-weight: 400;
}

.o-75 {
    opacity: 0.75;
}

.embed-responsive-5by4 {
    &::before {
        padding-top: percentage(math.div(4, 5));
    }
}

.white-space--nowrap {
    white-space: nowrap;
}

.position-lg-relative {
    @include media-breakpoint-up(lg) {
        position: relative;
    }
}

.position-lg-absolute {
    @include media-breakpoint-up(lg) {
        position: absolute;
    }
}

.bottom-lg-0 {
    @include media-breakpoint-up(lg) {
        bottom: 0;
    }
}

/*
	For usage on a picture element
 */
.subtle-zoom {
    overflow: hidden;

    @at-root {
        @keyframes zoom-subtle-1 {
            0% {
                transform: scale(1);
            }
            100% {
                transform: scale(1.03);
            }
        }
    }

    > img {
        animation-name: zoom-subtle-1;
        animation-duration: 4s;
        animation-delay: 1s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
    }
}

.flex-basis-100 {
    flex: 1 100%;
}

@each $k, $v in $aspect-ratios {
    .ar-#{$k} {
        aspect-ratio: str-replace(#{$v}, ' ', '/');
    }
}
