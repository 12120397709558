.CE_FULL_WIDTH_HEADER {
    > .container-fluid {
        position: relative;
    }

    .CE_TEXT {
        margin-top: $grid-gutter-width;
        margin-bottom: $grid-gutter-width * 2;
        @include media-breakpoint-up(md) {
            margin-top: 0;
        }
    }

    margin-bottom: $grid-gutter-width;

    @include media-breakpoint-up(md) {
        margin-bottom: 0;
    }

    img {
        min-height: 364px;
        object-fit: cover;
    }

    picture {
        margin-bottom: 0;
        @include media-breakpoint-up(md) {
            margin-bottom: $grid-gutter-width * 2;
        }
    }

    h1 {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        color: __get($colors_legacy, 'weiss' 'base');
        padding-bottom: $grid-gutter-width;
        @extend .gradientBG;

        &,
        & * {
            text-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
        }

        margin-bottom: 0;
        @include media-breakpoint-up(md) {
            bottom: $grid-gutter-width * 2;
        }

        span {
            color: __get($colors_legacy, 'weiss' 'base');
        }

        &:after {
            content: '';
            width: 48px;
            height: 2px;
            background-color: __get($colors_legacy, 'weiss' 'base');
            display: block;
            margin: 22px auto 0 auto;
        }
    }

    p {
        text-align: center;
    }
}
