.CE_DOWNLOADS {
    margin-bottom: $grid-gutter-width * 0.5;
    @include media-breakpoint-up(md) {
        margin-bottom: $grid-gutter-width;
    }

    .CE_DOWNLOAD_ITEM {
        @extend .shadow_button;
        padding: $grid-gutter-width;
        margin-bottom: $grid-gutter-width;

        @include motion-safe() {
            transition: all 0.2s ease-in-out;
        }

        &:hover {
            @extend .shadow_button_hover;
        }

        a {
            @include px-to-rem(line-height, __get($line-height, 'xs' 'h6'));
            @include media-breakpoint-up(md) {
                @include px-to-rem(line-height, __get($line-height, 'md' 'h6'));
            }
        }

        .CE_DOWNLOAD_PREVIEW {
            position: relative;
            padding-top: 100%;
            width: 100%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            margin-bottom: $grid-gutter-width * 0.5;
        }
    }
}
