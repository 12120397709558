.layover {
    &__form {
        border-bottom: 1px solid __get($colors_legacy, 'hellgrau.base');
        padding-bottom: $grid-gutter-width * 0.75;
        margin-bottom: $grid-gutter-width * 0.75;
        padding-top: $grid-gutter-width;
    }

    &__label {
        display: block;
        margin-bottom: $grid-gutter-width * 0.5;
    }

    &__select {
        max-width: 424px;
    }

    &__radio {
        position: relative;
        width: 38px;
        height: 38px;
        display: block;
        margin: 0 $grid-gutter-width * 0.25;
        transform: scale(1);

        @include motion-safe() {
            transition: all 0.2s ease-in-out;
        }

        &:hover {
            transform: scale(1.15);
        }

        &-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }

        &-input {
            position: absolute;
            opacity: 0;
            width: 0;
            height: 0;
            overflow: hidden;
        }

        &-image {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
            border-radius: 50%;
        }

        &-input:checked + &-overlay {
            &::after {
                position: absolute;
                top: -4px;
                left: -4px;
                bottom: -4px;
                right: -4px;
                border-radius: 50%;
                display: block;
                content: '';
                border: 2px solid __get($colors_legacy, 'primary.base');
                pointer-events: none;
            }
        }
    }

    &__variants-container {
        display: none;
    }

    &__content {
        display: none;
    }

    &__map {
        position: relative;
        margin-top: $grid-gutter-width;
    }

    &__info {
        display: block;
        position: absolute;
        border: 0;
        background: none;
        top: $grid-gutter-width;
        left: $grid-gutter-width;
        transform: translate(-50%, -50%);
        padding: 0;
    }

    &__modal {
        backdrop-filter: blur(5px);
    }

    &__dialog {
        @include media-breakpoint-up(sm) {
            max-width: 888px;
        }
    }

    &__dialog-content {
        padding: $grid-gutter-width * 2 $grid-gutter-width $grid-gutter-width * 0.5 $grid-gutter-width;

        @include media-breakpoint-up(lg) {
            padding: $grid-gutter-width * 2.5;
        }
    }

    &__dialog-close {
        opacity: 1;
        float: none;
        position: absolute;
        top: $grid-gutter-width;
        right: $grid-gutter-width;
        width: $grid-gutter-width;
        height: $grid-gutter-width;

        span {
            position: absolute;
            top: -15px;
            left: 0;
            width: $grid-gutter-width;
            height: $grid-gutter-width;
            font-size: 2.5rem;
        }
    }
}
