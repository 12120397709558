nav {
    &.subnav {
        margin-bottom: $grid-gutter-width;
    }

    &.sub {
        &.standard {
            position: absolute;
            width: 100%;
            background-color: __get($colors_legacy, 'weiss' 'base');
            top: 41px;
            z-index: 100;
            left: 0;
            display: none;

            ul {
                margin: 0;
                padding: 0 $grid-gutter-width;

                li {
                    border-bottom: 1px solid rgba(__get($colors_legacy, 'hellgrau' 'base'), 0.5);
                    padding: $grid-gutter-width * 0.5 $grid-gutter-width * 0.5;

                    @include media-breakpoint-up(md) {
                        padding: $grid-gutter-width 0;
                    }

                    a:link,
                    a:visited {
                        @include px-to-rem(font-size, __get($font-size, 'xs' 'subNavigation'));
                    }

                    a:hover,
                    a:active,
                    a:focus {
                        text-decoration: underline;
                        color: __get($colors_legacy, 'secondary' 'base');
                    }

                    a.active {
                        color: __get($colors_legacy, 'secondary' 'base');
                    }

                    &:last-child {
                        border-bottom: none;

                        header.main & {
                            @extend .pr-0;
                        }
                    }
                }
            }

            @include media-breakpoint-up(md) {
                display: block !important;
                position: inherit;
                background-color: transparent;
                margin: 8px 0;
                box-shadow: none;
                width: auto;
                top: 0px;

                ul {
                    li {
                        border: none;
                        padding: 0 $grid-gutter-width * 0.5;
                    }
                }
            }
        }

        ul {
            margin: 0;

            li {
                margin: 0;
                padding: 0 20px;

                &.buy {
                    padding-right: 0;
                    padding-left: $grid-gutter-width;

                    @include media-breakpoint-up(md) {
                        padding-left: 0;
                    }

                    a.buy {
                        &:link,
                        &:visited,
                        &:hover,
                        &:active {
                            @include px-to-rem(font-size, __get($font-size, 'md' 'small'));
                            @include px-to-rem(line-height, __get($line-height, 'md' 'small'));
                        }
                    }
                }

                a:link,
                a:visited {
                    color: __get($colors_legacy, 'dunkelgrau' 'base');
                    @include px-to-rem(font-size, __get($font-size, 'xs' 'small'));
                    @include px-to-rem(line-height, __get($line-height, 'xs' 'small'));

                    @include media-breakpoint-up(md) {
                        @include px-to-rem(font-size, __get($font-size, 'md' 'small'));
                        @include px-to-rem(line-height, __get($line-height, 'md' 'small'));
                    }

                    display: block;

                    &.buy {
                        color: __get($colors_legacy, 'weiss' 'base');
                        background-color: __get($theme-colors, 'primary');
                        border-radius: 3px;
                        border: 1px solid __get($theme-colors, 'primary');
                        @include px-to-rem(font-size, __get($font-size, 'xs' 'button_small'));
                        @include px-to-rem(line-height, __get($line-height, 'xs' 'button_small'));
                        box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.15);

                        @include media-breakpoint-up(md) {
                            @include px-to-rem(font-size, __get($font-size, 'md' 'button_small'));
                            @include px-to-rem(line-height, __get($line-height, 'md' 'button_small'));
                        }

                        padding: 0px 10px;
                    }
                }

                a:hover,
                a:active,
                a:focus,
                .active {
                    color: __get($colors_legacy, 'hellgrau' 'base');
                    text-decoration: none;

                    &.buy {
                        box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.3);
                        position: relative;
                        top: -1px;
                        background-color: __get($colors_legacy, 'secondary' 'base');
                        border: 1px solid __get($colors_legacy, 'secondary' 'base');
                    }
                }

                a:focus,
                a:active {
                    &.buy {
                        outline: none;
                        position: relative;
                        top: 0px;
                        box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
                    }
                }
            }
        }
    }
}
