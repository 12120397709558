.mega-menu {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100vh;
    z-index: 20;
    background: __get($colors_legacy, 'hellblau.base');

    &::before {
        display: block;
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        background: __get($colors_legacy, 'weiss.base');
        z-index: -1;

        @each $breakpoint, $height in __get($heights, 'navigation') {
            @include media-breakpoint-up($breakpoint) {
                height: $height;
            }
        }
    }

    @include motion-safe() {
        transform: translateX(100%);
        transition: transform 0.3s ease;

        @include media-breakpoint-up(xl) {
            transform: translateY(-100%);
        }
    }

    @include media-breakpoint-up(xl) {
        background: __get($colors_legacy, 'hellblau.base');

        &::before {
            display: none;
        }
    }

    &--active {
        transform: none;
    }

    &__content {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding-bottom: 150px;
        -ms-overflow-style: none;
        scrollbar-width: none;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }

        @each $breakpoint, $height in __get($heights, 'navigation') {
            @include media-breakpoint-up($breakpoint) {
                top: $height;
            }
        }

        @include motion-safe() {
            opacity: 0;
            transition: opacity 0.3s ease;

            &--active {
                opacity: 1;
            }
        }

        @include media-breakpoint-up(xl) {
            padding-bottom: $grid-gutter-width;
        }

        @include media-breakpoint-up(xxl) {
            .btn {
                font-size: 26px;
            }
        }
    }

    &__request-btn.btn.btn-primary {
        height: auto;
        line-height: 1;
        padding: $grid-gutter-width * 0.25 $grid-gutter-width * 0.5;

        @include media-breakpoint-up(md) {
            padding: $grid-gutter-width * 0.5 $grid-gutter-width;
        }
    }

    &__toggle {
        position: absolute;
        top: 0;
        left: $grid-gutter-width * 0.5;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 48px;
        min-width: 48px;
        padding: 0;
        border: 0;
        background: transparent;

        @each $breakpoint, $height in __get($heights, 'navigation') {
            @include media-breakpoint-up($breakpoint) {
                height: $height;
            }
        }

        @include media-breakpoint-up(xl) {
            left: unset;
            right: $grid-gutter-width * 0.25;
        }

        &-burger {
            display: block;
            margin-right: $grid-gutter-width * 0.25;

            @include media-breakpoint-up(xl) {
                width: $grid-gutter-width;
                color: __get($colors_legacy, 'schwarz.base');
                margin-right: 0;

                &::before,
                &::after {
                    display: block;
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 2px;
                    background: currentColor;
                    transform-origin: center center;
                    left: 50%;
                    margin-left: -50%;
                }

                &::before {
                    transform: rotateZ(45deg);
                }

                &::after {
                    transform: rotateZ(-45deg);
                }
            }
        }

        &-back {
            margin-right: $grid-gutter-width * 0.25;
            transform: rotate(180deg);
        }
    }

    .container {
        margin-top: 72px;
    }

    &__title {
        @extend h2;
        margin-top: 0;
        margin-bottom: $grid-gutter-width * 0.5;
        font-weight: 400;
    }

    &__subtitle {
        @extend h4;
        margin: 0 0 $grid-gutter-width * 1.5;
    }

    &__banner {
        position: relative;
        margin-bottom: $grid-gutter-width * 1.5;

        &::after {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: '';
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 45%, #050f20 100%);
            z-index: 2;
        }
    }

    &__banner-title {
        position: absolute;
        bottom: $grid-gutter-width * 0.25 * 3;
        left: $grid-gutter-width * 0.5;
        z-index: 3;
        color: __get($colors_legacy, 'weiss.base');
    }

    &__banner-image {
        display: block;
        width: 100%;
        z-index: 1;
    }

    &__banner-button {
        position: absolute !important;
        top: unset !important;
        bottom: $grid-gutter-width * 0.25 * 3 !important;
        right: $grid-gutter-width * 0.5;
        z-index: 3;

        &,
        &:focus,
        &:hover {
            background: __get($colors_legacy, 'weiss.base') !important;
            color: $primary !important;
        }
    }
}
