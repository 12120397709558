p,
.p {
    font-size: __get($font-size, 'xs' 'text');
    line-height: __get($line-height, 'xs' 'text');
    margin: 0 0 12px 0;
    @include media-breakpoint-up(md) {
        margin-bottom: $grid-gutter-width * 0.5;
    }

    &.lead,
    &.lead * {
        font-size: __get($font-size, 'xs' 'lead');
        line-height: __get($line-height, 'xs' 'lead');
    }

    @include media-breakpoint-up(md) {
        font-size: __get($font-size, 'md' 'text');
        line-height: __get($line-height, 'md' 'text');

        &.lead,
        &.lead * {
            font-size: __get($font-size, 'md' 'lead');
            line-height: __get($line-height, 'md' 'lead');
        }
    }

    &:last-child {
        margin-bottom: 0;
    }

    a:link,
    a:visited {
        color: __get($theme-colors, 'primary');
    }

    a:hover,
    a:active,
    a:focus {
        outline: 0;
        color: __get($theme-colors, 'primary-highlight');
    }

    .teaser-text & {
        font-size: __get($font-size, 'xs' 'teaser');
        @include media-breakpoint-up(md) {
            font-size: __get($font-size, 'md' 'teaser');
        }
    }

    &.small {
        font-size: __get($font-size, 'xs' 'small');
        line-height: __get($line-height, 'xs' 'small');

        @include media-breakpoint-up(md) {
            font-size: __get($font-size, 'md' 'small');
            line-height: __get($line-height, 'md' 'small');
        }
    }
}
