.CE_YOUTUBE {
    margin-bottom: 44px;
    position: relative;

    @include print() {
        display: none;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-left: $grid-gutter-width;
        margin-right: $grid-gutter-width;

        @include media-breakpoint-up(lg) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    figcaption {
        font-size: __get($font-size, 'xs' 'figcaption');
        margin-top: 9px;
        color: __get($colors_legacy, 'mittelgrau' 'base');
    }

    img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        border: 0;
    }

    .CE_YOUTUBE_GDPR_OVERLAY {
        position: inherit;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 20px;
    }

    @include media-breakpoint-up(sm) {
        .CE_YOUTUBE_GDPR_OVERLAY {
            position: absolute;
            top: 20px;
            bottom: 20px;
            left: 20px;
            right: 20px;
            background-color: rgba(__get($colors_legacy, 'weiss' 'base'), 0.8);
        }
    }
}
