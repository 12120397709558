.dropdown,
.dropup {
    button {
        &.dropdown-toggle {
            background-color: transparent;
            border-width: 1px;
            border-style: solid;
            border-color: __get($colors_legacy, 'schwarz' 'base');
            border-radius: 3px;
            color: __get($colors_legacy, 'schwarz' 'base');
            padding: 4px $grid-gutter-width * 0.5;
            cursor: pointer;

            &:focus {
                outline: none;
            }

            &:after {
                position: relative;
                top: -4px;
                border-style: solid;
                border-width: 2px 2px 0 0;
                content: '';
                display: inline-block;
                height: 8px;
                width: 8px;
                transform: rotate(135deg);
                vertical-align: middle;
                border-bottom: 0;
                border-left: 0;
                border-right: 2px solid;
            }
        }
    }

    .dropdown-menu {
        padding: $grid-gutter-width * 0.5 $grid-gutter-width;
        border-radius: 0;
        margin-bottom: 0;
        @extend .shadow_box;
        min-width: 250px;
        border-color: transparent;

        a:link,
        a:visited {
            line-height: 40px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        a:hover,
        a:active,
        a:focus {
            color: __get($theme-colors, 'primary-highlight');
        }
    }
}
