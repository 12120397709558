nav {
    &.meta {
        display: none;
        @include media-breakpoint-up(lg) {
            display: block;
            margin-top: -5px;

            @include print() {
                display: none;
            }
        }

        ul {
            margin-bottom: 14px;
            @include media-breakpoint-up(lg) {
                margin-bottom: 10px;
            }

            li {
                position: relative;

                a:link,
                a:visited {
                    color: __get($colors_legacy, 'mittelgrau' 'base');
                    padding: 0 0 0 20px;
                    @include media-breakpoint-up(lg) {
                        font-size: __get($font-size, 'lg' 'meta');
                    }
                }

                a:hover,
                a:active,
                a.active {
                    color: __get($colors_legacy, 'rot' 'base');
                }

                ul {
                    position: absolute;
                    left: 8px;
                    top: 20px;
                    width: 100%;
                    min-width: 200px;
                    background-color: __get($colors_legacy, 'weiss' 'base');
                    @extend .shadow-left-right-bottom;
                    padding: 14px;
                    display: none;
                    z-index: 1000;

                    li {
                        a:link,
                        a:visited {
                            text-transform: none;
                            padding: 0;
                            margin-bottom: 16px;
                        }

                        a:hover,
                        a:active {
                        }
                    }
                }

                &:hover {
                    ul {
                        display: block;
                    }
                }
            }
        }
    }
}
