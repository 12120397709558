.timeline {
    &-entry {
        margin-bottom: 0;
    }

    &-wrapper {
        position: relative;

        &:after {
            content: '';
            width: 2px;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            margin-left: -1px;
            background: __get($colors_legacy, 'primary.base');
            z-index: -1;

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }
    }

    &-item {
        display: flex;
        flex-direction: column;
        margin-bottom: $grid-gutter-width * 1.5;

        @include media-breakpoint-up(lg) {
            display: grid;
            grid-template-rows: 1fr auto 1fr;
            grid-template-columns: 1fr 1fr;
            margin-bottom: 0;
        }

        &:before {
            content: '';
            grid-row: 1 / span 3;
            grid-column: 1;
            width: 2px;
            background: __get($colors_legacy, 'primary.base');
            justify-self: flex-end;
            transform: translateX(1px);

            .timeline-entry:first-child & {
                grid-row: 2 / span 3;
            }

            .timeline-entry:last-child & {
                grid-row: 1 / span 2;
            }
        }

        &__year,
        &__data {
            grid-column: 1;
            grid-row: 1 / span 3;
        }

        &__year {
            margin-bottom: $grid-gutter-width * 1.5;
            grid-column: 1;
            align-self: center;
            justify-self: flex-end;
            font-weight: bold;
            line-height: 1;
            color: __get($colors_legacy, 'primary.base');
            border: 2px solid __get($colors_legacy, 'primary.base');
            background: __get($colors_legacy, 'weiss.base');
            position: relative;
            font-size: 28px;
            padding: $grid-gutter-width * 0.5 $grid-gutter-width * 0.75;

            @include media-breakpoint-up(lg) {
                font-size: 34px;
                margin-bottom: 0;
                transform: translateX(50%);
                padding: $grid-gutter-width * 0.75 $grid-gutter-width;
            }

            &:after {
                content: '';
                position: absolute;
                width: 6px;
                height: 12px;
                background: __get($colors_legacy, 'primary.base');
                right: -6px;
                top: 50%;
                margin-top: -6px;

                .timeline-entry:nth-child(2n) & {
                    left: -6px;
                    right: auto;
                }
            }

            &:before {
                content: '';
                position: absolute;
                width: 60px;
                height: 2px;
                top: 50%;
                margin-top: -1px;
                right: -60px;
                background: __get($colors_legacy, 'primary.base');

                .timeline-entry:nth-child(2n) & {
                    left: -60px;
                    right: auto;
                }
            }

            &:after,
            &:before {
                display: none;

                @include media-breakpoint-up(lg) {
                    display: block;
                }
            }
        }

        &__data {
            grid-column: 2;

            @include media-breakpoint-up(lg) {
                margin-left: 120px;
            }

            @include make-card();

            .timeline-entry:nth-child(2n) & {
                grid-column: 1;
                margin-left: 0;

                @include media-breakpoint-up(lg) {
                    margin-right: 120px;
                }
            }
        }

        &__image {
            flex: 0 auto;

            .picturelink {
                @include picturelink;
            }

            .icon {
                @include picturelinkIcon;
            }
        }

        &__link {
            position: relative;
            display: flex;
            align-items: center;
            gap: $grid-gutter-width * 0.25;

            &:after {
                content: '';
                width: 8px;
                height: 8px;
                border-top: 1px solid currentColor;
                border-right: 1px solid currentColor;
                transform: rotate(45deg);
                margin-top: 2px;
            }
        }
    }
}
