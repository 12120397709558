body {
    &.installateur,
    &.productoverview,
    &.tmpl-Productoverview {
        .CE_BREADCRUMB {
            background-color: __get($colors_legacy, 'hellbeige_3' 'base');
            padding-top: 44px;
            display: none;
            @include media-breakpoint-up(lg) {
                display: block;
            }

            nav {
                &.breadcrumb {
                    @include media-breakpoint-up(lg) {
                        margin-left: $grid-gutter-width;
                        margin-right: $grid-gutter-width;
                    }
                }
            }
        }
    }

    &.tmpl-News {
        .CE_BREADCRUMB {
            nav {
                &.breadcrumb {
                    @include media-breakpoint-up(lg) {
                        margin-left: $grid-gutter-width;
                        margin-right: $grid-gutter-width;
                    }
                }
            }
        }
    }

    &.tmpl-Product {
        .CE_BREADCRUMB {
            nav {
                &.breadcrumb {
                    @include media-breakpoint-up(lg) {
                        margin-left: $grid-gutter-width;
                        margin-right: $grid-gutter-width;
                    }
                    @include media-breakpoint-up(xl) {
                        margin-left: 0;
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

nav {
    &.breadcrumb {
        margin-top: 38px;
        margin-bottom: 38px;
        margin-left: $grid-gutter-width;
        margin-right: $grid-gutter-width;
        background-color: transparent;

        padding: 0;

        @include media-breakpoint-up(lg) {
            margin-left: 0;
            margin-right: 0;
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: 80px;
        }

        ul {
            padding: 0;
            margin-bottom: 0;
            background-color: transparent;

            li {
                a:link,
                a:visited {
                    padding: 0;
                    color: __get($colors_legacy, 'dunkelgrau' 'base');
                }

                a:hover,
                a:active,
                a.active {
                    color: __get($colors_legacy, 'rot' 'base');
                }

                @include print() {
                    a {
                        text-decoration: none !important;
                    }
                }

                a {
                    &:after {
                        content: '\f101';
                        font-family: $font-family-icon;
                        color: __get($colors_legacy, 'dunkelgrau' 'base');
                        padding: 0 8px;
                        font-size: 12px;
                    }
                }

                &:last-child {
                    a {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
