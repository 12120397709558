body {
    content: "#{implode($grid-breakpoints, ',')}";

    &:after {
        @each $breakpoint in map-keys($grid-breakpoints) {
            @include media-breakpoint-only($breakpoint) {
                content: '#{$breakpoint}';
            }
        }

        position: fixed;
        line-height: 1;
        font-size: 12px;
        color: #000;
        bottom: 0px;
        right: 0px;
        padding: 2px 5px;
        background: rgba(255, 0, 0, 1);
        text-transform: uppercase;
        z-index: 9999;
    }

    &:not(.develop) {
        &:after {
            opacity: 0;
        }
    }
}

head {
    content: "#{implode($grid-breakpoints, ',')}";
}
