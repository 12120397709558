@use 'sass:math';

//***************************************************************************
// DEFINE MARKUP CLASSNAMES
//***************************************************************************
$slider-class: 'highlight-slider';

$slider-item-class: '#{$slider-class}--item';
$slider-item_active-class: '#{$slider-item-class}__active';
$slider-item_start-class: '#{$slider-item-class}__start';
$slider-item_end-class: '#{$slider-item-class}__end';

$slider-lead-class: '#{$slider-class}--lead';
$slider-heading-class: '#{$slider-class}--heading';
$slider-chevron-class: '#{$slider-class}--chevron';
$slider-media-class: '#{$slider-class}--media';
$slider-content-wrapper-class: '#{$slider-class}--content-wrapper';
$slider-infobox-class: '#{$slider-class}--description-toggle';

$slider-content-class: '#{$slider-class}--content';
$slider-content_bottom-class: '#{$slider-content-class}__info-bottom';
$slider-content_right-class: '#{$slider-content-class}__info-right';

$slider-arrow-class: '#{$slider-class}--arrow';
$slider-arrow_right-class: '#{$slider-arrow-class}__right';
$slider-arrow-helper-class: '#{$slider-class}--arrow-helper';
$slider-arrow-hitbox-class: '#{$slider-class}--arrow-hitbox';

$slider-description-class: '#{$slider-class}--description';

//***************************************************************************
// DEFINE GENERAL SETTINGS
//***************************************************************************
$slider-breakpoint: 'md';
$slider-breakpoint-down: 'sm';
$slider-spacing: 3px;
$slider-lead-height: 70px;
$slider-chevron-thickness: 3px;
$slider-chevron-size: 25px;
$slider-collapse-transition-duration: 0.5s;
$slider-content-horizontal-spacing: 120px;
$slider-content-vertical-spacing: $grid-gutter-width;

//***************************************************************************
// CSS
//***************************************************************************
.#{$slider-class} {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;

    @include media-breakpoint-up($slider-breakpoint) {
        padding-top: percentage(math.div(math.div(100, 16) * 9, 100));
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
    }

    @include media-breakpoint-up('xl') {
        padding-left: 14%;
        padding-right: 14%;
    }
}

.#{$slider-item-class} {
    @include media-breakpoint-up($slider-breakpoint) {
        max-width: 230px;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: 230px;
        margin: 0 $grid-gutter-width * 0.5;
    }
}

.#{$slider-lead-class} {
    background-color: $primary;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 $grid-gutter-width * 0.5;
    border: none;
    width: 100%;

    @include motion-safe() {
        transition: background-color $slider-collapse-transition-duration ease;
    }

    @include media-breakpoint-down($slider-breakpoint-down) {
        color: $white;
        height: $slider-lead-height;

        .#{$slider-item_active-class} & {
            background-color: $blue-500;
        }
    }

    @include media-breakpoint-up($slider-breakpoint) {
        background-color: transparent;
        transition-property: border-bottom-color, color;
        transition-duration: $slider-collapse-transition-duration, $slider-collapse-transition-duration;
        transition-timing-function: ease, ease;
        border-bottom: 2px solid transparent;
        align-items: flex-end;
        padding: 20px 0 0;
        color: __get($theme-colors, 'grey');

        .#{$slider-item_active-class} &,
        &:hover {
            color: __get($theme-colors, 'primary');
            border-bottom-color: __get($theme-colors, 'primary');
        }
    }
}

.#{$slider-heading-class} {
    color: inherit;
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
    font-weight: 300;
    white-space: nowrap;
    text-overflow: ellipsis;

    @include media-breakpoint-up($slider-breakpoint) {
        font-size: 1rem;
    }
}

.#{$slider-chevron-class} {
    display: block;
    position: relative;
    width: $slider-chevron-size;
    height: $slider-chevron-size;

    @include motion-safe() {
        transition: transform $slider-collapse-transition-duration ease;
    }

    &:after {
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0) rotateZ(-45deg);
        border-color: currentColor;
        border-style: solid;
        border-width: $slider-chevron-thickness 0 0 $slider-chevron-thickness;
        height: $slider-chevron-size * 0.66;
        width: $slider-chevron-size * 0.66;
        margin-left: 15%;
        flex-shrink: 0;
    }

    .#{$slider-item_active-class} & {
        transform: rotateZ(-90deg);
    }

    @include media-breakpoint-up($slider-breakpoint) {
        display: none;
    }
}

.#{$slider-content-class} {
    overflow: hidden;

    @include media-breakpoint-down($slider-breakpoint-down) {
        height: 0;
        padding-top: $slider-spacing;

        @include motion-safe() {
            transition: height $slider-collapse-transition-duration linear;
        }

        .#{$slider-item_active-class} & {
            height: auto;
        }
    }

    @include media-breakpoint-up($slider-breakpoint) {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        .#{$slider-item_active-class} & {
            display: block;
        }
    }
}

@include media-breakpoint-up($slider-breakpoint) {
    .#{$slider-content-wrapper-class} {
        .#{$slider-item_active-class} & {
            transition: transform $slider-collapse-transition-duration linear;

            @media (prefers-reduced-motion) {
                transition: transform 1ms linear;
            }
        }

        .#{$slider-item_start-class} & {
            transform: translate3d(100%, 0, 0);
        }

        .#{$slider-item_end-class} & {
            transform: translate3d(-100%, 0, 0);
        }
    }
}

.#{$slider-description-class} {
    padding: $grid-gutter-width * 0.5;

    @include media-breakpoint-up($slider-breakpoint) {
        position: absolute;
        top: $slider-content-vertical-spacing;
        left: $slider-content-horizontal-spacing;
        background: $white;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
        max-width: 500px;
        padding: $grid-gutter-width;
        opacity: 0;
        transform: translate3d(-$slider-content-horizontal-spacing, -$slider-content-vertical-spacing, 0);

        @include motion-safe() {
            transition:
                opacity $slider-collapse-transition-duration ease,
                transform $slider-collapse-transition-duration ease;
        }

        .#{$slider-content_bottom-class} & {
            top: auto;
            bottom: $slider-content-vertical-spacing;
            transform: translate3d(-$slider-content-horizontal-spacing, $slider-content-vertical-spacing, 0);
        }

        .#{$slider-content_right-class} & {
            left: auto;
            right: $slider-content-horizontal-spacing;
            transform: translate3d($slider-content-horizontal-spacing, -$slider-content-vertical-spacing, 0);
        }

        .#{$slider-content_bottom-class}.#{$slider-content_right-class} & {
            transform: translate3d($slider-content-horizontal-spacing, $slider-content-vertical-spacing, 0);
        }

        > *:first-child {
            margin-top: 0;
        }

        &.open,
        .#{$slider-content-class}:hover &,
        &:focus-within {
            opacity: 1;
            transform: none !important;
        }
    }
}

.#{$slider-infobox-class} {
    display: none;
}

@include media-breakpoint-up($slider-breakpoint) {
    .#{$slider-arrow-class} {
        display: block;
        width: 10px;
        height: 10px;
        position: absolute;
        background: transparent;
        top: 50%;
        left: 50px;
        z-index: 7;
        transform: rotateZ(-45deg);
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.5);
        opacity: 0;
        cursor: pointer;
        margin-top: -35px;

        @include motion-safe() {
            transition: opacity $slider-collapse-transition-duration ease;
        }

        &:before,
        &:after {
            display: block;
            position: absolute;
            content: '';
            background: inherit;
            box-shadow: inherit;
            z-index: -1;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }

        &:before {
            width: 300%;
            left: 100%;
        }

        &:after {
            height: 300%;
            top: 100%;
        }

        &.#{$slider-arrow_right-class} {
            transform: rotateZ(135deg);
            right: 50px;
            left: auto;
        }

        .#{$slider-class}:hover & {
            opacity: 0.5;
        }
    }

    .#{$slider-arrow-helper-class} {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $white;
        z-index: 7;

        &:before,
        &:after {
            display: block;
            position: absolute;
            content: '';
            background: inherit;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }

        &:before {
            left: 50%;
            width: 350%;
        }

        &:after {
            top: 50%;
            height: 350%;
        }
    }

    .#{$slider-arrow-hitbox-class} {
        display: block;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
    }

    .#{$slider-infobox-class} {
        display: block;
        position: absolute;
        top: $slider-content-vertical-spacing;
        left: $slider-content-horizontal-spacing;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 3px solid $white;
        box-shadow:
            0 0 8px rgba($black, 0.8),
            0 0 8px rgba($black, 0.8) inset;
        opacity: 0.5;
        margin: 8px;
        z-index: 1;

        &:before {
            display: block;
            position: absolute;
            content: 'i';
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            font-weight: bold;
            color: $white;
            font-size: 1rem;
            text-shadow: 0 0 8px rgba($black, 0.8);
        }

        .#{$slider-content_bottom-class} & {
            top: auto;
            bottom: $slider-content-vertical-spacing;
        }

        .#{$slider-content_right-class} & {
            left: auto;
            right: $slider-content-horizontal-spacing;
        }
    }
}

.CE {
    &.CE_HIGHLIGHT_SLIDER {
        .CE_HEADER {
            margin-bottom: 24px;
        }
    }
}

.CE_HIGHLIGHT_SLIDER {
    overflow: hidden;
}
