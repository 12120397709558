/*
 Static close-X (e.g. for GridNavigation usage on subpages)

 	Following HTML markup should be used:
 	Emmet:
 		div.closing-x > (span*3)
 */
.closing-x {
    height: 20px;
    width: 30px;
    z-index: 5;
    position: relative;
    display: block;

    span {
        position: absolute;
        width: 100%;
        height: 3px;
        margin-top: -1px;
        right: 0;
        display: block;
        background: __get($colors_legacy, 'dunkelgrau' 'base');
        opacity: 0;
        top: 50%;
    }

    span:first-child {
        opacity: 1;
        transform: rotate(405deg);
    }

    span:last-child {
        opacity: 1;
        transform: rotate(-405deg);
    }

    &.color-white span {
        background: __get($colors_legacy, 'weiss' 'base');
    }
}
