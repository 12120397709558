.sticky-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $grid-gutter-width * 0.25 $grid-gutter-width * 0.5;
    position: relative;
    max-width: __get($container-max-widths, 'ultrawide');

    @include media-breakpoint-up(md) {
        padding: $grid-gutter-width * 0.5;
        margin: 0 auto;
    }

    @include media-breakpoint-up(ultrawide) {
        overflow: hidden;
    }

    &__list {
        margin: 0;
        list-style: none;
        display: none;
        position: absolute;
        top: calc(100% + #{$grid-gutter-width * 0.25});

        @include media-breakpoint-up(md) {
            top: calc(100% + #{$grid-gutter-width * 0.5});
        }

        left: $small-logo-width;

        .show-small-logo & {
            left: -$grid-gutter-width * 0.5;
        }

        .show-small-logo.scrolled.visible & {
            left: $small-logo-width;
        }

        width: 100vw;

        @include media-breakpoint-up(lg) {
            width: auto;
        }

        background: __get($colors_legacy, 'hellblau.base');
        overflow: hidden;
        box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.25);
        padding: 0;

        @include motion-safe() {
            transition: height 0.2s ease;
        }

        @include media-breakpoint-up(lg) {
            position: static;
            display: flex !important;
            align-items: center;
            justify-content: flex-start;
            gap: $grid-gutter-width;
            background: none;
            top: unset;
            left: unset;
            right: unset;
            box-shadow: unset;
            height: auto !important;
            padding-left: 0;
        }

        @include media-breakpoint-up(xxl) {
            gap: $grid-gutter-width;
        }
    }

    &__item {
        & + & {
            .sticky-nav__link {
                padding-top: 0;
            }
        }
    }

    &__mobile-toggle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: $grid-gutter-width * 1.5;
        height: $grid-gutter-width * 1.5;
        border: 0;
        padding: 0;
        background: transparent;
        margin-left: $grid-gutter-width * 0.25 * -1;

        svg {
            transform: rotateZ(0);
            transform-origin: center center;

            @include motion-safe() {
                transition: transform 0.2s ease;
            }
        }

        &[aria-expanded='true'] svg {
            transform: rotateZ(-180deg);
        }

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    &__link {
        display: block;
        color: currentColor;
        padding: $grid-gutter-width * 0.5;

        @include media-breakpoint-up(lg) {
            padding: 0;
        }

        @include motion-safe() {
            transition: color 0.2s ease;
        }

        &:hover,
        &:focus {
            color: $primary;
            text-decoration: none;
        }

        &.active {
            color: $primary;
        }
    }

    .btn {
        margin-bottom: 0;
        height: $grid-gutter-width;
        line-height: $grid-gutter-width;
    }

    .lead {
        margin-bottom: 0;
        flex: 1 0 auto;

        @include media-breakpoint-up(lg) {
            margin-right: $grid-gutter-width;
        }
    }

    .transform-logo {
        display: flex;
        align-items: center;
        transform: translateX(-($small-logo-width + ($grid-gutter-width * 0.5)));

        @include motion-safe() {
            transition: all 0.2s ease-in-out;
        }

        .show-small-logo & {
            transform: translateX(0);
        }

        .show-small-logo.scrolled.visible & {
            transform: translateX(-($small-logo-width + ($grid-gutter-width * 0.5)));
        }

        @include media-breakpoint-up(md) {
            margin-right: $grid-gutter-width;
        }

        @include media-breakpoint-up(lg) {
            transform: translateX(-($small-logo-width + ($grid-gutter-width)));

            .show-small-logo.scrolled.visible & {
                transform: translateX(-($small-logo-width + ($grid-gutter-width)));
            }
        }
    }

    // fix optical center
    &__nav-wrapper {
        @include media-breakpoint-up(lg) {
            margin-top: 4px;
        }
    }
}
