h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: $font-family-sans-serif;
    font-weight: 300;
    width: 100%;
}

h1,
.h1 {
    color: __get($colors_legacy, 'schwarz' 'base');
    @include px-to-rem(font-size, __get($font-size, 'xs' 'h1'));
    @include px-to-rem(line-height, __get($line-height, 'xs' 'h1'));

    margin: 6px 0 12px 0;

    @include media-breakpoint-up(md) {
        @include px-to-rem(font-size, __get($font-size, 'md' 'h1'));
        @include px-to-rem(line-height, __get($line-height, 'md' 'h1'));

        margin: 8px 0 16px 0;
    }
}

h2,
.h2 {
    color: __get($colors_legacy, 'schwarz' 'base');
    @include px-to-rem(font-size, __get($font-size, 'xs' 'h2'));
    @include px-to-rem(line-height, __get($line-height, 'xs' 'h2'));

    margin: 8px 0 16px 0;

    @include media-breakpoint-up(md) {
        @include px-to-rem(font-size, __get($font-size, 'md' 'h2'));
        @include px-to-rem(line-height, __get($line-height, 'md' 'h2'));
    }
}

h3,
.h3 {
    color: __get($colors_legacy, 'schwarz' 'base');
    @include px-to-rem(font-size, __get($font-size, 'xs' 'h3'));
    @include px-to-rem(line-height, __get($line-height, 'xs' 'h3'));

    margin: 8px 0 16px 0;

    @include media-breakpoint-up(md) {
        @include px-to-rem(font-size, __get($font-size, 'md' 'h3'));
        @include px-to-rem(line-height, __get($line-height, 'md' 'h3'));
    }
}

h4,
.h4 {
    color: __get($colors_legacy, 'schwarz' 'base');
    @include px-to-rem(font-size, __get($font-size, 'xs' 'h4'));
    @include px-to-rem(line-height, __get($line-height, 'xs' 'h4'));

    margin: 8px 0 16px 0;

    @include media-breakpoint-up(md) {
        @include px-to-rem(font-size, __get($font-size, 'md' 'h4'));
        @include px-to-rem(line-height, __get($line-height, 'md' 'h4'));
    }
}

h5,
.h5 {
    color: __get($colors_legacy, 'schwarz' 'base');
    @include px-to-rem(font-size, __get($font-size, 'xs' 'h5'));
    @include px-to-rem(line-height, __get($line-height, 'xs' 'h5'));

    margin: 8px 0 16px 0;

    @include media-breakpoint-up(md) {
        @include px-to-rem(font-size, __get($font-size, 'md' 'h5'));
        @include px-to-rem(line-height, __get($line-height, 'md' 'h5'));
    }
}

h6,
.h6 {
    color: __get($colors_legacy, 'schwarz' 'base');
    @include px-to-rem(font-size, __get($font-size, 'xs' 'h6'));
    @include px-to-rem(line-height, __get($line-height, 'xs' 'h6'));
    @extend .text-bold;

    margin: 6px 0 13px 0;

    @include media-breakpoint-up(md) {
        @include px-to-rem(font-size, __get($font-size, 'md' 'h6'));
        @include px-to-rem(line-height, __get($line-height, 'md' 'h6'));
    }
}
