.form-group {
    margin-bottom: 18px;
}

.form-check {
    padding-left: 1.5rem;

    label {
        padding-left: 4px;
    }
}

.form-control {
    border-radius: 0;
    padding: 0 14px;
    font-size: __get($font-size, 'xs' 'input');
    line-height: __get($line-height, 'xs' 'input');
    color: __get($colors_legacy, 'dunkelgrau' 'base');
    border-color: __get($colors_legacy, 'hellgrau' 'base');

    @include media-breakpoint-up(lg) {
        font-size: __get($font-size, 'lg' 'input');
        line-height: __get($line-height, 'lg' 'input');
    }
}
