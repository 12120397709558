.teaser-box {
    padding: $grid-gutter-width * 2;
    padding-left: 25%;

    h2 {
        position: relative;
        margin: 0;

        i {
            position: absolute;
            left: -64px;
            top: 0;

            @include media-breakpoint-up(sm) {
                left: -96px;
            }

            &:before {
                width: 48px;
                height: 40px;
            }
        }
    }
}
