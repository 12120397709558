:root {
    --logo-color-sign: #1a3869;
    --logo-color-word: #384044;
}

.logo {
    margin: $grid-gutter-width * 0.5 $grid-gutter-width $grid-gutter-width * 0.25 0;

    @include media-breakpoint-up(lg) {
        margin: 0 $grid-gutter-width 0 0;
    }

    svg {
        width: 153px;
        height: 30px;

        @include media-breakpoint-up(lg) {
            width: 200px;
            height: 40px;
        }
    }
}

.DAILogoSmall {
    width: 40px;
    height: 32px;
    margin-top: $grid-gutter-width * 0.25;
    margin-right: $grid-gutter-width * 0.5;

    @include media-breakpoint-up(lg) {
        margin-right: $grid-gutter-width;
    }
}
