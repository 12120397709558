$ce-overview-teaser_md-textbox-above-image-pixels: 128px;
$ce-overview-teaser_md-textbox-padding: 24px 29px;

$ce-overview-teaser_textbox-box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.5);

.CE_OVERVIEW_TEASER {
    &_IMAGE {
        @include media-breakpoint-down(sm) {
            margin-bottom: 24px;
        }
    }

    &_TEXTBOX {
        > a > :first-child {
            margin-top: 0;
            padding-top: 0;
        }

        @include media-breakpoint-up(md) {
            z-index: 1;
            margin-bottom: $ce-overview-teaser_md-textbox-above-image-pixels - 97px;

            > a {
                box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
                margin-top: -#{$ce-overview-teaser_md-textbox-above-image-pixels};
                padding: $ce-overview-teaser_md-textbox-padding;
                background-color: $white;

                &:hover,
                &:active,
                &:focus {
                    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.5);
                }

                > :last-child {
                    padding-bottom: 0;
                    margin-bottom: 0;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }

    @include media-breakpoint-down(sm) {
        // double the size of the default ".CE" class
        margin-bottom: 64px;
    }
    @include media-breakpoint-up(lg) {
        // double the size of the default ".CE" class
        margin-bottom: 64px;
    }
}
