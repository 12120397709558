@use 'sass:math';

.page-navigation {
    margin-top: $grid-gutter-width * 0.5;
}

.teaser-text {
    margin-bottom: $grid-gutter-width;
}

.news {
    &-list {
        @extend .row;
        margin-top: -$grid-gutter-width * 0.5;
        margin-bottom: -$grid-gutter-width * 0.5;

        &-item {
            &__wrap {
                @extend .d-flex;
                margin-top: $grid-gutter-width * 0.5;
                margin-bottom: $grid-gutter-width * 0.5;

                a {
                    @include motion-safe() {
                        transition: all 0.2s ease-in-out;
                    }

                    &:hover {
                        color: inherit;
                        text-decoration: none;
                    }
                }

                // first news entry
                &.first {
                    @extend .col-12, .col-sm-6, .col-lg-12;

                    .rootpage & {
                        @extend .col-sm-12;
                    }
                }

                // wide news left
                @include every(2) {
                    @extend .col-12, .col-sm-6, .col-lg-7;

                    // 16:9 image
                    figure {
                        display: block;

                        // 5:4 image
                        & + figure {
                            display: none;
                        }
                    }

                    // narrow news right
                    & + .news-list-item__wrap {
                        @extend .col-12, .col-sm-6, .col-lg-5;

                        // 16:9
                        figure {
                            @include media-breakpoint-up(lg) {
                                display: none;
                            }

                            // 5:4
                            & + figure {
                                @include media-breakpoint-up(lg) {
                                    display: block;
                                }
                            }
                        }
                    }
                }

                // narrow news left
                @include every(4) {
                    @include make-col(12);

                    @include media-breakpoint-up(sm) {
                        @include make-col(6);
                    }

                    @include media-breakpoint-up(lg) {
                        @include make-col(5);
                    }

                    // 16:9 image
                    figure {
                        @include media-breakpoint-up(lg) {
                            display: none;
                        }

                        // 5:4 image
                        & + figure {
                            @include media-breakpoint-up(lg) {
                                display: block !important;
                            }
                        }
                    }

                    // wide news right
                    & + .news-list-item__wrap {
                        @include make-col(12);

                        @include media-breakpoint-up(sm) {
                            @include make-col(6);
                        }

                        @include media-breakpoint-up(lg) {
                            @include make-col(7);
                        }

                        // 16:9 image
                        figure {
                            display: block;
                            // 5:4 image
                            & + figure {
                                display: none;
                            }
                        }
                    }
                }

                &-item {
                    .first & {
                        @extend .row;

                        &__image {
                            @extend .col-12, .col-lg-8;

                            .rootpage & {
                                @extend .col-md-8;
                            }
                        }

                        &__side {
                            @extend .col-12, .col-lg-4;

                            .rootpage & {
                                @extend .col-md-4;
                            }

                            h2 {
                                margin-bottom: $grid-gutter-width;
                            }

                            & > div {
                                padding-left: $grid-gutter-width;
                                padding-right: $grid-gutter-width;

                                @include media-breakpoint-up(lg) {
                                    padding-left: 0;
                                    padding-right: $grid-gutter-width;
                                }

                                .rootpage & {
                                    padding-left: $grid-gutter-width * 0.5;

                                    @include media-breakpoint-up(md) {
                                        padding-left: 0;
                                    }
                                }
                            }
                        }
                    }

                    &__side {
                        padding-left: $grid-gutter-width;
                        padding-right: $grid-gutter-width;

                        .rootpage & {
                            padding-left: $grid-gutter-width * 0.5;
                        }

                        &-category {
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 1;
                        }
                    }

                    &__image {
                        figure {
                            background: __get($colors_legacy, 'hellgrau.base');

                            display: block;

                            & + figure {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

.CE_NEWS_STARTPAGE {
    .CE_HEADER {
        .tmpl-Homepage &.CE,
        & {
            @include media-breakpoint-up(lg) {
                margin-bottom: $grid-gutter-width * 2;
            }
        }
    }

    ul.news-nav {
        @include media-breakpoint-up(lg) {
            position: absolute;
            top: $grid-gutter-width * 0.5;
            right: 0;
        }
    }

    .news__items {
        @include make-row();
        flex-wrap: nowrap;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;

        @include media-breakpoint-up(lg) {
            scroll-snap-type: none;
            flex-wrap: wrap;
            overflow-x: auto;
        }

        @include print() {
            flex-direction: column;
        }

        &-chunk {
            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: auto;
            display: flex;
            flex-wrap: nowrap;

            @include media-breakpoint-up(lg) {
                display: block;
                flex-wrap: initial;
                padding-left: $grid-gutter-width * 0.5;
                padding-right: $grid-gutter-width * 0.5;
                width: 100%;
            }

            &-1 {
                @include media-breakpoint-up(lg) {
                    flex-basis: percentage(math.div(5, 12));
                    max-width: percentage(math.div(5, 12));
                }
            }

            &-2 {
                @include media-breakpoint-up(lg) {
                    flex-basis: percentage(math.div(3, 12));
                    max-width: percentage(math.div(3, 12));
                    padding-top: $grid-gutter-width * 1.5;
                }
            }

            &-3 {
                @include media-breakpoint-up(lg) {
                    flex-basis: percentage(math.div(4, 12));
                    max-width: percentage(math.div(4, 12));
                    padding-top: $grid-gutter-width * 1.5;
                }
            }

            @include print() {
                flex-direction: column;
            }
        }

        &-item {
            a {
                color: currentColor;
                display: block;

                &:hover {
                    text-decoration: none;
                    color: currentColor;
                }

                &:focus-visible {
                    outline: -webkit-focus-ring-color auto 1px;
                }

                @include print() {
                    text-decoration: none;
                }
            }

            h3 {
                font-size: 18px;
                line-height: 1.2;

                @include media-breakpoint-up(lg) {
                    font-size: 25px;
                }
            }

            h3 + time {
                margin-top: $grid-gutter-width * 0.25;
            }

            h4 {
                font-size: 16px;
                font-weight: normal;
            }

            time {
                font-size: 16px;
            }
        }

        &-item-news {
            padding-left: $grid-gutter-width * 0.5;
            padding-right: $grid-gutter-width * 0.5;
            width: calc(100vw - #{$grid-gutter-width * 2});
            scroll-snap-align: start;

            @include media-breakpoint-up(md) {
                width: calc(50vw - #{$grid-gutter-width});
            }

            @include media-breakpoint-up(lg) {
                width: unset;
                max-width: none;
                min-width: auto;
                padding-left: 0;
                padding-right: 0;
                scroll-snap-align: none;
            }

            @include print() {
                width: 100%;
                padding-left: 0;
                padding-right: 0;
                margin-bottom: 0 !important;

                .aspect-ratio-xs {
                    &::before {
                        padding: 0;
                    }

                    &-item {
                        position: static;
                        left: unset;
                        right: unset;
                        top: unset;
                        bottom: unset;
                        height: auto;
                    }
                }
            }
        }
    }

    &.EVENTS_STARTPAGE {
        time {
            display: block;
            margin-top: 8px;
        }
    }

    .news__items-item {
        h3 {
            font-size: 22px;
            margin-top: $grid-gutter-width * 0.5;

            // fixing long headings
            text-overflow: ellipsis;
            overflow: hidden;

            @include media-breakpoint-up(md) {
                font-size: 26px;
            }

            @include media-breakpoint-up(xxl) {
                font-size: 38px;
                margin-bottom: $grid-gutter-width;
            }
        }

        h4 {
            line-height: 1;
            margin-bottom: 0;
        }

        time {
            font-size: 14px;
            margin-top: $grid-gutter-width * 0.5;

            @include media-breakpoint-up(xxl) {
                font-size: 16px;
            }
        }
    }
}
