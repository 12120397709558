.cc-theme-edgeless {
    &.cc-window {
        z-index: 10000;
        @extend .shadow;
        padding: $grid-gutter-width;
        font-family: $font-family-base;
        flex-direction: column;
        @include media-breakpoint-up(md) {
            flex-direction: row;
            &.cc-bottom {
                margin: 32px;
                width: auto;
            }
        }

        .cc-message {
            margin-right: $grid-gutter-width;
            margin-bottom: $grid-gutter-width * 0.5;
            font-size: __get($font-size, 'xs' 'small');
            line-height: __get($line-height, 'xs' 'small');

            @include media-breakpoint-up(md) {
                @include px-to-rem(font-size, __get($font-size, 'md' 'small'));
                @include px-to-rem(line-height, __get($line-height, 'md' 'small'));
                margin-bottom: 0;
            }

            a:link,
            a:visited {
                text-decoration: none;
                opacity: 1;
                font-size: __get($font-size, 'xs' 'small');
                line-height: __get($line-height, 'xs' 'small');

                @include media-breakpoint-up(md) {
                    @include px-to-rem(font-size, __get($font-size, 'md' 'small'));
                    @include px-to-rem(line-height, __get($line-height, 'md' 'small'));
                }
            }

            a:hover,
            a:active,
            a:focus {
                color: __get($colors_legacy, 'secondary' 'base');
                text-decoration: underline;
            }
        }
    }

    .cc-link {
        padding: 0;
    }
}

.cc-banner {
    &.cc-theme-edgeless {
        .cc-btn {
            color: __get($colors_legacy, 'weiss' 'base');
            text-align: center;
            padding: 0 $grid-gutter-width * 0.5;
            line-height: 48px;
            margin-bottom: 18px;
            height: 48px;
            border-radius: 3px;
            @extend .shadow_button;
            border: none;
            position: relative;
            @extend .text-bold;

            &:hover {
                @extend .shadow_button_hover;
                position: relative;
                top: -1px;
                background-color: __get($colors_legacy, 'secondary' 'base');
                text-decoration: none;
            }
        }
    }
}
