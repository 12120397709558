*,
html {
    font-weight: 300;
    @include font-smoothing;
    font-size: $base-font-size_xs_sm;

    @include media-breakpoint-up(md) {
        font-size: $base-font-size;
    }
}

body {
    overflow-x: hidden;

    &.noscroll {
        overflow: hidden;
    }

    &:not(.rootpage).stickybit-fixed {
        padding-top: $headerFullHeight;
    }

    &.develop:after {
        width: 20px;
    }
}

.color {
    @include utility($colors_legacy, 'color');
}

.bgColor {
    @include utility($colors_legacy, 'background-color');

    &-none {
        background: none;
    }
}

.borderColor {
    @include utility($colors_legacy, 'border-color');
}

.fontsize {
    @include utility-breakpoints($font-size, 'font-size');
}

.lineheight {
    @include utility-breakpoints($line-height, 'line-height');
}

b,
strong {
    font-weight: 400;
    color: __get($colors_legacy, 'schwarz' 'base');
}

.uppercase {
    text-transform: uppercase;
}

.letterspacing-2 {
    letter-spacing: 2px;
}

/*
.lead p {
	font-size: inherit;
	font-weight: inherit;
}
*/
