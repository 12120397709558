.lead {
    p,
    li,
    .teaser-text &,
    p *,
    li *,
    .teaser-text & * {
        font-size: __get($font-size, 'xs' 'lead');
        line-height: __get($line-height, 'xs' 'lead');

        @include media-breakpoint-up(md) {
            font-size: __get($font-size, 'md' 'lead');
            line-height: __get($line-height, 'md' 'lead');
        }
    }
}
