.pagination {
    margin-left: -$grid-gutter-width * 0.25;
    margin-right: -$grid-gutter-width * 0.25;

    .page-item {
        padding: 0 $grid-gutter-width * 0.25;
        margin: 5px 0;

        @include media-breakpoint-down(sm) {
            @include all-but-first-last(4) {
                display: none;
            }
        }

        .page-link {
            @extend .btn, .btn-primary, .outline;

            &:active,
            &:focus {
                color: __get($theme-colors, 'primary') !important;
            }
        }

        &.prev,
        &.next {
            flex-basis: 100%;

            @include media-breakpoint-up(md) {
                flex-basis: auto;
            }

            .page-link {
                @extend .btn, .btn-link;
                border: none !important;

                &:hover,
                &:active,
                &:focus {
                    color: __get($theme-colors, 'primary-highlight') !important;
                }
            }
        }

        &.active {
            .page-link {
                background: __get($theme-colors, 'primary') !important;
                color: __get($colors_legacy, 'weiss' 'base');

                &:hover {
                    background: __get($theme-colors, 'primary-highlight') !important;
                    color: __get($colors_legacy, 'weiss' 'base');
                }

                &:active,
                &:focus {
                    color: __get($colors_legacy, 'weiss' 'base') !important;
                }
            }
        }

        &.hellip {
            .page-link {
                border: none !important;
                cursor: default !important;
                box-shadow: none !important;
                top: 0 !important;
                color: __get($theme-colors, 'primary') !important;
            }
        }
    }
}
