.third-level-navigation {
    display: block;

    &--main {
        position: relative;
        overflow: hidden;

        @include motion-safe() {
            transition: height 0.3s ease;
        }
    }

    &--aside {
        margin-top: $grid-gutter-width * 0.5;
        margin-bottom: $grid-gutter-width * 0.5;

        @include media-breakpoint-up(lg) {
            margin-top: $grid-gutter-width * 2.25;
            margin-bottom: $grid-gutter-width * 2.25;
        }
    }

    &__aside {
        border-top: 1px solid __get($colors_legacy, 'schwarz.base');
        flex: 0 0 calc(100% - #{$grid-gutter-width});

        @include media-breakpoint-up(lg) {
            border-top: none;
            border-left: 1px solid __get($colors_legacy, 'schwarz.base');
            flex: 0 0 100%;
        }

        h4 {
            margin-top: 0;
        }
    }

    &__nav {
        margin: 0;
        padding: 0;
        list-style: none;

        &-item {
            &--active {
                color: __get($theme-colors, primary);
            }
        }
    }

    &__link {
        width: 100%;

        &,
        &:focus,
        &:hover {
            color: currentColor;
        }

        &:focus-visible {
            outline-offset: -2px;
        }

        &,
        * {
            font-size: 1.6rem;
            line-height: 1.3;
        }
    }

    &__item {
        margin-bottom: $grid-gutter-width;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media-breakpoint-up(lg) {
            position: relative;
            justify-content: flex-start;
            width: 50%;
        }
    }

    &--aside &__item {
        margin-bottom: $grid-gutter-width * 0.5;
        width: 100% !important;

        @include media-breakpoint-up(ultrawide) {
            margin-bottom: $grid-gutter-width;
        }
    }

    &__button {
        color: inherit;
        flex-shrink: 0;
        border: 0;
        padding: 0;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        width: $grid-gutter-width * 2;
        cursor: pointer;

        svg {
            pointer-events: none;
        }

        &:focus-visible {
            outline-offset: -2px;
        }
    }

    &__subnav {
        display: none;
        margin: 0;
        padding: 0;
        list-style: none;
        position: absolute;
        left: 0;
        top: 0;
        background: __get($colors_legacy, 'hellblau.base');
        right: 0;

        @include motion-safe() {
            transform: translateX(100%);
            transition: transform 0.3s ease;
        }

        @include media-breakpoint-up(lg) {
            left: 50%;
            padding-left: $grid-gutter-width;
            transform: none;

            @include motion-safe() {
                opacity: 0;
                transition: opacity 0.2s ease;
            }
        }

        &--active {
            display: block;
            transform: none;
            opacity: 1;
        }

        li:last-of-type {
            .third-level-navigation__subnav-link {
                &:last-of-type {
                    margin-bottom: $grid-gutter-width;
                }
            }
        }
    }

    &__subnav-link {
        display: block;
        margin-bottom: $grid-gutter-width * 0.5;
        color: __get($colors_legacy, 'schwarz.base');

        &:hover,
        &:focus {
            color: $primary;
        }

        &:focus-visible {
            outline-offset: -2px;
        }

        @include media-breakpoint-down(md) {
            font-size: 1.6rem;
            line-height: 1.3;
        }
    }

    &__title {
        display: block;
        margin-bottom: $grid-gutter-width * 1.5;
        line-height: 1.08;
        font-size: 2.15rem;
        color: __get($colors_legacy, 'schwarz.base');

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
}
