.CE_IMAGES {
    .CE_HEADER + &__items {
        margin-top: $grid-gutter-width * 1.5;
    }

    &__item {
        padding-left: $grid-gutter-width * 0.5;
        padding-right: $grid-gutter-width * 0.5;
        width: calc(100vw - #{$grid-gutter-width * 2});
        scroll-snap-align: start;
        margin-bottom: $grid-gutter-width * 0.5;

        a {
            display: block;

            img {
                @include motion-safe() {
                    transition: all 0.8s cubic-bezier(0.06, 0.43, 0.22, 1);
                }
            }

            &:hover img {
                transform: scale(1.05) rotate(-0.5deg);
            }
        }

        @include media-breakpoint-up(md) {
            width: calc(50vw - #{$grid-gutter-width});
        }

        @include media-breakpoint-up(lg) {
            scroll-snap-align: none;
            width: 33.33%;
            margin-bottom: $grid-gutter-width;
        }

        @include print() {
            width: 100%;

            figure {
                &::before {
                    display: none;
                }

                .icon {
                    display: none;
                }
            }

            picture {
                position: static;
                top: unset;
                left: unset;
                right: unset;
                bottom: unset;
            }
        }
    }

    &__items {
        @include make-row();
        flex-wrap: nowrap;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;

        @include media-breakpoint-up(lg) {
            scroll-snap-type: none;
            flex-wrap: wrap;
            overflow-x: auto;
        }

        @include print() {
            flex-direction: column;
            flex-wrap: wrap;
        }

        &-chunk {
            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: auto;
            display: flex;
            flex-wrap: nowrap;

            @include media-breakpoint-up(lg) {
                display: flex;
                flex-wrap: nowrap;
                width: 100%;
            }

            & + & {
                .CE_IMAGES__item {
                    margin-bottom: 0;
                }
            }

            @include print() {
                width: 100%;
            }
        }
    }

    &__figure {
        position: relative;
        overflow: hidden;
    }

    &__claim {
        font-size: 18px;
        line-height: 1.25;

        @include media-breakpoint-up(lg) {
            font-size: 26px;
        }
    }

    .CE_HEADER + &__claim {
        margin-top: $grid-gutter-width * 0.5;

        @include media-breakpoint-up(lg) {
            margin-top: $grid-gutter-width * 1.5;
        }
    }

    &__claim + &__items {
        margin-top: $grid-gutter-width;

        @include media-breakpoint-up(lg) {
            margin-top: $grid-gutter-width * 1.5;
        }
    }

    a.picturelink,
    picture {
        .icon {
            pointer-events: none;
        }

        &:hover,
        &:focus {
            .icon {
                background-color: __get($theme-colors, 'primary-highlight');
                opacity: 1;
            }
        }
    }

    .icon {
        background-color: __get($colors_legacy, 'dunkelgrau' 'base');
        opacity: 0.5;
        text-align: center;
        padding: 4px;
        display: inline-block;
        height: 32px;
        width: 32px;
        position: absolute;
        left: $grid-gutter-width * 0.25;
        top: $grid-gutter-width * 0.25;

        &.video,
        &.vr {
            left: calc(50% - #{$ce-gallery-mediaicon-big-width * 0.5 * 0.75});
            top: calc(50% - #{$ce-gallery-mediaicon-big-width * 0.5 * 0.75});
            width: $ce-gallery-mediaicon-big-width * 0.75;
            height: $ce-gallery-mediaicon-big-height * 0.75;
            @include media-breakpoint-up(xl) {
                left: calc(50% - #{$ce-gallery-mediaicon-big-width * 0.5});
                top: calc(50% - #{$ce-gallery-mediaicon-big-width * 0.5});
                width: $ce-gallery-mediaicon-big-width;
                height: $ce-gallery-mediaicon-big-height;
            }
        }
    }
}
