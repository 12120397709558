.grid {
    &-wrap {
        display: -ms-grid;
        display: grid;

        -ms-grid-columns: auto;
        grid-template-columns: auto;

        -ms-grid-rows: auto;
        grid-template-rows: auto;

        grid-gap: 0;
    }

    &-layer {
        &-0,
        &-1,
        &-2 {
            -ms-grid-column: 1;
            grid-column: 1 / 1;

            -ms-grid-row: 1;
            grid-row: 1;
        }

        &-0 {
            z-index: 0;
        }

        &-1 {
            z-index: 1;
        }

        &-2 {
            z-index: 2;
            -ms-grid-row: 2;
            grid-row: 2;

            @include media-breakpoint-up(sm) {
                -ms-grid-row: 1;
                grid-row: 1;
            }
        }
    }
}

_:-ms-fullscreen,
:root .grid-wrap,
,
:root .grid-layer-0,
,
:root .grid-layer-1,
,
:root .grid-layer-2 {
    width: 100vw;
}
