.uc-embed {
    &-list {
        &-headline {
            display: none;
        }

        &-item {
            border: none !important;
            box-shadow: 0 0 10px rgba(#000000, 0.2);
        }
    }

    &-collapsed,
    &-opened {
        &-content {
            display: block !important;
        }

        &-arrow,
        &-opened-arrow {
            display: none !important;
        }
    }
}

.uc-embedding {
    &-container {
        max-height: none !important;

        // google maps
        &[pid='S1pcEj_jZX'] {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            img {
                opacity: 0.5;
            }
        }
    }

    &-accept {
        background: $primary !important;
        color: $light !important;
    }

    &-wrapper {
        span,
        span a {
            font-size: 14px !important;
        }
    }
}
