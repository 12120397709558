.CE_TEXTIMAGE {
    figure {
        margin-bottom: $grid-gutter-width;
        margin-left: auto;
        margin-right: auto;
        display: table;

        figcaption {
            cursor: default;
            @extend .text-bold;
            @include px-to-rem(font-size, __get($font-size, 'xs' 'caption'));
            @include px-to-rem(line-height, __get($line-height, 'xs' 'caption'));
            @include media-breakpoint-up(md) {
                @include px-to-rem(font-size, __get($font-size, 'md' 'caption'));
                @include px-to-rem(line-height, __get($line-height, 'md' 'caption'));
            }
        }

        a.picturelink,
        picture {
            .icon {
                pointer-events: none;
            }

            &:hover,
            &:focus {
                + .icon {
                    background-color: __get($theme-colors, 'primary-highlight');
                    opacity: 1;
                }
            }
        }

        :not(table) {
            .icon {
                background-color: __get($colors_legacy, 'dunkelgrau' 'base');
                opacity: 0.5;
                text-align: center;
                padding: 4px;
                display: inline-block;
                height: 32px;
                width: 32px;
                position: absolute;
                left: $grid-gutter-width * 0.25;
                top: $grid-gutter-width * 0.25;

                &.video,
                &.vr {
                    left: calc(50% - #{$ce-gallery-mediaicon-big-width * 0.5 * 0.75});
                    top: calc(50% - #{$ce-gallery-mediaicon-big-width * 0.5 * 0.75});
                    width: $ce-gallery-mediaicon-big-width * 0.75;
                    height: $ce-gallery-mediaicon-big-height * 0.75;
                    @include media-breakpoint-up(xl) {
                        left: calc(50% - #{$ce-gallery-mediaicon-big-width * 0.5});
                        top: calc(50% - #{$ce-gallery-mediaicon-big-width * 0.5});
                        width: $ce-gallery-mediaicon-big-width;
                        height: $ce-gallery-mediaicon-big-height;
                    }
                }
            }
        }
    }

    .CE_HEADER {
        margin-bottom: 20px;
        @include media-breakpoint-up(md) {
            margin-bottom: 24px;
        }
    }

    + .CE_GALLERY {
        .CE_GALLERY_HEADER {
            margin-top: 0;
        }
    }
}
