:root {
    --theme-gradient-target: #{__get($theme-colors, 'light-grey')};
}

.gradient {
    &-start {
        background-color: var(--theme-gradient-target);
    }

    &-overlay {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &-box {
        &:after {
            background: linear-gradient(
                    0deg,
                    rgba(__get($theme-colors, 'dai-teaser-dark'), 1) 0.5%,
                    rgba(__get($theme-colors, 'dai-teaser-dark'), 0) 7%,
                    rgba(__get($theme-colors, 'dai-teaser-dark'), 0) 92%,
                    rgba(__get($theme-colors, 'dai-teaser-dark'), 1) 99.5%
                ),
                linear-gradient(
                    90deg,
                    rgba(__get($theme-colors, 'dai-teaser-dark'), 1) 0.5%,
                    rgba(__get($theme-colors, 'dai-teaser-dark'), 0) 7%,
                    rgba(__get($theme-colors, 'dai-teaser-dark'), 0) 92%,
                    rgba(__get($theme-colors, 'dai-teaser-dark'), 1) 99.5%
                ),
                linear-gradient(
                    45deg,
                    rgba(__get($theme-colors, 'dai-teaser-dark'), 1) 0.5%,
                    rgba(__get($theme-colors, 'dai-teaser-dark'), 0) 13%,
                    rgba(__get($theme-colors, 'dai-teaser-dark'), 0) 87%,
                    rgba(__get($theme-colors, 'dai-teaser-dark'), 1) 99.5%
                ),
                linear-gradient(
                    135deg,
                    rgba(__get($theme-colors, 'dai-teaser-dark'), 1) 0.5%,
                    rgba(__get($theme-colors, 'dai-teaser-dark'), 0) 13%,
                    rgba(__get($theme-colors, 'dai-teaser-dark'), 0) 87%,
                    rgba(__get($theme-colors, 'dai-teaser-dark'), 1) 99.5%
                );
        }
    }

    &-linear {
        &:after {
            z-index: 1;
            top: auto;

            bottom: $grid-gutter-width * -0.5;
            height: $grid-gutter-width * 0.5;

            @include media-breakpoint-up(lg) {
                bottom: -$grid-gutter-width * 3;
                height: $grid-gutter-width * 4;
            }

            background: linear-gradient(0deg, rgba(#fff, 0) 0%, var(--theme-gradient-target) 50%);
        }
    }
}
