.openNavOverlay {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9;
    background-color: rgba(__get($colors_legacy, 'schwarz' 'base'), 0.5);
    top: 0px;
    left: 0;
    display: none;
}
