.modal {
    &-dialog {
        @include media-breakpoint-between(sm, md) {
            max-width: 95vw;
        }
    }

    .benefit__content {
        header {
            margin-bottom: $grid-gutter-width * 0.75;
        }
        p {
            margin-top: $grid-gutter-width * 0.75;
        }
    }
}
