@import url('https//fast.fonts.net/t/1.css?apiType=css&projectid=48830c8b-1254-4566-bcab-716ffe185be4');

@font-face {
    font-family: 'Univers W01';
    src:
        url('../Fonts/3bdb4597-ff70-447c-8620-8836686bf840.woff2') format('woff2'),
        url('../Fonts/5599ac9f-d136-4277-aeee-f82aac5d527f.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univers W01';
    src:
        url('../Fonts/a503e1fd-a8d1-4caa-89da-d125c0986f23.woff2') format('woff2'),
        url('../Fonts/5f07f397-7e1c-4d2d-957b-a4e960af952b.woff') format('woff');
    font-weight: 700;
    font-style: oblique;
    font-display: swap;
}

@font-face {
    font-family: 'Univers W01';
    src:
        url('../Fonts/d4a01ff3-d1c6-49c0-a4e8-350a6eef9880.woff2') format('woff2'),
        url('../Fonts/609beecf-8d23-4a8c-bbf5-d22ee8db2fc9.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univers W01';
    src:
        url('../Fonts/64adb099-1a1e-444c-b8b5-b0086e717386.woff2') format('woff2'),
        url('../Fonts/d35821cc-c3b0-4c4a-b25c-4d2e6e4ace3f.woff') format('woff');
    font-weight: 300;
    font-style: oblique;
    font-display: swap;
}

@font-face {
    font-family: 'Univers W01';
    src:
        url('../Fonts/87512e82-56b3-4356-97ab-9cd7b2957e37.woff2') format('woff2'),
        url('../Fonts/1eaf27c0-f4de-4273-9f4c-1b3236ad2f3c.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univers W01';
    src:
        url('../Fonts/142786d1-4a72-4273-9130-a80a339bd4e0.woff2') format('woff2'),
        url('../Fonts/34e6ea2e-62b2-49c9-b5e6-128fddfd9f6c.woff') format('woff');
    font-weight: 400;
    font-style: oblique;
    font-display: swap;
}
