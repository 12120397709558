nav {
    &.main {
        right: 0;
        top: 107px;
        height: calc(100% - 112px);
        position: absolute;
        width: 100%;
        transform: translateX(760px);
        display: none;

        @include motion-safe() {
            transition: transform 0.5s ease-in-out;
        }

        @include media-breakpoint-up(md) {
            transform: translateX(610px);
        }

        &.open {
            transform: translateX(0px);
        }

        max-width: 100%;
        background-color: __get($colors_legacy, 'weiss' 'base');
        z-index: 10;
        @extend .shadow_box;
        overflow: hidden;

        @include media-breakpoint-up(md) {
            max-width: 600px;
        }

        header {
            padding-top: $grid-gutter-width;
            padding-left: $grid-gutter-width * 0.5;
            margin-bottom: $grid-gutter-width * 0.5;
            text-transform: uppercase;
            color: __get($colors_legacy, 'hellgrau' 'base');
            @include px-to-rem(font-size, __get($font-size, 'md' 'specialNavButton'));
            @include px-to-rem(line-height, __get($line-height, 'md' 'specialNavButton'));

            @include media-breakpoint-up(md) {
                margin-left: $grid-gutter-width * 0.5;
            }

            font-weight: 700;
        }

        ul {
            padding: 0 $grid-gutter-width $grid-gutter-width $grid-gutter-width;

            @include media-breakpoint-up(md) {
                padding: 0 $grid-gutter-width $grid-gutter-width $grid-gutter-width;
            }

            li {
                border-bottom: 1px solid rgba(__get($colors_legacy, 'hellgrau' 'base'), 0.5);
                padding: 5px 0;
                margin: 0;
                @include media-breakpoint-up(md) {
                    border-bottom: none;
                }

                .withImg {
                    padding: 0;
                }

                a:link,
                a:visited {
                    padding: 4px 0;
                    color: __get($colors_legacy, 'dunkelgrau' 'base');

                    @include px-to-rem(font-size, __get($font-size, 'xs' 'subNavigation'));

                    span {
                        @include px-to-rem(font-size, __get($font-size, 'xs' 'subNavigation'));
                    }

                    @include media-breakpoint-up(md) {
                        @include px-to-rem(font-size, __get($font-size, 'md' 'subNavigation'));
                        span {
                            @include px-to-rem(font-size, __get($font-size, 'md' 'subNavigation'));
                        }
                    }

                    display: block;
                }

                a:hover,
                a:active,
                a:focus {
                    text-decoration: underline;
                    color: __get($colors_legacy, 'secondary' 'base');
                }

                a.active {
                    text-decoration: none;
                    color: __get($colors_legacy, 'secondary' 'base');
                }

                ul {
                    border-top: 1px solid rgba(__get($colors_legacy, 'hellgrau' 'base'), 0.5);
                    padding: 0;
                    margin-top: 4px;

                    @include media-breakpoint-up(md) {
                        border-top: none;
                    }

                    li {
                        &:last-child {
                            border-bottom: none;
                        }

                        a {
                            position: relative;

                            &:before {
                                width: 15px;
                                height: 1px;
                                background-color: __get($colors_legacy, 'hellgrau' 'base');
                                content: '';
                                display: inline-block;
                                margin-right: 10px;
                                vertical-align: middle;
                            }

                            &.textlink-only {
                                &:before {
                                    position: absolute;
                                    left: 0;
                                    top: 13px;
                                    @include media-breakpoint-up(md) {
                                        top: 17px;
                                    }
                                }

                                span {
                                    padding-left: 27px;
                                    display: block;
                                }
                            }

                            img {
                                margin-left: 10px;
                                height: 50px;
                                width: auto;
                                @include media-breakpoint-up(md) {
                                    height: 80px;
                                    width: 216px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // sidebar sub nav
    aside & {
        header {
            text-transform: uppercase;
            color: __get($colors_legacy, 'hellgrau' 'base');
            @include px-to-rem(font-size, 14px);
            @include px-to-rem(line-height, 24px);
            font-weight: 700 !important;
        }

        a {
            &:link,
            &:visited {
                color: __get($colors_legacy, 'dunkelgrau' 'base');
                text-decoration: none;

                @include motion-safe() {
                    transition: color 0.2s ease-in-out;
                }

                @include px-to-rem(font-size, __get($font-size, 'xs' 'subNavigation'));
                @include px-to-rem(line-height, __get($line-height, 'xs' 'subNavigation'));

                @include media-breakpoint-up(md) {
                    @include px-to-rem(font-size, __get($font-size, 'md' 'subNavigation'));
                    @include px-to-rem(line-height, __get($line-height, 'md' 'subNavigation'));
                }

                @include px-to-rem(font-size, 18px);
                display: block;
            }

            &:hover,
            &:active,
            &:focus {
                color: __get($colors_legacy, 'secondary' 'base');
                text-decoration: underline;
            }

            &.active {
                text-decoration: none;
                color: __get($colors_legacy, 'secondary' 'base');
            }

            img {
                display: none;
            }
        }

        ul {
            li {
                margin: 0;

                a {
                    + ul {
                        margin-top: 7px;
                    }
                }
            }

            ul {
                li {
                    a {
                        &:link,
                        &:visited {
                            @include px-to-rem(font-size, __get($font-size, 'xs' 'h6'));
                            @include px-to-rem(line-height, __get($line-height, 'xs' 'subNavigationChild'));
                            margin-bottom: 8px;

                            @include media-breakpoint-up(md) {
                                @include px-to-rem(font-size, __get($font-size, 'md' 'h6'));
                                @include px-to-rem(line-height, __get($line-height, 'md' 'subNavigationChild'));
                            }
                        }

                        position: relative;

                        &:before {
                            width: 15px;
                            height: 1px;
                            background-color: __get($colors_legacy, 'hellgrau' 'base');
                            content: '';
                            display: inline-block;
                            margin-right: 10px;
                            vertical-align: middle;
                            position: absolute;
                            left: 0;
                            top: 13px;
                        }

                        span {
                            padding-left: 27px;
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
