figure {
    flex: 0 1 100%;

    margin: 0;
    position: relative;

    figcaption {
        @include px-to-rem(font-size, __get($font-size, 'xs' 'caption'));
        @include px-to-rem(line-height, __get($line-height, 'xs' 'caption'));
        margin-top: 5px;
    }
}

img {
    &.svg {
        display: none;
    }
}
