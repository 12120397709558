.language-switcher-container {
    .dropdown button.languageSwitcher {
        border-width: 0;
        color: __get($theme-colors, 'primary');
        padding: $grid-gutter-width * 0.25;
        display: flex;
        align-items: center;

        &:after {
            position: relative;
            top: -1px !important;
            border-color: __get($theme-colors, 'primary');
            border-style: solid;
            border-width: 2px 2px 0 0;
            content: '';
            display: inline-block;
            height: 8px;
            width: 8px;
            transform: rotate(135deg);
            vertical-align: middle;
            border-bottom: 0;
            border-left: 0;
            border-right: 2px solid;
        }

        &:focus {
            outline: none;
        }

        span {
            position: relative;

            @include px-to-rem(font-size, __get($font-size, 'xs' 'langSwitcher'));
            @include px-to-rem(line-height, __get($line-height, 'xs' 'langSwitcher'));

            @include media-breakpoint-up(md) {
                @include px-to-rem(font-size, __get($font-size, 'md' 'langSwitcher'));
                @include px-to-rem(line-height, __get($line-height, 'md' 'langSwitcher'));
            }
        }

        svg {
            fill: __get($theme-colors, 'primary');
            margin-right: 8px;
        }
    }

    .language-switcher-inner {
        * {
            line-height: 1;

            &:first-child {
                padding-right: $grid-gutter-width * 0.25;
                border-right: 1px solid black;
            }

            &:last-child {
                padding-left: $grid-gutter-width * 0.25;
            }
        }
    }
}

.dropdown-menu {
    &.language {
        padding: $grid-gutter-width;
        border-radius: 0;
        margin-bottom: 0;
        margin-right: -3px;
        @extend .shadow_box;
        min-width: 240px;

        a.language:link,
        a.language:visited {
            display: block;
            color: __get($colors_legacy, 'schwarz' 'base');
            margin-bottom: $grid-gutter-width * 0.5;

            &:last-child {
                margin-bottom: 0;
            }
        }

        a.language:hover,
        a.language:active,
        a.language:focus {
            color: __get($colors_legacy, 'secondary' 'base');
            text-decoration: underline;
        }

        a.language.active {
            color: __get($colors_legacy, 'secondary' 'base');
            text-decoration: none;

            &:hover,
            &:focus,
            &:active {
                text-decoration: underline;
            }
        }
    }
}

.dropup {
    .languageSwitcher {
    }
}
