$ce-shadowbox_item-header-compensation-top: 9px;
$ce-shadowbox_item-padding-top: $grid-gutter-width - $ce-shadowbox_item-header-compensation-top;
$ce-shadowbox_item-padding-bottom: $grid-gutter-width - 5px;
$ce-shadowbox_item-breakpoint-1: 'md';
$ce-shadowbox_item-margin-bottom-0: $grid-gutter-width;
$ce-shadowbox_item-margin-bottom-1: $grid-gutter-width;

.CE_SHADOWBOX {
    &_ITEM {
        margin-bottom: $ce-shadowbox_item-margin-bottom-0;

        &:last-child {
            margin-bottom: 0;
        }

        @include media-breakpoint-up($ce-shadowbox_item-breakpoint-1) {
            margin-bottom: $ce-shadowbox_item-margin-bottom-1;
            &.col-12.col-md-6:nth-last-child(2) {
                margin-bottom: 0;
            }
        }

        &-WRAP {
            height: 100%;

            @include motion-safe() {
                transition: all 0.2s ease-in-out;
            }
        }

        &-CONTENT {
            padding: $ce-shadowbox_item-padding-top $grid-gutter-width $ce-shadowbox_item-padding-bottom
                $grid-gutter-width !important;

            > img:first-child {
                padding-top: $ce-shadowbox_item-header-compensation-top;
                box-sizing: content-box;
            }

            > :last-child {
                margin-bottom: 0 !important;
                padding-bottom: 0;
            }
        }

        &-ICON {
            height: 48px;
            width: auto;
            @include media-breakpoint-up($ce-shadowbox_item-breakpoint-1) {
                height: 64px;
            }
            // visual margin must be 32px:
            margin-bottom: $grid-gutter-width - 9px;
        }

        &-IMAGE {
        }

        &-SIDEIMAGE {
            background-size: cover;
            background-position: center center;
        }
    }
}
