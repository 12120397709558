.aircraft {
    &-preview {
        background: __get($colors_legacy, 'weiss.base');
        margin-bottom: $grid-gutter-width * 0.5;
        padding: 0 $grid-gutter-width * 0.5;
        overflow: hidden;
        border-bottom: 0 solid transparent;

        @include motion-safe() {
            transition: border-bottom 0.2s ease;
        }

        @media (hover: hover) {
            &.open {
                border-bottom: 4px solid $primary;

                .aircraft-preview__links {
                    visibility: visible;
                    max-height: 46px;

                    @include motion-safe() {
                        transition-delay: 0s;
                    }
                }

                .aircraft-preview__links-toggle svg {
                    transform: rotate(180deg);
                }
            }
        }

        &:hover {
            border-bottom: 4px solid $primary;

            .aircraft-preview__links {
                visibility: visible;
                max-height: 46px;

                @include motion-safe() {
                    transition-delay: 0s;
                }
            }

            .aircraft-preview__links-toggle svg {
                transform: rotate(180deg);
            }
        }

        &__list {
            margin: 0 0 $grid-gutter-width;
            padding: 0;
            list-style: none;
        }

        &__content {
            display: grid;
            align-items: center;
            justify-content: space-between;
            padding: $grid-gutter-width * 0.5 0;
            grid-template-columns: auto auto;
            grid-template-rows: auto auto;

            &,
            &:hover,
            &:focus {
                color: __get($colors_legacy, 'schwarz.base');
                text-decoration: none;
            }
        }

        &__title {
            --column: 1;
            --row: 2;
            font-weight: 400;
            grid-column: var(--column);
            grid-row: var(--row);
        }

        &__category {
            --column: 1;
            --row: 1;

            grid-column: var(--column);
            grid-row: var(--row);

            @include media-breakpoint-up(md) {
                --column: 1/3;
            }

            @include media-breakpoint-up(xxl) {
                --column: 1;
            }
        }

        &__data {
            --column: 2;
            --row: 1/3;

            grid-column: var(--column);
            grid-row: var(--row);

            @include media-breakpoint-up(md) {
                --row: 2;
            }

            @include media-breakpoint-up(xxl) {
                --row: 1/3;
            }
        }

        &__facts {
            display: flex;
            align-items: center;
            margin: 0 $grid-gutter-width * 0.5;
            gap: $grid-gutter-width * 0.5;
        }

        &__fact {
            display: none;
            align-items: center;
            border-right: 1px solid currentColor;
            padding-right: $grid-gutter-width * 0.5;
            gap: $grid-gutter-width * 0.25;

            &:last-child {
                border-right: 0;
                margin-right: 0;
            }

            @include media-breakpoint-up(md) {
                display: flex;
            }

            .CE_PRODUCTS__content & {
                @include media-breakpoint-down(md) {
                    &:nth-child(2) {
                        border-right: none;
                    }

                    @include last(1) {
                        display: none;
                    }
                }
            }
        }

        &__fact-content {
            white-space: nowrap;
        }

        &__fact-value {
            margin-bottom: 0;
            display: flex;
            flex-direction: column;
        }

        &__fact-unit {
            font-size: 0.8em;
        }

        &__image {
            flex-shrink: 1;
            width: 77px;

            @include media-breakpoint-up(md) {
                width: 120px;
            }

            @include media-breakpoint-up(lg) {
                width: 145px;
            }

            height: auto;
            object-position: center center;
            object-fit: contain;
        }

        &__links {
            display: flex;
            margin: 0;
            padding: 0;
            list-style: none;
            visibility: hidden;
            max-height: 0;
            gap: $grid-gutter-width * 0.5;

            @include motion-safe() {
                transition:
                    max-height 0.2s ease,
                    visibility 0s 0.2s;
            }

            &-toggle {
                border: none;
                background: transparent;

                svg {
                    height: 24px;

                    @include media-breakpoint-up(lg) {
                        width: 24px;
                    }
                }
            }
        }

        &__link {
            display: flex;
            align-items: center;
            padding: 0 0 8px;
        }
    }

    &-big {
        margin-bottom: $grid-gutter-width;

        @include media-breakpoint-up(md) {
            margin-bottom: $grid-gutter-width * 1.5;
        }

        &__button.btn.aircraft-big__button {
            background-color: __get($theme-colors, 'dai-light');
            color: __get($theme-colors, 'dai-dark');
            white-space: nowrap;
            margin: 0;
            //padding: 0;
            height: $grid-gutter-width;
            line-height: $grid-gutter-width;
            font-size: 16px;

            &:hover,
            &:focus {
                color: __get($theme-colors, 'dai-light');
            }

            @include media-breakpoint-up(md) {
                height: 48px;
                line-height: 48px;
                font-size: 18px;
            }
        }

        &__heading {
            color: __get($theme-colors, 'dai-light');
            font-size: 26px;

            @include media-breakpoint-up(xl) {
                font-size: 38px;
            }
        }
    }
}
