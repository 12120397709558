table,
.table {
    border-bottom: 1px solid __get($colors_legacy, 'table_border' 'base');

    > thead {
        tr {
            width: 100%;
        }

        td,
        th {
            border-top: none !important;
            border-bottom: none !important;
        }

        th {
            @extend .text-bold;

            p {
                @extend .text-bold;
            }
        }
    }

    > tbody {
        tr {
            width: 100%;
            max-width: 100%;

            &:first-child {
                > th,
                > td {
                    border-top: 2px solid __get($colors_legacy, 'table_border' 'base') !important;
                }
            }

            &:hover {
                background-color: __get($colors_legacy, 'table_hover' 'base');
            }

            th,
            td {
                padding: 10px $grid-gutter-width * 0.5 11px $grid-gutter-width * 0.5;
            }

            th {
                @extend .text-bold;

                p {
                    @extend .text-bold;
                }
            }

            td {
                .icon {
                    background-color: transparent;
                    opacity: 1;
                    text-align: left;
                    padding: 0;
                    display: block;
                    height: auto;
                    width: auto;
                    position: relative;
                    left: 0;
                    top: 0;
                }
            }
        }
    }
}

.jobs-list table {
    --border-color: #{$border-color} !important;

    > tbody tr:first-child > td {
        border-top: 0 !important;
    }

    th,
    td,
    td a {
        @include px-to-rem(font-size, __get($font-size, 'xs' 'text'));

        @include media-breakpoint-up(md) {
            @include px-to-rem(font-size, __get($font-size, 'md' 'table'));
        }
    }
}
