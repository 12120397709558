$ce-gallery-mediaicon-default-width: 32px;
$ce-gallery-mediaicon-default-height: 32px;
$ce-gallery-mediaicon-big-width: 120px;
$ce-gallery-mediaicon-big-height: 120px;

.CE_GALLERY {
    background-image: url('../Images/Diamond-Logografik-Ansicht-dark.png');
    background-size: 873px 834px;
    background-position: -60px -100px;
    background-repeat: no-repeat;
    margin-bottom: $grid-gutter-width;
    padding: 0;

    @include media-breakpoint-up(md) {
        margin-bottom: $grid-gutter-width * 2;
    }

    &.no-background {
        background: none;
    }

    &.bgColor-primary {
        background-color: __get($theme-colors, 'gallery-primary');
        padding: $grid-gutter-width 0;
        @include media-breakpoint-up(md) {
            padding: $grid-gutter-width * 2 0;
        }
    }

    &.bgColor-hellgrau {
        background-color: __get($theme-colors, 'light-grey');
        padding: $grid-gutter-width 0;
        @include media-breakpoint-up(md) {
            padding: $grid-gutter-width * 2 0;
        }
    }

    // Disable entire backgrounds on beLayouts with sidebar
    .tmpl-Default & {
        background: none !important;
    }

    + .CE_GALLERY.mixed {
        margin-top: -$grid-gutter-width * 3;
        padding-top: $grid-gutter-width;
        background-image: none;
    }

    figure {
        margin-bottom: $grid-gutter-width;

        figcaption {
            cursor: default;
            @extend .text-bold;
            @include px-to-rem(font-size, __get($font-size, 'xs' 'caption'));
            @include px-to-rem(line-height, __get($line-height, 'xs' 'caption'));
            @include media-breakpoint-up(md) {
                @include px-to-rem(font-size, __get($font-size, 'md' 'caption'));
                @include px-to-rem(line-height, __get($line-height, 'md' 'caption'));
            }
        }

        a.picturelink,
        picture {
            @include picturelink;
        }

        .icon {
            @include picturelinkIcon;
        }
    }
}

.leftCol {
    .CE_GALLERY {
        .CE_TEXT {
            .row {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
}
