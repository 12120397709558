nav {
    &.footer {
        ul {
            margin: 0 $grid-gutter-width * 0.25;
            flex-wrap: wrap;
            gap: $grid-gutter-width * 0.25;

            li {
                display: flex;
                align-items: center;
                flex: 0 0 auto;

                margin: 0;
                padding: 0 $grid-gutter-width * 0.25;

                @include media-breakpoint-up(md) {
                    padding-top: 0;
                    padding-bottom: 0;
                }

                a:link,
                a:visited {
                    @include px-to-rem(font-size, __get($font-size, 'md' 'footerText'));
                    @include px-to-rem(line-height, __get($line-height, 'md' 'footerText'));

                    @include media-breakpoint-up(md) {
                        @include px-to-rem(font-size, __get($font-size, 'md' 'footerText'));
                        @include px-to-rem(line-height, __get($line-height, 'md' 'footerText'));
                    }
                }

                a:hover,
                a:active,
                a:focus {
                }

                &:last-child {
                    margin-right: 0;
                    @include media-breakpoint-up(lg) {
                        margin-right: $grid-gutter-width * 0.5;
                    }
                }
            }
        }

        .selectStyled {
        }
    }

    &.social {
        width: 290px;

        ul {
            padding-top: 12px;
            position: relative;

            li {
                margin: 0 $grid-gutter-width * 0.125;

                & > a {
                    display: block;
                    width: 40px;
                    height: 40px;
                    text-align: center;
                    line-height: 35px;

                    img,
                    em {
                        opacity: 0.8;

                        @include motion-safe() {
                            transition: all 0.2s ease-in-out;
                        }

                        footer:hover &,
                        footer.visible & {
                            opacity: 1;
                        }
                    }
                }

                [data-notifications] {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 32px;
                    width: 32px;
                    padding-top: 9px;

                    .fancybox-content & {
                        opacity: 0.25;
                    }

                    &:after {
                        position: absolute;
                        width: 24px;
                        height: 24px;
                        top: -24px;
                        left: 50%;
                        margin-left: -12px;
                        border-radius: 100%;
                        background: __get($theme-colors, 'danger');
                        box-shadow: 0 0 2px rgba(__get($colors_legacy, 'weiss.base'), 0.5);
                        content: attr(data-notifications);
                        color: __get($colors_legacy, 'weiss.base');
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        opacity: 0;
                        font-weight: 400;

                        @include motion-safe() {
                            transition: opacity 0.2s ease-in-out;
                        }

                        .fancybox-content & {
                            display: none;
                        }
                    }

                    &.show-notes:after {
                        opacity: 1;
                    }
                }

                [data-notifications='0']:after {
                    display: none;
                }
            }

            &:after {
                @include media-breakpoint-up(md) {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 16px;
                    right: 16px;
                    height: 20px;

                    @include motion-safe() {
                        transition: all 0.2s ease-in-out;
                    }

                    background: linear-gradient(
                        to bottom,
                        rgba(__get($colors_legacy, 'dunkelgrau.base'), 0) 0%,
                        rgba(__get($colors_legacy, 'dunkelgrau.base'), 1) 100%
                    );
                    pointer-events: none;

                    footer:hover &,
                    footer.visible & {
                        bottom: -20px;
                    }
                }
            }
        }
    }
}
