.CE_PAGETEASER {
    .CE_HEADER {
        margin-bottom: $grid-gutter-width * 1.375;
    }

    .teaser-row {
        row-gap: $grid-gutter-width;

        .card {
            @include motion-safe() {
                transition: all 0.2s ease-in-out;
            }

            figure {
                flex: 0 0;
                picture {
                    overflow: hidden;
                }
            }

            &-body {
                row-gap: $grid-gutter-width * 0.5;

                .content {
                    row-gap: $grid-gutter-width * 0.5;

                    a.btn {
                        height: auto;
                        line-height: 1.5;
                        padding: $grid-gutter-width * 0.25 $grid-gutter-width * 0.5;
                        position: unset;
                        margin-top: $grid-gutter-width * 0.5;
                        margin-bottom: 0;

                        &:hover {
                            transform: unset;
                            margin-top: 14px;
                            margin-bottom: 2px;
                        }
                    }
                }
            }
        }
    }
}
