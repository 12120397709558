.icon {
    display: inline-flex;

    &-arrow {
        @include getIcon('arrow', __get($theme-colors, 'primary'), 9px);
        display: inline;

        &:after {
            display: inline-block !important;
        }

        //.blocklevel-link & {
        //  display: inline-flex;
        //}

        &:hover,
        &:focus,
        &:active,
        a:hover,
        a.hover & {
            @include getIcon('arrow', __get($theme-colors, 'primary-highlight'), 9px);
            display: inline;
        }
    }

    @include media-breakpoint-up(md) {
        &-arrow {
            @include getIcon('arrow', __get($theme-colors, 'primary'), 11px);
            display: inline;

            &:hover,
            &:focus,
            &:active,
            a:hover,
            a.hover & {
                @include getIcon('arrow', __get($theme-colors, 'primary-highlight'), 11px);
                display: inline;
            }
        }
    }

    &-logo {
        @include getIcon('logo', __get($colors_legacy, 'secondary' 'base'), 50px, 'before');
    }

    &-calendar {
        @include getIcon('calendar', __get($colors_legacy, 'weiss' 'base'), 20px, 'before');

        &:before {
            margin-right: 5px;
        }
    }

    &.small {
        @include px-to-rem(font-size, 16px);
    }

    &-notifications {
        $notificationsIconSize: 6px;

        display: inline-block;
        width: $notificationsIconSize;
        height: $notificationsIconSize;
        border-radius: 100%;
        background: __get($colors_legacy, 'hellgrau.base');
        position: relative;

        &:before,
        &:after {
            content: '';
            display: inline-block;
            width: $notificationsIconSize;
            height: $notificationsIconSize;
            border-radius: 100%;
            background: __get($colors_legacy, 'hellgrau.base');
            position: absolute;
        }

        &:before {
            left: -$notificationsIconSize * 2;
        }

        &:after {
            right: -$notificationsIconSize * 2;
        }
    }

    &-close {
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background: __get($colors_legacy, 'promo-cockpit.close');

        &:before,
        &:after {
            content: '';
            display: block;
            height: 3px;
            width: 26px;
            background: __get($colors_legacy, 'weiss.base');
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -13px;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }

    &:hover {
    }
}
