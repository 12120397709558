.CE_FAQ {
    p,
    button span {
        @include px-to-rem(font-size, 18px);

        @include media-breakpoint-up(md) {
            @include px-to-rem(font-size, 22px);
        }
    }
}
