ul,
ol {
    margin: 0 0 12px 0;
    padding: 0 0 0 24px;

    @include media-breakpoint-up(md) {
        margin-bottom: $grid-gutter-width * 0.5;
    }

    &.list-unstyled {
        li {
            margin-left: 0;

            &:before {
                display: none;
            }
        }
    }

    li {
        margin: 0 0 4px 0;
        padding: 0;
        font-size: __get($font-size, 'xs' 'text');
        line-height: __get($line-height, 'xs' 'text');

        @include media-breakpoint-up(md) {
            @include px-to-rem(font-size, __get($font-size, 'md' 'text'));
            @include px-to-rem(line-height, __get($line-height, 'md' 'text'));
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

ul {
    &.nice-list {
        list-style: none;
        padding: 0;

        li {
            position: relative;
            padding-left: 24px;

            &:before {
                content: '»';
                position: absolute;
                left: 4px;
            }
        }

        ul {
            list-style: none;
            padding: 0;

            li {
                padding-left: 16px;

                &:before {
                    content: '›';
                    left: 2px;
                }
            }
        }
    }
}
