header#header {
    --highlight-color: #{__get($theme-colors, 'primary-highlight')};
    position: sticky;
    z-index: 102;
    background: __get($colors_legacy, 'weiss.base');
    color: __get($theme-colors, 'dai-dark');

    @include motion-safe() {
        transition: all 0.2s ease;
    }

    @each $breakpoint, $height in __get($heights, 'navigation') {
        @include media-breakpoint-up($breakpoint) {
            top: -$height;
            height: $height;
        }
    }

    &.home:not(.scrolled):not(.active) {
        --logo-color-sign: #{__get($theme-colors, 'dai-light')};
        --logo-color-word: #{__get($theme-colors, 'dai-light')};
        --highlight-color: #{__get($theme-colors, 'dai-light')};

        color: __get($theme-colors, 'dai-light');
        background: transparent;

        .header__main {
            border-bottom-color: var(--dai-air);
        }
    }

    &.visible,
    &.active {
        top: 0 !important;
    }

    &.with-sticky:not(.home) {
        margin-bottom: 64px;

        @include media-breakpoint-up(md) {
            margin-bottom: 80px;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 64px;
        }
    }

    @include print() {
        position: static;
        --logo-color-sign: #fff;
        --logo-color-word: #fff;
        color: #fff;
    }

    .header {
        &__wrapper {
            @include make-container();
            max-width: __get($container-max-widths, 'ultrawide');
        }

        &__main {
            display: flex;
            align-items: stretch;
            border-bottom: 1px solid __get($colors_legacy, 'schwarz.base');

            @each $breakpoint, $height in __get($heights, 'navigation') {
                @include media-breakpoint-up($breakpoint) {
                    height: $height;
                }
            }

            @include motion-safe() {
                transition: border-bottom 0.2s ease;
            }
        }

        &__logo {
            z-index: 30;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__collapsable {
            display: none;

            @include media-breakpoint-down(sm) {
                padding-top: __get($heights, 'navigation.xs');
            }

            @include media-breakpoint-down(lg) {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 100vh;
                background: __get($colors_legacy, 'hellblau.base');
                z-index: 40;
                padding-top: __get($heights, 'navigation.md');
                color: __get($colors_legacy, 'schwarz.base');

                @include motion-safe() {
                    transform: translateX(100%);
                    transition: transform 0.3s ease 0.3s;

                    & > * {
                        opacity: 0;
                        transition: opacity 0.3s ease;
                    }

                    &--active {
                        transform: none;
                        transition-delay: 0s;

                        & > * {
                            opacity: 1;
                            transition-delay: 0.3s;
                        }
                    }
                }
            }
        }

        &__burger {
            display: block;
            background: none;
            border: none;
            color: inherit;
            padding: 0;
            position: relative;
            z-index: 50;
            width: 48px;
            height: 48px;
            margin-right: -8px;
            align-self: center;

            &::before,
            &::after {
                width: $grid-gutter-width;
                height: 2px;
                background: currentColor;
                display: block;
                content: '';
                position: absolute;
                transform: translate(-50%, -50%);
                transform-origin: center center;
                left: 50%;
                top: 50%;

                @include motion-safe() {
                    transition:
                        transform 0.3s ease,
                        margin-top 0.3s ease 0.3s;
                }
            }

            &::before {
                margin-top: -4px;
            }

            &::after {
                margin-top: 4px;
            }

            &--active {
                &::before,
                &::after {
                    margin-top: 0;

                    @include motion-safe() {
                        transition:
                            transform 0.3s ease 0.3s,
                            margin-top 0.3s ease;
                    }
                }

                &::before {
                    transform: translate(-50%, -50%) rotateZ(45deg);
                }

                &::after {
                    transform: translate(-50%, -50%) rotateZ(-45deg);
                }
            }

            @include print() {
                display: none !important;
            }
        }

        &__main-nav {
            @include media-breakpoint-down(lg) {
                width: 100%;
                height: 100%;
            }
        }

        &__subnav {
            background: __get($colors_legacy, 'weiss.base');
            position: relative;
            max-width: none;
            padding: 0;

            &::after {
                display: block;
                position: absolute;
                content: '';
                top: 100%;
                left: 0;
                right: 0;
                height: 10px;
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0.125) 0%, rgba(0, 0, 0, 0) 100%);
            }
        }
    }

    &.home .header__subnav {
        display: none;
    }
}
