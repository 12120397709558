footer {
    background-color: __get($colors_legacy, 'dunkelgrau' 'base');
    margin-top: 168px;
    box-shadow: 0 0 50px rgba(__get($colors_legacy, 'weiss.base'), 0.8);
    z-index: 101;

    position: sticky;

    @each $breakpoint, $value in $promo-cockpit {
        @if $breakpoint == 'xs' {
            bottom: $value;

            &:hover {
                bottom: $value + $promo-cockpit-hover;
            }
        } @else {
            @include media-breakpoint-up($breakpoint) {
                bottom: $value;

                &:hover {
                    bottom: $value + $promo-cockpit-hover;
                }
            }
        }
    }

    @include print() {
        position: static;
    }

    .fancybox-content & {
        position: relative;
        bottom: auto;

        .row:last-child {
            display: none;
        }

        @include media-breakpoint-up(lg) {
            position: fixed;
            left: 0;
            width: 100%;
            bottom: 0;
        }
    }
    @include motion-safe() {
        transition: all 0.2s ease-in-out;
    }

    @include media-breakpoint-up(lg) {
        margin-top: 192px;
    }

    .FOOTER_BOX {
        border: 2px solid lighten(__get($colors_legacy, 'mittelgrau' 'base'), 20%);
        background-color: rgba(0, 0, 0, 0.15);
        border-radius: 3px;
        width: 100%;
        margin-bottom: $grid-gutter-width * 0.5;

        &:hover,
        &:active,
        &:focus {
            background-color: rgba(0, 0, 0, 0.1);
            border: 2px solid lighten(__get($colors_legacy, 'mittelgrau' 'base'), 30%);
        }

        @include media-breakpoint-up(lg) {
            width: 25%;
            margin-right: 25px;
            flex: 1 1 auto;
        }

        a:link,
        a:visited {
            padding: $grid-gutter-width * 0.5;

            display: block;
            color: __get($colors_legacy, 'hellgrau' 'base');

            @include media-breakpoint-up(lg) {
                padding: $grid-gutter-width;

                .fancybox-content & {
                    padding-top: $grid-gutter-width * 0.5;
                    padding-bottom: $grid-gutter-width * 0.5;
                }
            }

            svg {
                width: 35px;
                height: 35px;
                margin-right: $grid-gutter-width * 0.5;
                fill: __get($colors_legacy, 'hellgrau' 'base');

                @include media-breakpoint-up(lg) {
                    width: 58px;
                    height: 58px;
                    margin-right: 0;
                }
            }

            p {
                width: 100%;
                @include px-to-rem(font-size, __get($font-size, 'xs' 'footerBox'));
                @include px-to-rem(line-height, __get($line-height, 'xs' 'footerBox'));

                @include media-breakpoint-up(md) {
                    @include px-to-rem(font-size, __get($font-size, 'md' 'footerBox'));
                    @include px-to-rem(line-height, __get($line-height, 'md' 'footerBox'));
                }

                &:after {
                    border-style: solid;
                    border-width: 2px 2px 0 0;
                    content: '';
                    display: inline-block;
                    height: 10px;
                    width: 10px;
                    transform: rotate(45deg);
                    vertical-align: middle;
                    float: right;
                    margin-top: 6px;

                    @include media-breakpoint-up(lg) {
                        margin-top: 12px;
                    }
                }
            }

            @at-root footer[data-count='5'] .FOOTER_BOX {
                @include media-breakpoint-between(lg, xl) {
                    a,
                    a:hover,
                    a:active,
                    a:focus {
                        padding-right: $grid-gutter-width - 8;

                        p {
                            font-size: 20px;
                            line-height: 23px;

                            &:after {
                                margin-top: 8px;
                            }
                        }
                    }
                }
            }
        }

        a:hover,
        a:active,
        a:focus {
            color: __get($colors_legacy, 'weiss' 'base');

            svg {
                fill: __get($colors_legacy, 'weiss' 'base');
            }

            p {
                color: __get($colors_legacy, 'weiss' 'base');
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }

    p,
    a:link,
    a:visited {
        color: __get($colors_legacy, 'hellgrau' 'base');
        @include px-to-rem(font-size, __get($font-size, 'xs' 'footerText'));
        @include px-to-rem(line-height, __get($line-height, 'xs' 'footerText'));

        @include media-breakpoint-up(md) {
            @include px-to-rem(font-size, __get($font-size, 'md' 'footerText'));
            @include px-to-rem(line-height, __get($line-height, 'md' 'footerText'));
        }
    }

    a:hover,
    a:active,
    a:focus {
        color: __get($colors_legacy, 'weiss' 'base');
    }

    .footer-top {
        margin-top: -40px;
        margin-bottom: $grid-gutter-width;

        .fancybox-content & {
            margin-bottom: $grid-gutter-width * 0.5;
        }

        position: relative;

        .socialBg {
            position: absolute;
            top: 0;
            z-index: 0;

            .fancybox-content & {
                z-index: auto;
            }
        }

        &:after {
            content: '';
            position: absolute;
            top: 0px;
            left: 50%;
            width: 210px;
            height: 30px;
            margin-left: -105px;
            box-shadow: 0 0 20px rgba(__get($colors_legacy, 'weiss.base'), 0.8);

            @include media-breakpoint-up(md) {
                box-shadow: 0 0 20px rgba(__get($colors_legacy, 'weiss.base'), 0.8);
            }

            z-index: -2;
        }
    }

    .footer-left,
    .footer-right {
        background-color: __get($colors_legacy, 'dunkelgrau' 'base');
        width: 21px;
        border-radius: 10px;
        transform: skew(-20deg);
        margin-right: -1px;
    }

    .footer-right {
        transform: skew(20deg);
        margin-right: 0;
        margin-left: -1px;
    }

    .copyright {
        margin-bottom: $grid-gutter-width;
        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }
    }
}
