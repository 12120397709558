.shadow-left-right-bottom {
    -webkit-box-shadow: 0px 10px 17px -6px rgba(50, 50, 50, 0.2);
    -moz-box-shadow: 0px 10px 17px -6px rgba(50, 50, 50, 0.2);
    box-shadow: 0px 10px 17px -6px rgba(50, 50, 50, 0.2);
}

.shadow {
    -webkit-box-shadow: 0px 0px 18px 0px rgba(50, 50, 50, 0.2);
    -moz-box-shadow: 0px 0px 18px 0px rgba(50, 50, 50, 0.2);
    box-shadow: 0px 0px 18px 0px rgba(50, 50, 50, 0.2);
}

.shadow_button {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
}

.shadow_button_hover {
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.5);
}

.shadow_box {
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.5);
}

.flex-grow-1 {
    flex-grow: 1;
}

/*
.wow{
	visibility: hidden;
}
*/
.address_mover {
    display: block;
    float: left;
    margin-right: 20px;
    width: 20px;
}

.font-weight-normal {
    font-weight: 300;
}

.font-weight-bold {
    font-weight: 400 !important;
}

.word-break-all {
    word-break: break-all;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-underline {
    text-decoration: underline;
}

.limited-width {
    @include custom-make-container-max-widths();

    //margin-left:$grid-gutter-width * 0.5;
    //margin-right:$grid-gutter-width * 0.5;

    @include media-breakpoint-up(sm) {
        margin-left: auto;
        margin-right: auto;
    }
}

.no-border {
    border: none !important;
}

.arrow {
    color: __get($colors_legacy, 'schwarz' 'base');
    cursor: pointer;

    &:before {
        border-style: solid;
        border-width: 2px 2px 0 0;
        content: '';
        display: inline-block;
        height: 15px;
        left: 0.15em;
        position: relative;
        top: 0px;
        transform: rotate(135deg);
        vertical-align: middle;
        width: 15px;
    }

    &.up {
        &:before {
            transform: rotate(-45deg);
            top: 5px;
        }
    }
}

.gradientBG {
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        top,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.4) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.4) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#66000000', GradientType=0); /* IE6-9 */
}

// Helper class to inherit styles of parent
.inherit-styles {
    color: inherit !important;
    font-weight: inherit !important;
    font-family: inherit !important;
    font-size: inherit !important;
}

.small {
    p,
    li,
    ol,
    span,
    strong,
    em,
    i,
    a {
        font-size: __get($font-size, 'xs' 'small');
        line-height: __get($line-height, 'xs' 'small');

        @include media-breakpoint-up(md) {
            font-size: __get($font-size, 'md' 'small');
            line-height: __get($line-height, 'md' 'small');
        }
    }
}

.nowrap {
    white-space: nowrap;
}

iframe {
    &#jobFrame {
        margin-left: $grid-gutter-width * -0.5;
    }
}
