@use 'sass:math';

//***************************************************************************
// FUNCTIONS
//***************************************************************************
@function pow($number, $exponent) {
    $value: 1;

    @if $exponent > 0 {
        @for $i from 1 through $exponent {
            $value: $value * $number;
        }
    } @else if $exponent < 0 {
        @for $i from 1 through -$exponent {
            $value: math.div($value, $number);
        }
    }

    @return $value;
}

@function duration($index, $duration: $transitionDuration, $count: $items, $transitionOffset: $transitionDifference) {
    $baseDuration: math.div($duration, $count);
    $additionalDuration: ($index - 0.5 - (math.div($count, 2))) * $transitionOffset;
    $totalDuration: $baseDuration + $additionalDuration;

    @return $totalDuration;
}

//***************************************************************************
// ACTUAL CSS
//***************************************************************************

.#{$grid-class} {
    display: none;
}

.#{$grid-body_active-class} {
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
}

.#{$grid-scroll-class} {
    left: 0;
    top: $headerHeightFirstRow;
    width: 100%;
    height: calc(100% - #{$headerHeightFirstRow});
    z-index: 9999;
    position: fixed;
    overflow: auto;

    .rootpage &,
    .tmpl-Homepage & {
        top: 0;
        height: 100%;
    }

    @include media-breakpoint-up($grid-breakpoint) {
        top: 0;
        height: 100%;
    }
}

.#{$grid-wrapper-class} {
    display: flex;

    // Set properties here, that would be unset on higher breakpoints
    @include media-breakpoint-down($grid-breakpoint) {
        flex-direction: column-reverse;
        justify-content: stretch;
        min-height: 100%;
    }

    @include media-breakpoint-up($grid-breakpoint) {
        $cols: pow(2, ceil(math.div($items, 2)) - 1);
        $rows: pow(2, floor(math.div($items, 2)));

        display: grid;
        top: 0;
        height: 100%;
        grid-template-columns: repeat($cols, math.div(100%, $cols));
        grid-template-rows: repeat($rows, math.div(100%, $rows));
    }
}

.#{$grid-item-class} {
    position: relative;
    display: block;
    overflow: hidden;
    text-align: center;
    color: #fff;

    > a {
        &,
        & * {
            color: __get($colors_legacy, 'weiss' 'base');
        }
    }

    .closing-x {
        display: block;
    }

    img {
        display: none;
    }

    // Closing the grid navigation is not allowed on the homepage
    body.tmpl-Homepage &.hide-grid {
        a {
            cursor: default;

            img {
                display: block;
            }

            .closing-x {
                display: none;
            }
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5 {
        text-transform: uppercase;
    }

    @include media-breakpoint-down($grid-breakpoint) {
        flex-basis: 50px;
        flex-shrink: 0;
        flex-grow: 0;

        &.#{$grid-item_background-class} {
            flex-grow: 1;
            min-height: 160px;
        }
    }

    // Set grid areas here
    @include media-breakpoint-up($grid-breakpoint) {
        &:nth-child(1) {
            grid-area: 1 / 8 / 2 / 9;
        }
        &:nth-child(2) {
            grid-area: 2 / 8 / 3 / 9;
        }
        &:nth-child(3) {
            grid-area: 1 / 7 / 3 / 8;
        }
        &:nth-child(4) {
            grid-area: 3 / 7 / 5 / 9;
        }
        &:nth-child(5) {
            grid-area: 1 / 5 / 5 / 7;
        }
        &:nth-child(6) {
            grid-area: 5 / 5 / 9 / 9;
        }
        &:nth-child(7) {
            grid-area: 1 / 1 / 9 / 5;
        }

        @media (orientation: portrait) {
            &:nth-child(1) {
                grid-area: 1 / 7 / 2 / 9;
            }
            &:nth-child(2) {
                grid-area: 2 / 7 / 3 / 9;
            }
            &:nth-child(3) {
                grid-area: 1 / 5 / 3 / 7;
            }
            &:nth-child(4) {
                grid-area: 3 / 5 / 5 / 9;
            }
            &:nth-child(5) {
                grid-area: 5 / 1 / 9 / 5;
            }
            &:nth-child(6) {
                grid-area: 5 / 5 / 9 / 9;
            }
            &:nth-child(7) {
                grid-area: 1 / 1 / 5 / 5;
            }
        }
    }
}

.#{$grid-container-class} {
    display: none;
    text-decoration: none;
    height: 100%;
    width: 100%;
    color: currentColor;
    box-sizing: border-box;
    position: absolute;
    border-bottom: 2px solid #fff;

    &:hover {
        color: currentColor;
        text-decoration: inherit;
    }

    &.#{$animation-start-prepare-class},
    &.#{$animation-leave-to-class} {
        transform: translate3d(0, -100%, 0);
    }

    .#{$grid-wrapper_reverse-class} &.#{$animation-leave-to-class} {
        transform: translate3d(0, 100%, 0);
    }

    &.#{$animation-start-to-class},
    &.#{$animation-leave-prepare-class} {
        transform: none;
    }

    &.#{$animation-start-active-class} {
        @include motion-safe() {
            transition: transform;
            transition-timing-function: ease-out;
        }
    }

    &.#{$animation-leave-prepare-class} {
        opacity: 1;
    }

    &.#{$animation-leave-to-class} {
        opacity: 0;
    }

    &.#{$animation-leave-active-class} {
        @include motion-safe() {
            transition: transform, opacity;
            transition-timing-function: ease-out, ease-out;
        }
    }

    &.#{$animation-active-class} {
        display: block;
    }

    // Set animation effects
    @include media-breakpoint-up($grid-breakpoint) {
        border: 2px solid #fff;

        .#{$grid-item_side-class} &.#{$animation-start-prepare-class},
        .#{$grid-item_side-class} &.#{$animation-leave-to-class} {
            transform: translate3d(100%, 0, 0);
        }

        .#{$grid-wrapper_reverse-class} .#{$grid-item_side-class} &.#{$animation-leave-to-class} {
            transform: translate3d(-100%, 0, 0);
        }
    }

    // Set transition duration for n-th element
    @for $i from 1 through $items {
        .#{$grid-item-class}:nth-child(#{$i}) & {
            &.#{$animation-leave-active-class},
            &.#{$animation-start-active-class} {
                transition-duration: duration($i);
            }
        }
    }
}

.#{$grid-content-class} {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    z-index: 5;
    position: relative;
    padding: 10px 0;

    > * {
        text-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
    }

    .#{$animation-start-prepare-class} & {
        transform: translate3d(0, 80px, 0);
        opacity: 0;
    }

    .#{$animation-active-class} & {
        @include motion-safe() {
            transition: transform, opacity;
            transition-timing-function: ease, ease;
            transition-delay: 0.2s, 0.2s;
            transition-duration: 0.8s, 0.8s;
        }
    }

    .#{$animation-start-to-class} & {
        opacity: 1;
        transform: none;
    }

    @include media-breakpoint-up($grid-breakpoint) {
        padding: 0 15px;
    }

    h1 {
        font-size: 40px;
        @include media-breakpoint-up(xl) {
            font-size: 64px;
        }
    }

    h3 {
        @include media-breakpoint-down(sm) {
            font-size: 40px;
        }
    }

    span {
        font-size: 24px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        background: transparent;
        color: inherit;
        margin: 0;
        padding: 0;
    }

    h4,
    h5,
    h6 {
        @include media-breakpoint-down($grid-breakpoint) {
            font-size: $h5-font-size;
        }
    }

    hr {
        width: 50px;
        height: 1px;
        border: none;
        background: #fff;
        display: block;
        margin: 10px 0 0;
    }

    @include media-breakpoint-up($grid-breakpoint) {
        &.#{$grid-content_top-class} {
            justify-content: flex-start;
        }

        &.#{$grid-content_bottom-class} {
            justify-content: flex-end;
        }
    }
}

.#{$grid-background-class} {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: __get($colors_legacy, 'schwarz' 'base');

    img {
        opacity: 0.75;
        @include media-breakpoint-up($grid-breakpoint) {
            opacity: 1;
        }
    }

    // Set transition duration for n-th element
    @for $i from 1 through $items {
        .#{$grid-item-class}:nth-child(#{$i}) & {
            transition-duration:
                duration($i) * $transitionBackgroundFactor,
                duration($i) * $transitionBackgroundFactor;
        }
    }

    .#{$animation-start-prepare-class} & {
        transform: translate3d(0, -100%, 0);
        opacity: 0;
    }

    .#{$animation-start-to-class} & {
        transform: none;
        opacity: 1;
    }

    .#{$animation-start-active-class} & {
        @include motion-safe() {
            transition: transform, opacity;
            transition-delay: 0.2s;
        }
    }

    @include media-breakpoint-up($grid-breakpoint) {
        .#{$animation-start-prepare-class} & {
            transform: translate3d(100%, 0, 0);
        }
    }

    img {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        //object-fit: cover;
        //object-position: center center;
        @include object-fit(cover, center center);
    }
}
