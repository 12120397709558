.teasertext {
    margin-top: $grid-gutter-width * 0.5;

    p {
        font-size: 18px;
        line-height: 1.3;

        @include media-breakpoint-up(md) {
            font-size: 23px;
        }

        @include media-breakpoint-up(lg) {
            font-size: 26px;
        }
    }

    a {
        display: inline-block;
        margin-top: $grid-gutter-width * 0.5;

        span {
            font-size: 15px;
            line-height: 1.5;

            @include media-breakpoint-up(lg) {
                font-size: 20px;
            }
        }
    }
}
