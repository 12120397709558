.CE_MODALBOX {
    .modalbox {
        &__wrapper {
            margin-top: $grid-gutter-width * 1.375;
            --grid-columns: 1;
            grid-template-columns: repeat(var(--grid-columns), 1fr);
            display: grid;
            gap: $grid-gutter-width;
            grid-auto-rows: 1fr;
            @include media-breakpoint-up(sm) {
                --grid-columns: 2;
            }
            @include media-breakpoint-up(lg) {
                --grid-columns: 3;
            }
        }

        &__item-btn {
            position: relative;
            padding-right: $grid-gutter-width * 2.25 !important;
            font-size: __get($font-size, 'xs' 'modalBox');
            line-height: __get($line-height, 'xs' 'modalBox');
            min-height: 100px;
            font-weight: 300;

            @include media-breakpoint-up(xl) {
                font-size: __get($font-size, 'md' 'modalBox');
            }

            &:after {
                content: '+';
                position: absolute;
                width: $grid-gutter-width;
                height: $grid-gutter-width;
                margin: $grid-gutter-width * 0.5;
                padding-bottom: 6px;
                background-color: __get($theme-colors, 'dai-blue');
                color: __get($theme-colors, 'dai-light');
                right: 0;
                top: 0;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
