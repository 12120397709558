@use 'sass:math';

.navigation {
    list-style: none;
    margin: 0 $grid-gutter-width * -0.25;
    padding: 0;

    @include media-breakpoint-up(xxl) {
        margin: 0 $grid-gutter-width * -0.5;
    }

    &__main {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 0 $grid-gutter-width * 0.5 __get($heights, 'navigation.xs');
        margin-top: 56px;

        @include media-breakpoint-up(md) {
            padding-bottom: __get($heights, 'navigation.md');
        }

        @include media-breakpoint-up(xl) {
            flex-direction: row;
            padding: 0;
            z-index: 30;
            justify-content: center;
            margin-top: 0;
        }

        @each $breakpoint, $height in __get($heights, 'navigation') {
            @include media-breakpoint-up($breakpoint) {
                height: $height;
            }
        }

        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }

    &__meta &__link {
        &:hover {
            color: var(--highlight-color);
            text-decoration: underline;
            text-underline-offset: 2px;
            text-decoration-thickness: 1px;
        }

        &:focus-visible {
            border-radius: 2px;
            outline: 2px solid var(--highlight-color);
            outline-offset: -2px;
        }

        &-text {
            font-size: 0.8em !important;

            @include motion-safe() {
                transition: color 0.2s ease;
            }
        }
    }

    &__item {
        margin: 0;
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        padding: $grid-gutter-width * 0.5 $grid-gutter-width * 0.25;
        margin: 0;
        border: none;
        background: transparent;
        width: 100%;
        position: relative;

        @include motion-safe() {
            transition: color 0.2s ease;
        }

        @include media-breakpoint-up(xl) {
            justify-content: center;
            width: auto;
            padding: 0 $grid-gutter-width * 0.25;
        }

        @include media-breakpoint-up(xxl) {
            padding: 0 $grid-gutter-width * 0.5;
        }

        &,
        &:hover,
        &:focus {
            color: currentColor;
            text-decoration: none;
        }

        &[aria-expanded='true'] {
            color: $primary;
        }
    }

    &__main &__link {
        &[aria-expanded='true'],
        #header:not(.active) &.active,
        &:hover {
            color: var(--highlight-color);

            @include media-breakpoint-up(xl) {
                &::after {
                    opacity: 1;
                }
            }
        }

        &:focus-visible {
            border-radius: 2px;
            outline: 2px solid var(--highlight-color);
            outline-offset: -2px;
            color: var(--highlight-color);
        }

        &::after {
            position: absolute;
            left: $grid-gutter-width * 0.25;
            right: $grid-gutter-width * 0.25;
            //bottom: calc(50% - #{$grid-gutter-width / 1.5});
            bottom: calc(50% - #{math.div($grid-gutter-width, 1.5)});
            height: 2px;
            background: currentColor;
            display: none;
            content: '';
            opacity: 0;

            @include motion-safe() {
                transition: opacity 0.2s ease;
            }

            @include media-breakpoint-up(lg) {
                display: block;
            }

            @include media-breakpoint-up(xl) {
                left: $grid-gutter-width * 0.5;
                right: $grid-gutter-width * 0.5;
            }
        }
    }

    &__link-text {
        white-space: nowrap;
        color: currentColor;
        font-weight: 400;

        @include media-breakpoint-down(lg) {
            font-size: 1.6rem;
            line-height: 1.3;
        }
    }
}
