// load all variables
@import 'breakpoints';
@import 'container';
@import 'aspect-ratios';

$grid-gutter-width: 32px;

// set font size in px values per breakpoint
// e.g.
// md: 18px,
// xl: 20px,
$base-font-size: (
    default: 16px,
);

$base-font-size_xs_sm: 15px;
$base-font-size: 20px;

$font-family-sans-serif: 'Univers W01', sans-serif;
$font-family-base: $font-family-sans-serif;
$font-family-icon: 'icomoon';
$font-weight-normal: 300;

$font-size: (
    xs: (
        h1: 37.5px,
        h1_small: 26.25px,
        h2: 30px,
        h3: 26.25px,
        h4: 22.5px,
        h5: 18.75px,
        h6: 15px,
        lead: 18px,
        text: 15px,
        button: 15px,
        button_small: 13.5px,
        small: 13.5px,
        specialNavButton: 10.5px,
        caption: 12px,
        footerBox: 18.75px,
        footerText: 12px,
        feature: 36px,
        langSwitcher: 15px,
        subNavigation: 18px,
        teaser: 16.5px,
        legend: 20px,
        label: 18px,
        modalBox: 24px,
        table: 18px,
    ),
    md: (
        h1: 50px,
        h1_small: 35px,
        h2: 40px,
        h3: 35px,
        h4: 30px,
        h5: 25px,
        h6: 20px,
        lead: 24px,
        text: 20px,
        button: 20px,
        button_small: 18px,
        small: 18px,
        specialNavButton: 14px,
        caption: 16px,
        footerBox: 25px,
        footerText: 16px,
        feature: 48px,
        langSwitcher: 16px,
        subNavigation: 24px,
        teaser: 22px,
        legend: 22px,
        label: 20px,
        modalBox: 30px,
        table: 22px,
    ),
);

$line-height: (
    xs: (
        h1: 42px,
        h2: 36px,
        h3: 31.5px,
        h4: 27px,
        h5: 22.5px,
        h6: 19.5px,
        lead: 23.25px,
        text: 19.5px,
        button: 19.5px,
        button_small: 16.5px,
        small: 17.5px,
        specialNavButton: 10.5px,
        caption: 15px,
        footerBox: 22.5px,
        footerText: 16.5px,
        feature: 42px,
        langSwitcher: 16.5px,
        subNavigation: 24px,
        subNavigationChild: 24px,
        modalBox: 1.2,
        jobDetail: 1.3,
    ),
    md: (
        h1: 56px,
        h2: 48px,
        h3: 42px,
        h4: 36px,
        h5: 30px,
        h6: 26px,
        lead: 31px,
        text: 26px,
        button: 26px,
        button_small: 22px,
        small: 23px,
        specialNavButton: 14px,
        caption: 20px,
        footerBox: 35px,
        footerText: 22px,
        feature: 56px,
        langSwitcher: 22px,
        subNavigation: 32px,
        subNavigationChild: 26px,
        li: 22px,
    ),
);

$primary: #3866b0;
$blue: #349eeb;
$blue-500: #28497d;
$gray-300: rgba(#bdc0c1, 0.5);
$gray-900: #212529;
$light: #fafafa;

$colors_legacy: (
    dunkelgrau: (
        base: #384044,
    ),
    mittelgrau: (
        base: #7c8184,
    ),
    hellgrau: (
        base: #bdc0c1,
    ),
    weiss: (
        base: #fff,
    ),
    schwarz: (
        base: #000,
    ),
    hellblau: (
        base: #eff4f7,
    ),
    primary: (
        base: #3866b0,
    ),
    secondary: (
        base: #349eeb,
    ),
    success: (
        base: #2ba63a,
    ),
    danger: (
        base: #d64038,
    ),
    warning: (
        base: #d69138,
    ),
    info: (
        base: #6ccbce,
    ),
    News: (
        base: #193867,
    ),
    Event: (
        base: #2ba63a,
    ),
    Blog: (
        base: #d69138,
    ),
    table_hover: (
        base: #eff0f0,
    ),
    table_border: (
        base: #dee2e6,
    ),
    promo-cockpit: (
        base: #5587ac,
        close: #487392,
    ),
);

$theme-colors: (
    'primary': #3866b0,
    'secondary': #349eeb,
    'success': #198927,
    'danger': #d64038,
    'warning': #d69138,
    'info': #6ccbce,
    'light': #bdc0c1,
    'copytext': #000,
    'gallery-primary': #dfe9f0,
    'light-grey': #ebecec,
    'header-color': #000000,
    'border-color': #000000,
    'dai-light': #ffffff,
    'dai-dark': #000,
    'dai-blue': #6393b4,
    'dai-teaser-dark': #151c2e,
    'dai-shadow': #0c1428,
    'light-blue': #eff4f7,
    'grey': #767676,
    'primary-highlight': $blue-500,
    'bodycolor': $gray-900,
);

// spacers for margin and padding spaces
// use classnames like px-fg or px-lg-fg
// for full gutter padding on x-axis or
// full gutter padding lg or bigger on x-axis
$spacers: (
    qg: $grid-gutter-width * 0.25,
    hg: $grid-gutter-width * 0.5,
    qp: $grid-gutter-width * 0.75,
    fg: $grid-gutter-width,
    oh: $grid-gutter-width * 1.5,
    dg: $grid-gutter-width * 2,
    tg: $grid-gutter-width * 3,
    quadg: $grid-gutter-width * 3,
    25: 25px,
);

$headerHeightFirstRow: 71px;
$headerFullHeight: 124px;
$border-radius: 2px;
$border-color: #dee2e6;

$heights: (
    'navigation': (
        'xs': 59px,
        'md': 64px,
        'lg': 80px,
        'xxl': 96px,
    ),
);

$headings-line-height: 1.115;

$promo-cockpit: (
    'xs': -780px,
    'sm': -660px,
    'md': -675px,
    'lg': -335px,
    'xxl': -345px,
);
$promo-cockpit-hover: 10px;

$transition-duration: 0.4s;
$transition-delay: 0.2s;
$preview-heights: (
    xs: 40px,
    sm: 45px,
    md: 142px,
    xl: 130px,
    ultrawide: 130px,
);
$hero-max-width: 2560px;

$small-logo-width: 40px;

//icons
$checkbox-icon-unchecked: "data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='35' height='35' rx='1.5' fill='white' stroke='black'/%3E%3C/svg%3E%0A";
$checkbox-icon-checked: "data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='35' height='35' rx='1.5' fill='white' stroke='black'/%3E%3Cpath d='M10 17.5L15.5 23L26.5 12' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A";
$arrow-down: "data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.27295L7 6.72749L13 1.27295' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A";

//shadow
$teaser-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);

//form

$del-icon: "data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.27539 1.5H8.89297L8.69102 1.82227L7.17422 4.25H5.15039H3.77539H2.40039V5.625H3.77539V22.125V23.5H5.15039H18.9004H20.2754V22.125V5.625H21.6504V4.25H20.2754H18.9004H16.8766L15.3598 1.82227L15.1578 1.5H14.7754H9.27539ZM15.2523 4.25H8.79844L9.65781 2.875H14.393L15.2523 4.25ZM5.15039 22.125V5.625H18.9004V22.125H5.15039ZM8.58789 9.0625V8.375H7.21289V9.0625V18.6875V19.375H8.58789V18.6875V9.0625ZM12.7129 9.0625V8.375H11.3379V9.0625V18.6875V19.375H12.7129V18.6875V9.0625ZM16.8379 9.0625V8.375H15.4629V9.0625V18.6875V19.375H16.8379V18.6875V9.0625Z' fill='%236393B4'/%3E%3C/svg%3E%0A";
