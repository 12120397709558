.CE_TECH_SPEC_HEADER {
    margin-bottom: $grid-gutter-width * 2;
    position: relative;
    overflow: hidden;
    padding-top: $grid-gutter-width * 1.25;

    @include media-breakpoint-up(md) {
        padding-top: $grid-gutter-width * 1.5;
    }

    @include media-breakpoint-up(lg) {
        padding-top: $grid-gutter-width * 1.5;
    }

    h1 {
        text-align: center;

        span {
        }

        &:after {
            content: '';
            width: 48px;
            height: 2px;
            background-color: __get($colors_legacy, 'schwarz' 'base');
            display: block;
            margin: 22px auto 0 auto;
        }
    }

    .CE_TECH_SPEC_HEADER_HELPER {
        margin-right: -$grid-gutter-width * 0.5;
    }

    .CE_TECH_SPEC_HEADER_IMG {
        height: 256px;

        @include media-breakpoint-up(md) {
            height: 448px;
        }

        @include media-breakpoint-up(xl) {
            height: 560px;
        }

        picture {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: $grid-gutter-width;
            z-index: -1;

            @include media-breakpoint-up(sm) {
                top: -11px;
            }

            @include media-breakpoint-up(md) {
                top: -7px;
            }

            img {
                height: 100%;
                width: auto;
            }
        }
    }

    ul,
    .download-box {
        margin: 0;

        @include media-breakpoint-up(md) {
            margin-bottom: 58px;
        }

        @include media-breakpoint-up(xxl) {
            margin-left: -$grid-gutter-width * 0.5;
        }

        li {
            &:first-child {
                float: left;
                display: flex;
                margin: 0 $grid-gutter-width * 0.5 0 0;

                @include media-breakpoint-up(sm) {
                    float: none;
                    margin: 0 0 $grid-gutter-width * 0.5 0;
                }
            }

            a {
                font-size: __get($font-size, 'xs' 'text');
                line-height: __get($line-height, 'xs' 'text');
                @include media-breakpoint-up(md) {
                    @include px-to-rem(font-size, __get($font-size, 'md' 'text'));
                    @include px-to-rem(line-height, __get($line-height, 'md' 'text'));
                }
            }
        }
    }

    .download-box {
        display: flex;

        @include media-breakpoint-up(sm) {
            display: block;
        }

        svg {
            margin-top: -1px;
        }

        .btn-group {
            @include media-breakpoint-up(sm) {
                margin-top: $grid-gutter-width * 0.5;
            }
        }
    }

    svg {
        &.techspecheader-download {
            width: 32px;
            height: 32px;

            @include media-breakpoint-up(md) {
                width: 64px;
                height: 64px;
            }

            display: block;
            margin: 0;
        }
    }
}
