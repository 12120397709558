$tabbed-content-gutter: 32px;

@keyframes toggleMe {
    0% {
        display: none;
        opacity: 0;
    }
    100% {
        display: block;
        opacity: 1;
    }
}

.frame-type-theme_tabbed_content {
    .tabbedContent__list {
        @include make-row;
        margin-left: $tabbed-content-gutter * -0.5;
        margin-right: $tabbed-content-gutter * -0.5;
        margin-bottom: $grid-gutter-width * 0.5;

        @include media-breakpoint-up(lg) {
            margin-bottom: $grid-gutter-width * 1.5;
        }

        list-style: none;
        padding: 0;

        &-items {
            --grid-columns: 1fr;
            margin-bottom: $grid-gutter-width;
            list-style: none;
            padding: 0;
            display: grid;
            grid-template-columns: var(--grid-columns);
            gap: $grid-gutter-width * 0.5;

            @include media-breakpoint-up(md) {
                --grid-columns: 1fr 1fr 1fr;
                margin-bottom: $grid-gutter-width * 2;
                gap: $grid-gutter-width;
            }

            &.smaller {
                @include media-breakpoint-up(sm) {
                    --grid-columns: 1fr 1fr;
                }

                @include media-breakpoint-up(md) {
                    gap: $grid-gutter-width * 0.5;
                }

                @include media-breakpoint-up(xl) {
                    --grid-columns: 1fr 1fr 1fr 1fr;
                    gap: $grid-gutter-width;
                }
            }

            &__button {
                display: flex;
                height: auto;
                margin-bottom: 0;
                padding: $grid-gutter-width * 0.5;
                align-items: center;

                &:hover,
                &:active,
                &:focus,
                &.active {
                    text-decoration: none;

                    svg {
                        fill: __get($colors_legacy, 'weiss.base');
                    }

                    span {
                        color: __get($colors_legacy, 'weiss.base');
                    }
                }

                svg {
                    width: 32px;
                    height: 32px;
                    margin-right: $grid-gutter-width * 0.5;
                    fill: __get($theme-colors, 'primary');
                }

                span {
                    color: __get($theme-colors, 'primary');
                    display: flex;
                    flex-grow: 1;
                    justify-content: space-between;
                    align-items: center;
                    line-height: 1;
                    margin: 0;
                    text-align: left;

                    @include chevron;
                }

                &.active span:after {
                    transform: rotate(135deg);
                }

                &:not(.smaller) {
                    @include media-breakpoint-up(md) {
                        flex-direction: column;
                        padding: $grid-gutter-width;
                        align-items: start;

                        svg {
                            width: 64px;
                            height: 64px;
                        }

                        span {
                            margin: $grid-gutter-width * 0.25 0 0;
                        }
                    }
                }

                &.smaller {
                    @include media-breakpoint-up(xl) {
                        flex-direction: column;
                        padding: $grid-gutter-width;
                        align-items: start;

                        svg {
                            width: 64px;
                            height: 64px;
                        }

                        span {
                            margin: $grid-gutter-width * 0.25 0 0;
                        }
                    }
                }
            }
        }
    }

    .tabbedContent__content {
        .container-wrapper {
            padding: 0;
        }

        &-item {
            opacity: 0;
            display: none;
            animation: toggleMe 0.2s;

            &.active {
                opacity: 1;
                display: block;
            }

            .frame-type-form_formframework {
                aside {
                    @extend .d-none;

                    & + div {
                        @extend .col-12, .col-lg-8, .mx-auto;
                    }
                }
            }
        }
    }
}
