.CE_SIDEBAR_TEXT {
    .main aside & {
        h2 {
            font-size: __get($font-size, 'xs.h3');

            @include media-breakpoint-up(md) {
                font-size: __get($font-size, 'md.h3');
            }
        }

        .bodytext h3 {
            font-size: __get($font-size, 'xs.h4');

            @include media-breakpoint-up(md) {
                font-size: __get($font-size, 'md.h4');
            }
        }
    }
}
