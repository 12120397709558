.burgermenu {
    margin-top: -5px;
    margin-left: 14px;

    input {
        display: none;
    }

    input + label {
        height: 20px;
        width: 30px;
        z-index: 5;
        position: relative;
        top: 5px;

        span {
            position: absolute;
            width: 100%;
            height: 3px;
            top: 50%;
            margin-top: -1px;
            right: 0;
            display: block;
            background: __get($colors_legacy, 'dunkelgrau' 'base');

            @include motion-safe() {
                transition: 0.5s;
            }
        }

        span:first-child {
            top: 0px;
        }

        span:last-child {
            top: 20px;
        }
    }

    label {
        margin-bottom: 0;
    }

    label:hover {
        cursor: pointer;
    }

    input:checked + label {
        span {
            opacity: 0;
            top: 50%;
        }

        span:first-child {
            opacity: 1;
            transform: rotate(405deg);
        }

        span:last-child {
            opacity: 1;
            transform: rotate(-405deg);
        }
    }
}
