.CE_TEXT {
    margin-bottom: 30px;
    //margin-left: $grid-gutter-width;
    //margin-right: $grid-gutter-width;

    //@include media-breakpoint-up(lg) {
    //  margin-left: 0;
    //  margin-right: 0;
    //}
}

body {
    &.tmpl-News {
        .CE_TEXT {
            .offset-lg-2 {
                @include media-breakpoint-up(lg) {
                    margin-left: $grid-gutter-width !important;
                }
            }
        }
    }
}
