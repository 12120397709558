.tooltip {
    white-space: nowrap;

    &.bs-tooltip-top {
        left: -3px !important;
        top: -6px !important;
    }

    .arrow {
        &:before {
            transform: none;
        }
    }
}

.tooltip-wide .tooltip.bottom,
.tooltip-inner {
    max-width: none;
}
