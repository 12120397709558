.CE_TEXTTEASER {
    margin-top: 56px;

    @include media-breakpoint-up(xxl) {
        margin-top: 128px;
    }

    h2 {
        font-size: 30px;
        line-height: 1.3;

        @include media-breakpoint-up(lg) {
            font-size: 40px;
        }
    }

    .teasertext a {
        @include print() {
            display: none !important;
        }
    }
}
