/**
  ICH ZUCK AUS!!! wer sowas macht, wird head of development bei reichl & partner!!
  siehe: _gallery.scss
 */
.CE_EXTERIOR {
    &.bgColor-hellgrau,
    &.bgColor-primary {
        padding: $grid-gutter-width 0;

        @include media-breakpoint-up(md) {
            padding: $grid-gutter-width * 2 0;
        }
    }

    &.bgColor-primary {
        background-color: __get($theme-colors, 'gallery-primary');
    }

    &.bgColor-hellgrau {
        background-color: __get($theme-colors, 'light-grey');
    }
}
