input[type='text'] {
    height: 47px;

    &.powered-by-google {
        background-image: url(../Images/powered_by_google_on_white_hdpi.png);
        background-repeat: no-repeat;
        background-position: right 10px center;
        background-size: 100px;
    }
}

input[type='tel'],
input[type='email'],
input[type='date'],
select.custom-select {
    height: 47px;
    border-radius: 0 !important;
}
