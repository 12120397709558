.CE_IMAGETEASER {
    h2,
    .h6 {
        color: currentColor;
    }

    .h6:after {
        background-color: currentColor;
    }

    &__LIGHT {
        max-width: 2560px;
        margin-left: auto;
        margin-right: auto;
    }

    @include print() {
        &__DARK {
            .color-dai-light {
                color: currentColor;
            }
        }

        .teasertext {
            a {
                display: none;
            }
        }
    }

    &__DARK {
        a span.icon-arrow {
            @include getIcon('arrow', __get($colors_legacy, 'weiss' 'base'), 9px);
        }
    }
}
