.CE_FORM {
    .required {
        color: __get($colors_legacy, 'danger' 'base');
    }

    label {
        display: block;
    }
}

select.form-control {
    border-radius: 2px;
    appearance: none;
    background-image: url("data:image/svg+xml,<svg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg' aria-hidden='true' focusable='false'><path d='M1 1.27344L7 6.72798L13 1.27344' stroke='black' stroke-width='2'/></svg>");
    background-repeat: no-repeat;
    background-position: calc(100% - 12px) center;
}
