.main {
    a:not(.btn, .cta):link,
    a:not(.btn, .cta):visited {
        @include motion-safe() {
            transition: all 0.2s ease-in-out;
        }

        color: __get($theme-colors, 'primary');

        &.blocklevel-link {
            display: block;
            color: inherit;

            .pseudo-link {
                text-decoration: none;
                color: __get($theme-colors, 'primary');
            }
        }
    }

    a:not(.btn, .cta):hover,
    a:not(.btn, .cta).hover,
    a:not(.btn, .cta):active,
    a:not(.btn, .cta):focus {
        color: __get($theme-colors, 'primary-highlight');

        &.blocklevel-link {
            text-decoration: none;
            color: inherit;
            outline: 0;

            .pseudo-link {
                text-decoration: underline;
                color: __get($theme-colors, 'primary-highlight');
                text-underline-offset: 2px;
                text-decoration-thickness: 1px;
            }
        }
    }
}

a:not(.btn, .cta).hover,
a:not(.btn, .cta):hover {
    text-decoration: underline;
    text-underline-offset: 2px;
    text-decoration-thickness: 1px;
}
