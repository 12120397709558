.CE_NEWSLETTERSUBSCRIPTION {
    text-align: center;

    @include media-breakpoint-up(lg) {
        text-align: left;
    }

    h2 {
        margin-top: 0;
    }

    p {
        font-size: 18px;

        @include media-breakpoint-up(lg) {
            font-size: 26px;
        }
    }

    .CE_HEADER + .bodytext {
        margin-top: $grid-gutter-width * 0.5;
    }

    .h6 {
        &::after {
            margin-left: auto;
            margin-right: auto;

            @include media-breakpoint-up(lg) {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }

    &__background {
        background-color: __get($theme-colors, 'light-grey');
    }

    &__button {
        font-size: 24px;
        font-weight: 300 !important;
    }
}
