.morphing {
    &-btn {
        transition:
            color 0.3s 0.2s,
            width 0.2s 0s;
        white-space: nowrap;
        box-sizing: border-box;

        &_circle {
            color: transparent !important;
            padding-left: 0;
            padding-right: 0;
            width: 31.9px !important;
            height: 31.9px !important;
            transition:
                color 0.2s 0s,
                width 0.3s 0.2s;
        }

        &-wrap {
            display: inline-block;
            position: relative;
            text-align: center;
        }

        &-clone {
            position: fixed;
            background: __get($colors_legacy, 'promo-cockpit.base');
            border-radius: 50%;
            z-index: 3;
            backface-visibility: hidden;
            transition: all 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);

            @media (prefers-reduced-motion) {
                transition-duration: 1ms;
            }

            &_visible {
                display: block;
                transform: scale(1) !important;
            }
        }
    }
}

.fancybox {
    &-slide {
        &--html {
            padding: 0;
        }
    }

    &-content {
        &.promo-cockpit {
            padding: ($grid-gutter-width * 2) 0 0 0;
        }
    }

    &-morphing {
        .fancybox-bg {
            background: __get($colors_legacy, 'promo-cockpit.base');
            opacity: 1;
        }

        .fancybox-toolbar {
            top: 20px;
            right: 40px;
        }

        .fancybox-button {
            &--close {
                background: rgba(0, 0, 0, 0.2);
                border-radius: 50%;
                color: #fff;

                &:before,
                &:after {
                    height: 1.55px;
                    width: 22px;
                    left: calc(50% - 11px);
                }

                &:hover {
                    background: rgba(0, 0, 0, 0.25);
                }
            }
        }
    }
}

#morphing-content {
    position: relative;
    background: transparent;
    z-index: 3;
    backface-visibility: hidden;
    display: none;
    width: 100%;

    @include media-breakpoint-up(lg) {
        padding-bottom: 250px;
    }
}

.promo-cockpit {
    @include media-breakpoint-up(lg) {
        padding-bottom: 300px;
    }

    [data-close] {
        position: fixed;
        right: $grid-gutter-width * 0.5;
        top: $grid-gutter-width * 0.5;
    }

    &.no-transform + footer:hover {
        transform: none;
    }

    &.visible {
        animation: toggleMe 0.2s;
        display: block;
        opacity: 1;

        &:not(.no-transform) + footer {
            transform: translate(0, -240px);
        }

        &.no-transform + footer {
            transform: translate(0, 130px);
        }

        & > footer {
            bottom: -630px;

            @include media-breakpoint-up(md) {
                bottom: -650px;
            }
        }

        & + footer {
            display: none;

            @include media-breakpoint-up(lg) {
                display: block;
            }
        }
    }

    .promo-cockpit__item {
        transition: all 1s cubic-bezier(0.06, 0.43, 0.22, 1) 0.5s;

        @media (prefers-reduced-motion) {
            transition-duration: 1ms;
        }

        opacity: 0;
        transform: translateY(50px);

        $delay: 500;

        @each $iterator in [1, 2, 3] {
            &:nth-child(#{$iterator}) {
                transition-delay: #{$delay}ms;
            }
            $delay: $delay + 200;
        }

        &.animate {
            opacity: 1;
            transform: translateY(0);
        }

        @include after-first(1) {
            //opacity: .1;
        }

        aside {
            order: 1;

            @include media-breakpoint-up(lg) {
                order: initial;
            }

            & > div {
                padding: $grid-gutter-width * 0.5;

                @include media-breakpoint-up(lg) {
                    padding: $grid-gutter-width;
                    padding-right: 0;
                }
            }
        }

        @include every(2) {
            aside {
                order: 1;

                & > div {
                    @include media-breakpoint-up(lg) {
                        padding-left: 0;
                        padding-right: $grid-gutter-width;
                    }
                }
            }
        }
    }
}
