.selectStyled {
    position: relative;
    display: inline-block;
    width: auto;
    margin-bottom: 15px;
    min-width: 166px;
    margin-right: 8px;
    background-color: __get($colors_legacy, 'dunkelgrau' 'base');
    border-width: 1px;
    border-style: solid;
    border-color: __get($colors_legacy, 'hellgrau' 'base');
    border-radius: 4px;

    &:last-child {
        margin-right: 0;
    }

    .select_list {
        display: none;
        position: absolute;
        bottom: 65px;
        left: 0;
        width: 100%;
        background-color: __get($colors_legacy, 'weiss' 'base');

        ul {
            margin: 0;
            padding: 20px;

            li {
                a:link,
                a:visited {
                    color: __get($colors_legacy, 'weiss' 'base');
                }

                a:hover,
                a:active {
                    color: __get($colors_legacy, 'weiss' 'base');
                    text-decoration: none;
                }
            }
        }
    }

    select {
        display: inline-block;
        width: 100%;
        cursor: pointer;
        padding: 0 35px 5px 35px;
        color: __get($colors_legacy, 'hellgrau' 'base');
        border-radius: 0;
        outline: 0;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        @extend .small;
        background-color: transparent;
        position: relative;
        z-index: 100;
        border: none;

        option {
            position: absolute;
            left: -3000px;
        }
    }

    .select__globe {
        position: absolute;
        top: 4px;
        left: 5px;
        fill: __get($colors_legacy, 'hellgrau' 'base');
        z-index: 99;
    }

    .select__arrow {
        position: absolute;
        top: 10px;
        right: 15px;
        color: __get($colors_legacy, 'hellgrau' 'base');
        z-index: 99;

        &:before {
            border-style: solid;
            border-width: 1px 1px 0 0;
            content: '';
            display: inline-block;
            height: 9px;
            left: 0.15em;
            position: relative;
            top: 0px;
            transform: rotate(135deg);
            vertical-align: top;
            width: 9px;
        }
    }

    &.small {
        width: 100%;

        select {
            padding: 5px 30px 5px 14px;
        }

        .select__arrow {
            color: __get($colors_legacy, 'dunkelgrau' 'base');
            top: 12px;

            &:before {
                border-width: 3px 3px 0 0;
                width: 10px;
                height: 10px;
            }
        }
    }
}

.selectStyled select::-ms-expand {
    display: none;
}

.selectStyled select:hover,
.selectStyled select:focus {
}

.selectStyled select:disabled {
    pointer-events: none;
    opacity: 0.5;
}

.selectStyled select:hover ~ .select__arrow,
.selectStyled select:focus ~ .select__arrow {
    border-top-color: #000;
}

.selectStyled select:disabled ~ .select__arrow {
    border-top-color: #ccc;
}
