.bodytext {
    a {
        text-decoration: underline;
        text-underline-offset: 2px;
        text-decoration-thickness: 1px;

        @include media-breakpoint-down(md) {
            &.icon-arrow {
                padding: $grid-gutter-width * 0.125 0;
                display: inline-block;
            }
        }
    }

    p:has(a + br) a {
        line-height: 1.7;
    }
}
