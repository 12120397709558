.CE_AREALIST {
    .area-row {
        margin-top: $grid-gutter-width * 1.375;
        row-gap: $grid-gutter-width;

        .card {
            @include motion-safe() {
                transition: all 0.2s ease-in-out;
            }
        }
    }
}
