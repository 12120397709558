[data-animation] {
    .CE_OVERVIEW_TEASER_TEXTBOX {
        @include motion-safe() {
            transition: all 1s cubic-bezier(0.06, 0.43, 0.22, 1) 1s;
        }

        bottom: -30px;
        opacity: 0;
    }

    &[data-animation='fadeIn'] {
        @include motion-safe() {
            transition: all 1s cubic-bezier(0.06, 0.43, 0.22, 1) 0.5s;
        }

        opacity: 0;
        transform: translateY(50px);
    }

    &.fadeIn {
        opacity: 1;
        transform: translateY(0px);

        .CE_OVERVIEW_TEASER_TEXTBOX {
            opacity: 1;
            bottom: 0;
        }
    }
}
