@use 'sass:math';

@function implode($pieces, $glue: '') {
    $result: null;
    @for $i from 1 through length($pieces) {
        $piece: nth($pieces, $i);
        @if type-of($piece) == list {
            $result: unquote('#{$result}#{$glue}#{implode($piece, $glue)}');
        } @else {
            $result: unquote('#{$result}#{$glue}#{$piece}');
        }
    }
    @if $result != null {
        $result: str-slice($result, str-length($glue) + 1, -1);
    }
    @return $result;
}

@mixin utility($var, $attribute, $prefix: '-', $separator: '-', $base: 'base') {
    @each $key, $value in $var {
        &#{if($key != $base, #{$prefix}#{$key}, '')} {
            @if type-of($value) == 'map' {
                @include utility($value, $attribute, $separator);
            } @else {
                #{$attribute}: $value;
            }
        }
    }
}

@mixin utility-breakpoints($var, $attribute, $prefix: '-', $separator: '-', $breakpoints: $grid-breakpoints) {
    @each $key, $value in $var {
        @each $breakpoint in map-keys($breakpoints) {
            $infix: breakpoint-infix($breakpoint, $breakpoints);
            @if $breakpoint == $key {
                &#{$prefix}#{$key} {
                    @include media-breakpoint-up($breakpoint, $breakpoints) {
                        @each $part, $val in $value {
                            &-#{$part} {
                                #{$attribute}: $val;
                            }
                        }
                    }
                }
            }
        }
    }
}

// adds the burger navigation
@mixin burger() {
    position: absolute;
    top: 8px;
    right: $grid-gutter-width * 0.5;
    z-index: 101;

    width: 35px;
    height: 35px;
    transform: rotate(0deg);
    cursor: pointer;

    @include motion-safe() {
        transition: 0.5s ease-in-out;
    }

    span {
        display: block;
        position: absolute;
        height: 4px;
        width: 100%;
        background: theme-color('dark');
        opacity: 1;
        left: 0;
        transform: rotate(0deg);

        @include motion-safe() {
            transition: 0.25s ease-in-out;
        }

        &:nth-child(1) {
            top: 0px;
        }

        &:nth-child(2),
        &:nth-child(3) {
            top: 10px;
        }

        &:nth-child(4) {
            top: 20px;
        }
    }

    &.open span {
        &:nth-child(1) {
            top: 18px;
            width: 0%;
            left: 50%;
        }

        &:nth-child(2) {
            transform: rotate(45deg);
        }

        &:nth-child(3) {
            transform: rotate(-45deg);
        }

        &:nth-child(4) {
            top: 18px;
            width: 0%;
            left: 50%;
        }
    }
}

@mixin background-opacity($color, $opacity: 0.3) {
    background: $color; /* The Fallback */
    background: rgba($color, $opacity);
}

@mixin print() {
    @media print {
        @content;
    }
}

/// Make font smoothing
///
@mixin font-smoothing() {
    -webkit-text-size-adjust: 100%;
    /* Font varient */
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    /* Smoothing */
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

@mixin px-to-rem($property, $px, $unit: rem) {
    $px: strip-unit($px);
    $rem: rem($px, $unit);
    #{$property}: #{$px}px;
}

@mixin centerer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin chevron() {
    &:after {
        border-style: solid;
        border-width: 2px 2px 0 0;
        content: '';
        height: 7.5px;
        width: 7.5px;
        transform: rotate(45deg);
        display: inline-block;

        @include motion-safe() {
            transition: all 0.2s ease-in-out;
        }

        @include media-breakpoint-up(md) {
            height: 10px;
            width: 10px;
        }
    }
}

@mixin getIcon($icon, $color: '#ffffff', $size: 24px, $position: 'after') {
    $color: str-replace(#{$color}, '#', '%23');

    &:#{$position} {
        display: flex;
        align-self: center;
        content: '';
        width: $size;
        height: $size;
        background-size: contain;
        background-repeat: no-repeat;
        margin-left: 5px;

        @if $icon == 'arrow' {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='#{$color}' d='M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z'/%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3C/svg%3E");
        }

        @if $icon == 'logo' {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg baseProfile='basic' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 57 45'%3E%3Cpath fill='%231A3869' d='M37.038 0L21.849 14.684 31.855.562 16.87 14.684 26.792.562l-15.07 14.291L21.849.507 6.827 14.684 16.784.676 0 16.33l15.756 15.754 14.953-14.7-12.506 17.147 17.4-17.147-14.784 19.764 19.595-19.764-17.063 22.295 22.211-22.295L26.051 42.38l24.321-24.996-21.957 27.36L56.831 16.33 41.133.632 26.912 14.684z'/%3E%3C/svg%3E");
            margin-left: 0;
        }

        @if $icon == 'calendar' {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='#{$color}' d='M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z'/%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3C/svg%3E");
            margin-left: 0;
        }
    }
}

@mixin make-card() {
    background: __get($colors_legacy, 'weiss.base');
    box-shadow: 0 0 10px rgba(__get($colors_legacy, 'schwarz.base'), 0.25);
    display: flex;
    flex-direction: column;

    .card {
        &__body {
            padding: $grid-gutter-width;
        }

        &__title {
            margin: 0;
            line-height: 1.2;
            margin-bottom: $grid-gutter-width * 0.5;
            font-size: 22px;

            @include media-breakpoint-up(lg) {
                font-size: 38px;
            }
        }

        &__text {
            margin-bottom: $grid-gutter-width * 0.5;
        }
    }
}

@mixin picturelink() {
    .icon {
        pointer-events: none;
    }

    &:hover,
    &:focus {
        + .icon {
            background-color: __get($theme-colors, 'primary-highlight');
            opacity: 1;
        }
    }
}

@mixin picturelinkIcon() {
    background-color: __get($colors_legacy, 'dunkelgrau' 'base');
    opacity: 0.5;
    text-align: center;
    padding: 4px;
    display: inline-block;
    height: 32px;
    width: 32px;
    position: absolute;
    left: $grid-gutter-width * 0.25;
    top: $grid-gutter-width * 0.25;

    &.video,
    &.vr {
        //left: calc(50% - #{$ce-gallery-mediaicon-big-width / 2 * 0.75});
        left: calc(50% - #{math.div($ce-gallery-mediaicon-big-width, 2) * 0.75});
        //top: calc(50% - #{$ce-gallery-mediaicon-big-height / 2 * 0.75});
        top: calc(50% - #{math.div($ce-gallery-mediaicon-big-height, 2) * 0.75});
        width: $ce-gallery-mediaicon-big-width * 0.75;
        height: $ce-gallery-mediaicon-big-height * 0.75;
        @include media-breakpoint-up(xl) {
            left: calc(50% - #{math.div($ce-gallery-mediaicon-big-width, 2)});
            top: calc(50% - #{math.div($ce-gallery-mediaicon-big-height, 2)});
            width: $ce-gallery-mediaicon-big-width;
            height: $ce-gallery-mediaicon-big-height;
        }
    }
}
