.CE {
    margin-bottom: $grid-gutter-width * 1.5;

    .CE {
        margin-bottom: 0;
    }

    .tmpl-Homepage & {
        @include media-breakpoint-up(md) {
            margin-bottom: 56px;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 72px;
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: 88px;
        }

        @include media-breakpoint-up(xxl) {
            margin-bottom: 128px;
        }

        .CE {
            margin-bottom: 0;
        }
    }
}

.main > .CE_HEADER.special-space:first-child {
    margin-top: $grid-gutter-width * 4 !important;
}
