.home-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    padding: $grid-gutter-width;

    > img {
        height: 91%;
        width: auto;
        max-width: 100%;
    }
}
