.hero-slider {
    z-index: 0;

    color: __get($theme-colors, 'dai-light');
    overflow: hidden;

    @include motion-safe() {
        transition: color $transition-duration ease $transition-delay;
    }

    max-height: 100vh;
    max-width: $hero-max-width;
    margin: 0 auto $grid-gutter-width * 2 auto;
    position: relative;

    //@include media-breakpoint-up(md) {
    &::after {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        pointer-events: none;
        background: linear-gradient(180deg, rgba(12, 20, 40, 0.8) 0%, rgba(12, 20, 40, 0) 27%);
        mix-blend-mode: darken;

        @include print() {
            display: none;
        }
    }
    //}

    @include hero-grid(__get($heights, 'navigation'), $preview-heights);

    @each $breakpoint, $height in __get($heights, 'navigation') {
        @include media-breakpoint-up($breakpoint) {
            margin-top: -$height;
        }
    }

    @include print() {
        page-break-inside: avoid;
    }

    &__autoplay-toggle {
        border: 0;
        background-color: transparent;
        color: inherit;
        grid-column: 2;
        grid-row: 3;
        z-index: 4;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        height: 40px;
        width: 40px;
        position: absolute;
        right: 0;
        margin: $grid-gutter-width * 0.25;
        bottom: 0;
        opacity: 0.8;

        @include media-breakpoint-up(sm) {
            position: relative;
            margin: 0;
            right: unset;
            bottom: unset;
            opacity: 0.9;
        }

        @include media-breakpoint-up(md) {
            align-items: center;
            margin-top: $grid-gutter-width * 0.25;
        }

        @include media-breakpoint-up(xl) {
            margin-top: 30px;
            margin-bottom: 0;
        }

        svg {
            height: 37px;
            width: 32px;
            fill: white;
        }

        .hero-slider__autoplay-play {
            display: none;
        }

        .hero-slider__autoplay-pause {
            display: block;
        }

        &.hero-slider__autoplay-toggle--pause {
            .hero-slider__autoplay-play {
                display: block;
            }

            .hero-slider__autoplay-pause {
                display: none;
            }
        }

        @include media-breakpoint-up(md) {
            margin-top: $grid-gutter-width * 1.5;
        }

        @include media-breakpoint-up(lg) {
            margin-top: $grid-gutter-width;
        }

        @include media-breakpoint-up(xl) {
            @media (hover: hover) and (pointer: fine) {
                opacity: 0;

                .hero-slider:hover &,
                &:focus {
                    opacity: 1;
                }
            }
        }
    }

    &__items {
        grid-row: 1/4;
        grid-column: 1/3;
        z-index: 1;
        position: relative;
        align-self: stretch;
        justify-self: stretch;
    }

    &__item {
        @include hero-grid(__get($heights, 'navigation'), $preview-heights);
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        &--active {
            display: grid;
        }

        [data-stagger] {
            opacity: 0;
        }

        @include print() {
            transform: scale(1) !important;
        }

        &-content {
            grid-row: 2/3;
            grid-column: 1/3;
            z-index: 2;
            align-self: end;
            justify-self: stretch;
            position: relative;
            z-index: 2;

            @include media-breakpoint-up(lg) {
                padding-bottom: $grid-gutter-width;
            }

            @include print() {
                display: none !important;
            }
        }

        &-background {
            grid-row: 1/4;
            grid-column: 1/3;
            z-index: 1;
            align-self: center;
            justify-self: stretch;
            position: relative;

            //@include media-breakpoint-up(md) {
            &::before {
                position: absolute;
                display: block;
                content: '';
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 2;
                pointer-events: none;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(5, 15, 32, 0.5) 100%);
                background-blend-mode: overlay;
                mix-blend-mode: darken;

                @include print() {
                    display: none;
                }
            }

            &::after {
                position: absolute;
                display: block;
                content: '';
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 2;
                pointer-events: none;
                background: linear-gradient(251.71deg, rgba(5, 15, 32, 0) 28.38%, #050f20 99.53%);
                background-blend-mode: overlay;
                mix-blend-mode: darken;

                @include print() {
                    display: none;
                }
            }
            //}
        }
    }

    &__top {
        width: 100%;
    }

    &__text {
        flex: 1 0 auto;

        & > * {
            font-size: 18px;
            line-height: 1.2;

            @include media-breakpoint-up('xl') {
                font-size: 35px;
            }
        }

        p {
            @include text-shadow;
        }
    }

    &__button {
        &-bar {
            flex: 0 0 auto;
        }

        &-link {
            &.btn.btn-primary {
                background-color: __get($theme-colors, 'dai-light');
                color: __get($theme-colors, 'dai-dark');
                line-height: 1;
                height: auto;
                padding: $grid-gutter-width * 0.5 $grid-gutter-width;
                margin-bottom: $grid-gutter-width * 0.25;

                &:hover,
                &:focus {
                    color: __get($colors_legacy, 'weiss.base');
                }

                @include media-breakpoint-up('sm') {
                    margin-bottom: $grid-gutter-width * 0.5;
                }

                @include media-breakpoint-up('xl') {
                    margin-left: $grid-gutter-width;
                }

                @include print() {
                    display: none !important;
                }
            }
        }
    }

    &__previews {
        grid-row: 3;
        grid-column: 1/2;
        z-index: 2;
        align-self: start;
        justify-content: center;
    }

    &__preview-container {
        display: flex;
        justify-content: stretch;
        align-items: flex-end;
        z-index: 30;
        margin: -$grid-gutter-width * 0.25;

        @include media-breakpoint-up(sm) {
            margin: $grid-gutter-width * 0.5 $grid-gutter-width * 0.5 0;
            transform: translateY(-50%) !important;
        }

        @include media-breakpoint-up(md) {
            margin: $grid-gutter-width * 0.5 0 0;
            transform: none !important;
        }

        @include media-breakpoint-up(lg) {
            margin: 0;
        }

        @include print() {
            display: none !important;
        }
    }

    &__preview {
        opacity: 0.3;
        border: 0;
        background: transparent;
        color: currentColor;
        width: 100%;
        min-width: min-content;
        padding: 0;
        text-align: left;

        @include motion-safe() {
            transition: opacity 0.3s ease;
        }

        max-width: 50px;
        min-height: 40px;
        margin: 0 $grid-gutter-width * 0.25;

        @include media-breakpoint-up(md) {
            max-width: calc(25% - #{$grid-gutter-width});
            margin: 0 $grid-gutter-width * 0.5;
        }

        &:not(&--active):hover {
            opacity: 0.8;

            .hero-slider__preview-timeline {
                transform: none !important;
                transition-delay: 0s;
            }
        }

        &--active {
            opacity: initial;
            transition-delay: 0.6s;

            .hero-slider__preview-timeline {
                transform: none !important;
            }

            .hero-slider__preview-currenttime {
                @include motion-safe() {
                    transition: opacity $transition-duration ease $transition-delay;
                }

                opacity: 1;
            }
        }

        &-timeline {
            display: block;
            height: 2px;
            background: __get($theme-colors, 'dai-light');
            width: 100%;
            transition:
                background $transition-duration ease $transition-delay,
                transform 0.2s ease $transition-delay;
            transform: scaleY(0.5) !important;

            @include media-breakpoint-up(md) {
                margin-top: 8px;
                transform: none !important;

                @include motion-safe() {
                    transition: background $transition-duration ease $transition-delay;
                }
            }

            @include media-breakpoint-up(xl) {
                margin-top: 16px;
            }
        }

        &-currenttime {
            display: block;
            height: 100%;
            background: __get($theme-colors, dai-blue);
            width: 0;
            opacity: 0;
            transition:
                opacity $transition-duration ease,
                width 0 ease $transition-duration;
        }

        &-category {
            display: none;
            font-size: 14px;

            @include media-breakpoint-up('md') {
                display: block;
            }

            @include media-breakpoint-up('xl') {
                font-size: 16px;
            }

            & + .hero-slider__preview-title {
                margin-top: $grid-gutter-width * 0.25;
            }
        }

        &-title {
            display: none;
            font-size: 22px;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            padding-bottom: 8px;

            @include media-breakpoint-up(md) {
                display: -webkit-box;
            }

            @include media-breakpoint-up(lg) {
                font-size: 24px;
            }
        }
    }

    &__title {
        font-size: clamp(2rem, 8vw, 5.625rem);
        margin-bottom: $grid-gutter-width * 0.5;
        font-weight: 400;
        line-height: 1.1;
        color: inherit;

        @include media-breakpoint-up(md) {
            font-size: clamp(2rem, 5vw, 5.625rem);
            margin-bottom: clamp(16px, 2.5vw, 40px);
        }

        //@include text-shadow;
    }

    &__category {
        color: inherit;
        font-size: 20px;

        @include media-breakpoint-up('xl') {
            font-size: 35px;
        }

        //@include text-shadow;
    }

    &__arrows {
        grid-row: 3;
        grid-column: 1/2;
        justify-self: stretch;
        display: flex;
        align-self: start;
        align-items: flex-start;
        justify-content: space-between;
        z-index: 3;
        height: 0;
        padding: 0 $grid-gutter-width * 0.5;
        margin-top: -12px;

        @include media-breakpoint-up(md) {
            padding: 0 $grid-gutter-width;
            margin-top: 0;
        }

        @include media-breakpoint-up(xl) {
            padding: 0 $grid-gutter-width * 2;
        }

        @include print() {
            display: none !important;
        }

        &-container {
            @include media-breakpoint-up(xxl) {
                gap: $grid-gutter-width * 0.5;
            }
        }
    }

    &__arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 0;
        border: none;
        background: transparent;
        height: 40px;
        width: 40px;
        color: __get($theme-colors, 'dai-light');

        @include motion-safe() {
            transition: color $transition-duration ease $transition-delay;
        }

        @include media-breakpoint-up(md) {
            transform-origin: top right;
            margin-top: $grid-gutter-width * 1.5;

            &--left {
                transform-origin: top left;
            }
        }

        @include media-breakpoint-up(lg) {
            margin-top: $grid-gutter-width;
        }

        @include media-breakpoint-up(xl) {
            @media (hover: hover) and (pointer: fine) {
                opacity: 0;

                .hero-slider:hover &,
                &:focus {
                    opacity: 1;
                }
            }
        }

        svg {
            display: block;
            width: $grid-gutter-width * 0.5;
            height: 26px;
        }

        &--right {
            margin-right: -12px;

            @include media-breakpoint-up(md) {
                margin-right: -32px;
            }

            @include media-breakpoint-up(xl) {
                margin-right: -48px;
            }

            @include media-breakpoint-up(xxl) {
                margin-right: -12px;
            }
        }

        &--left {
            margin-left: -12px;

            @include media-breakpoint-up(md) {
                margin-left: -32px;
            }

            @include media-breakpoint-up(xl) {
                margin-left: -48px;
            }

            @include media-breakpoint-up(xxl) {
                margin-left: -12px;
            }

            svg {
                transform: scaleX(-1) !important;
            }
        }
    }
}
