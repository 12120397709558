.CE_HEADER {
    margin-top: 0;
    margin-bottom: 18px;
    @include media-breakpoint-up(md) {
        margin-bottom: 45px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 0;

        span {
            font-weight: 400;

            &:after {
                content: '';
                display: block;
                width: 60px;
                height: 2px;
                margin-top: 2px;
                background-color: __get($colors_legacy, 'schwarz' 'base');
                margin-bottom: $grid-gutter-width * 0.5;
            }
        }
    }

    + .CE_GALLERY {
        .CE_GALLERY_HEADER {
            margin-top: 0;
        }
    }

    .CE_SHADOWBOX & {
        margin-bottom: 18px;
        @include media-breakpoint-up(md) {
            margin-bottom: 45px;
        }
    }
}
