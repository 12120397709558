.CE.CE_CTA_BUTTON {
    text-align: center;

    .cta {
        color: __get($colors_legacy, 'weiss' 'base');
        background-color: __get($theme-colors, 'primary');
        border-radius: 3px;
        border: 1px solid __get($theme-colors, 'primary');
        @include px-to-rem(font-size, __get($font-size, 'md' 'h4') * 0.8);
        @include px-to-rem(line-height, __get($line-height, 'md' 'h3') * 0.8);
        box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.15);
        padding: 3px 18px;

        &:hover,
        &:active,
        &:focus {
            box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.3);
            position: relative;
            top: -1px;
            text-decoration: none;
            background-color: __get($theme-colors, 'primary-highlight');
            border: 1px solid __get($theme-colors, 'primary-highlight');
        }
    }
}
